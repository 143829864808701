import { Card, Link, AppBar as MuiAppBar, Typography, styled } from '@mui/material';

export const AppBar = styled(MuiAppBar)({
  height: '48px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#111827',
  boxShadow: 'none',
});

export const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '25px',
});

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  gap: 20,
  marginBottom: '20px',
  marginTop: '48px',
  height: 'calc(100vh - 48px)',
}));

export const TitleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const Title = styled(Typography)({
  fontSize: 34,
});

export const Content = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  backgroundColor: 'white',
  padding: '32px 24px 32px 24px',
  rowGap: '20px',
  borderRadius: 4,
  maxWidth: '565px',
});

export const Image = styled('img')(({ theme }) => ({
  marginTop: '20px',
  height: '100px',
  width: '250px',

  [theme.breakpoints.down('sm')]: {
    height: '35px',
    width: '200px',
  },

  [theme.breakpoints.up('sm')]: {
    height: '70px',
    width: '120px',
  },

  [theme.breakpoints.between(theme.breakpoints.values.md, theme.breakpoints.values.xl)]: {
    height: '70px',
    width: '150px',
  },
}));

export const NavImage = styled('img')({
  height: '25px',
  width: '136px',
});

export const LinksContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
});

export const SignInWrapper = styled('div')({
  width: '80vw',
  minWidth: '360px',
  maxWidth: '520px',
  marginBottom: '20px',
});

export const FooterLink = styled(Link)({
  fontSize: 16,
  marginTop: '.5rem',
  underline: 'none',
  textDecoration: 'none',
});
