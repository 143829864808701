/* eslint react/jsx-no-literals: "off", no-nested-ternary: "off" */

import { Box, Button, FormControl, MenuItem, Table, TableBody, TableContainer } from '@mui/material';
import { KeyboardEvent, MouseEvent, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TableHead } from '~/core/components/shared';
import { GetAssetRecommendationsQueryVariables } from '~/core/services/graphql/assets/assets.queries.generated';
import { useGetAssetRecommendations } from '~/core/services/graphql/assets/hooks';
import { useGetIntents } from '~/core/services/graphql/intents/hooks';
import { IntentsSchema, IntentsSortEnum, QueryGetIntentsArgs } from '~/core/types/graphql.types';
import { SortType } from '~/core/types/table.types';
import { useContexts } from '~/store/context/useContext';

import { CustomTablePagination } from '../../components';
import LoadingTable from '../LoadingTable';
import * as Styled from '../styles';
import { headerCells } from '../tableHeader.data';
import DeployContentModal from './DeployContentModal';
import CollapsibleRow from './CollapisbleRow';

type IntentsTableProps = {
  coveredState?: string;
  setCoveredState?: (value: string) => void;
};

const IntentsTable = ({ coveredState, setCoveredState }: IntentsTableProps) => {
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedCampaignId, selectedDateRange } = useContexts();
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderBy, setOrderBy] = useState<IntentsSortEnum>(IntentsSortEnum.Impressions);
  const [desc, setDesc] = useState<boolean>(true);
  const [page, setPage] = useState<number>((searchParams.get('page') && Number(searchParams.get('intentsPage'))) || 0);
  const [pageSize, setPageSize] = useState<number>(
    (searchParams.get('pageSize') && Number(searchParams.get('pageSize'))) || 25,
  );
  const [inputValue, setInputValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedIntentIds, setSelectedIntentIds] = useState<string[]>([]);
  const [allSelectedIntents, setAllSelectedIntents] = useState<IntentsSchema[]>([]);
  const [isDeployModalOpen, setIsDeployModalOpen] = useState(false);

  const handleIntentSelect = (intentId: string, intent: IntentsSchema) => {
    setSelectedIntentIds((prev) => {
      const newIds = prev.includes(intentId) ? prev.filter((id) => id !== intentId) : [...prev, intentId];

      // Update allSelectedIntents accordingly
      setAllSelectedIntents((prev) => {
        if (newIds.includes(intentId)) {
          // Add the intent if it's not already in the list
          return prev.some((i) => i.id === intentId) ? prev : [...prev, intent];
        }
        // Remove the intent
        return prev.filter((i) => i.id !== intentId);
      });

      return newIds;
    });
  };

  const handleOpenDeployModal = () => {
    setIsDeployModalOpen(true);
  };

  const handleCloseDeployModal = () => {
    setIsDeployModalOpen(false);
  };

  const handleDeploySuccess = () => {
    setSelectedIntentIds([]);
    setAllSelectedIntents([]);
    handleCloseDeployModal();
  };

  const handleRemoveIntent = (intentId: string) => {
    setSelectedIntentIds((prev) => prev.filter((id) => id !== intentId));
    setAllSelectedIntents((prev) => prev.filter((intent) => intent.id !== intentId));
  };

  const intentsSkip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange;
  const intentsVariables: QueryGetIntentsArgs = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    campaignIds: selectedCampaignId ? [selectedCampaignId] : null,
    dateRange: selectedDateRange,
    sortBy: {
      field: orderBy,
      desc,
    },
    searchValue,
    page: page + 1,
    pageSize,
    aligned: coveredState === 'covered' ? true : coveredState === 'uncovered' ? false : undefined,
  };

  const { intentsLoading, getIntents } = useGetIntents(intentsSkip, intentsVariables);

  const intentIds = getIntents?.edges?.map((intent) => intent?.id);

  const assetRecommendationsSkip =
    !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange || !intentIds?.length;
  const assetRecommendationsVariables: GetAssetRecommendationsQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    dateRange: selectedDateRange,
    intentIds,
  };

  const { getAssetRecommendations } = useGetAssetRecommendations(
    assetRecommendationsSkip,
    assetRecommendationsVariables,
  );

  const intentWithRecommendationsIds = getAssetRecommendations?.map((recommendation) => recommendation?.intentId);

  const handleRequestSort = (event: MouseEvent<unknown>, property: any) => {
    const isAsc = property === orderBy ? !desc : true;
    setDesc(isAsc);
    setOrderBy(property);
    searchParams.set('orderBy', property);
    searchParams.set('desc', String(!isAsc));
    setSearchParams(searchParams);
  };

  const handleSearch = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setSearchValue(inputValue);
    }
  };

  return intentsLoading ? (
    <>
      <Styled.SearchContainer sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Styled.SearchBar
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleSearch}
            placeholder="Search by intent"
          />
          <FormControl sx={{ minWidth: 120, ml: 2 }}>
            <Styled.Select
              value={coveredState || 'all'}
              onChange={(e) => setCoveredState?.(e.target.value as string)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem sx={{ fontSize: '12px', color: 'rgba(33, 33, 52, 0.6)' }} value="all">
                All search intent
              </MenuItem>
              <MenuItem sx={{ fontSize: '12px', color: 'rgba(33, 33, 52, 0.6)' }} value="covered">
                Search intents with assets
              </MenuItem>
              <MenuItem sx={{ fontSize: '12px', color: 'rgba(33, 33, 52, 0.6)' }} value="uncovered">
                Search intents without assets
              </MenuItem>
            </Styled.Select>
          </FormControl>
        </Box>
      </Styled.SearchContainer>
      <LoadingTable headerCells={headerCells} />
      <DeployContentModal
        open={isDeployModalOpen}
        onClose={handleCloseDeployModal}
        selectedIntents={allSelectedIntents}
        onRemoveIntent={handleRemoveIntent}
        onDeploySuccess={handleDeploySuccess}
      />
    </>
  ) : (
    <>
      <Styled.SearchContainer
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Styled.SearchBar
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleSearch}
            placeholder="Search by intent"
          />
          <FormControl sx={{ minWidth: 120, ml: 2 }}>
            <Styled.Select
              value={coveredState || 'all'}
              onChange={(e) => setCoveredState?.(e.target.value as string)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem sx={{ fontSize: '12px', color: 'rgba(33, 33, 52, 0.6)' }} value="all">
                All search intent
              </MenuItem>
              <MenuItem sx={{ fontSize: '12px', color: 'rgba(33, 33, 52, 0.6)' }} value="covered">
                Search intents with assets
              </MenuItem>
              <MenuItem sx={{ fontSize: '12px', color: 'rgba(33, 33, 52, 0.6)' }} value="uncovered">
                Search intents without assets
              </MenuItem>
            </Styled.Select>
          </FormControl>
        </Box>
      </Styled.SearchContainer>

      {getIntents?.edges?.length > 0 ? (
        <TableContainer>
          <Table aria-label="collapsible table" size="small">
            <TableHead
              data-testid="tableHead"
              headCells={headerCells}
              order={desc ? SortType.Desc : SortType.Asc}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {getIntents.edges.map((row, index) => (
                <CollapsibleRow
                  key={row?.id}
                  row={row}
                  index={index}
                  hasRecommendations={intentWithRecommendationsIds?.includes(row?.id)}
                  onIntentSelect={(intentId) => handleIntentSelect(intentId, row)}
                  selectedIntentIds={selectedIntentIds}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer>
          <Table aria-label="sticky table">
            <TableHead
              data-testid="tableHead"
              headCells={headerCells}
              order={desc ? SortType.Desc : SortType.Asc}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              <Styled.TableCell
                sx={{
                  textAlign: 'center',
                  height: '150px',
                }}
                colSpan={5}
              >
                No Search Intents to Display
              </Styled.TableCell>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          borderTop: '1px solid #E5E5E5',
        }}
      >
        <CustomTablePagination
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          pageTotal={getIntents?.pageInfo?.total}
        />
      </Box>
      {selectedIntentIds.length > 0 && (
        <Box
          sx={{
            position: 'fixed',
            bottom: '32px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            width: 'auto',
          }}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
              padding: '12px 32px',
              fontSize: '1.1rem',
              minWidth: '300px',
              height: '56px',
              borderRadius: '28px',
            }}
            color="primary"
            onClick={handleOpenDeployModal}
            size="large"
          >
            Review and Deploy Content
          </Button>
        </Box>
      )}
      <DeployContentModal
        open={isDeployModalOpen}
        onClose={handleCloseDeployModal}
        selectedIntents={allSelectedIntents}
        onRemoveIntent={handleRemoveIntent}
        onDeploySuccess={handleDeploySuccess}
      />
    </>
  );
};

export default IntentsTable;
