/* eslint-disable max-len */
import { Tooltip } from '~/core/components/shared';
import { AdGroupSummarySortEnum, IntentsSortEnum, TrafficSegmentSortEnum } from '~/core/types/graphql.types';

import { InfoIcon } from './styles';

export const headerCells = [
  {
    id: IntentsSortEnum.Name,
    label: (
      <>
        <Tooltip
          offset={[0, -10]}
          placement="bottom"
          title="Search Intent (also known as “User Intent”) is the purpose behind a search. It is the content they are looking for. Understanding these intents will help you to acquire more customers."
        >
          <InfoIcon />
        </Tooltip>
        <span>Search Intent</span>
      </>
    ),
    numeric: false,
    disablePadding: true,
    sx: {
      '& .MuiTableSortLabel-root': {
        justifyContent: 'flex-end !important',
      },
    },
  },
  {
    id: IntentsSortEnum.Impressions,
    label: 'Impressions',
    numeric: false,
    disablePadding: true,
  },

  {
    id: IntentsSortEnum.Conversions,
    label: 'Conversions',
    numeric: false,
    disablePadding: true,
  },
  {
    id: IntentsSortEnum.Aligned,
    label: (
      <>
        <Tooltip
          offset={[0, -10]}
          placement="bottom"
          title="Content Coverage indicates which identified search intents have intent-aligned content and which do not."
        >
          <InfoIcon />
        </Tooltip>
        <span>Content Coverage</span>
      </>
    ),
    numeric: false,
    disablePadding: true,
    width: '233.33px',
    sx: {
      minWidth: '233.33px',
    },
  },
  {
    id: 'useContent',
    label: (
      <>
        <Tooltip offset={[0, -10]} placement="bottom" title="Select search intents to use their recommended content">
          <InfoIcon />
        </Tooltip>
        <span>Use Content</span>
      </>
    ),
    numeric: false,
    disablePadding: true,
    width: '150px',
    disableSort: true,
    sx: {
      minWidth: '120px',
    },
  },
  {
    id: '',
    label: '',
    numeric: false,
    disablePadding: true,
    width: '233.33px',
    sx: {
      minWidth: '233.33px',
    },
  },
];

export const adGroupHeaderCells = [
  {
    id: AdGroupSummarySortEnum.AdGroupName,
    label: 'Ad Groups',
    numeric: false,
    disablePadding: true,
    sx: {
      '& .MuiTableSortLabel-root': {
        justifyContent: 'flex-end !important',
      },
    },
  },
  {
    id: AdGroupSummarySortEnum.TotalSegments,
    label: 'Intent Segments',
    numeric: false,
    disablePadding: true,
    sx: {
      minWidth: '150px',
    },
  },
  {
    id: AdGroupSummarySortEnum.TotalKeywords,
    label: 'Total Keywords',
    numeric: false,
    disablePadding: true,
    sx: {
      minWidth: '150px',
    },
  },

  {
    id: AdGroupSummarySortEnum.KeywordsWithTraffic,
    label: 'Keywords with Traffic',
    numeric: false,
    disablePadding: true,
    sx: {
      minWidth: '190px',
    },
  },
  {
    id: AdGroupSummarySortEnum.TotalEligibleImpressions,
    label: 'Total Eligible Impr.',
    numeric: false,
    disablePadding: true,
    sx: {
      minWidth: '180px',
    },
  },
  {
    id: AdGroupSummarySortEnum.Impressions,
    label: 'Impr.',
    numeric: false,
    disablePadding: true,
    sx: {
      minWidth: '130px',
    },
  },
  {
    id: '',
    label: '',
    numeric: false,
    disablePadding: true,
    width: '233.33px',
    sx: {
      minWidth: '233.33px',
    },
  },
];

export const segmentsHeaderCells = [
  {
    id: TrafficSegmentSortEnum.SegmentName,
    label: (
      <>
        <Tooltip
          offset={[0, -10]}
          placement="right"
          title="Google assesses traffic beyond ad group boundaries, focusing on user behavior and keyword intent. Our Intent Segments mirror this by grouping traffic with similar search intent, often spanning multiple ad groups. This approach provides insights into audience behavior and aids in designing cohesive ad groups that align with Google's traffic perception."
        >
          <InfoIcon />
        </Tooltip>
        <span>Intent Segments</span>
      </>
    ),
    numeric: false,
    disablePadding: true,
    sx: {
      '& .MuiTableSortLabel-root': {
        justifyContent: 'flex-end !important',
      },
    },
  },
  // {
  //   id: TrafficSegmentSortEnum.AdGroups,
  //   label: 'Ad Groups',
  //   numeric: false,
  //   disablePadding: true,
  //   sx: {
  //     minWidth: '150px',
  //   },
  // },
  // {
  //   id: TrafficSegmentSortEnum.Keywords,
  //   label: 'Keywords',
  //   numeric: false,
  //   disablePadding: true,
  //   sx: {
  //     minWidth: '150px',
  //   },
  // },

  {
    id: TrafficSegmentSortEnum.Auctions,
    label: 'Total Eligible Impr.',
    numeric: false,
    disablePadding: true,
    sx: {
      minWidth: '190px',
    },
  },
  {
    id: TrafficSegmentSortEnum.Impressions,
    label: 'Actual Impr.',
    numeric: false,
    disablePadding: true,
    sx: {
      minWidth: '180px',
    },
  },
  {
    id: TrafficSegmentSortEnum.AuctionsLost,
    label: 'Missed Impr.',
    numeric: false,
    disablePadding: true,
    sx: {
      minWidth: '180px',
    },
  },
  {
    id: '',
    label: '',
    numeric: false,
    disablePadding: true,
    width: '233.33px',
    sx: {
      minWidth: '233.33px',
    },
  },
];
