import {
  IconButton,
  Input,
  Box as MuiBox,
  Select as MuiSelect,
  Tab as MuiTab,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Paper,
  Typography,
  styled,
} from '@mui/material';

import { CircularProgress } from '~/core/components/shared';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

export const FlexContainer = styled(MuiBox)({
  display: 'flex',
  background: '#fff',
});

export const CenterContainer = styled(MuiBox)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const ImageContainer = styled(MuiBox)({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignSelf: 'center',
});

export const Image = styled('img')({
  height: '228px',
  width: '228px',
  margin: '0 auto',
});

export const ImageHeading = styled('div')`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: 0.1px;
  text-align: center;
  color: rgba(33, 33, 52, 0.87);
`;

export const SupportLink = styled('a')`
  height: 20px;
  padding: 15px 0 4px;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.17px;
  color: #3154aa;
`;

export const Heading = styled(Typography)`
  height: 22px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.69;
  letter-spacing: 0.46px;
  color: rgba(33, 33, 52, 0.87);
  text-align: center;
`;

export const ProgressLoader = styled(CircularProgress)({
  height: '0px',
  position: 'absolute',
  top: '150px',
});

export const TableContainer = styled(MuiBox)({
  background: '#F0F0F3',
  width: '100%',
  overflow: 'auto',
  opacity: '0.5',
});

export const MapButton = styled('button')({
  width: '30px',
  border: 'none',
  color: 'rgba(74, 74, 106, 0.5)',
  background: 'none',
  '&:hover': {
    cursor: 'pointer',
  },
});

export const GeneratedText = styled(MuiBox)`
  font-size: 14px;
  line-height: 1.66;
  letter-spacing: 0.4px;
`;

export const SkeletonContainer = styled(MuiBox)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
  padding: '10px',
  background: '#f6f6f7',
  borderBottom: '1px solid #e5e5e5',
});

export const PaginationContainer = styled(MuiBox)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20px',
  borderTop: '1px solid rgba(33, 33, 52, 0.12)',
});

export const CustomPaper = styled(Paper)({
  maxHeight: '55vh',
  background: '#F0F0F3',
  width: '1',
});

export const TableHeader = styled(MuiBox)({
  background: '#F6F6F7',
  height: '43px',
  padding: '10px 35px',
  fontWeight: 'bold',
  lineHeight: 1.66,
  letterSpacing: '0.4px',
  color: 'rgba(33, 33, 52, 0.87)',
  borderBottom: '1px solid #E5E5E5',
  display: 'flex',
});

export const Circle = styled(MuiBox)({
  height: '15px',
  width: '15px',
  borderRadius: '50%',
});

// CollapisbleRow Styles

export const TableRow = styled(MuiTableRow)({
  '&:hover': {
    background: '#F6F6F7',
    cursor: 'pointer',
  },
  borderBottom: '0px solid',
});

export const Box = styled(MuiBox)`
  display: flex;
  justify-content: column;
  align-self: stretch;
  padding: 4px 0 4px 51px;
  border-radius: 6px;
  background: #f6f6f7;
  padding: 8px 12px;
  margin: 0 0 8px 48px;
`;

export const MainCell = styled(MuiTableCell)({
  paddingBottom: 0,
  paddingTop: 0,
  borderTop: '0px solid !important',
});

export const TableCell = styled(MuiTableCell)({
  color: 'rgb(33, 33, 52, 0.6)',
  height: '17px',
  fontSize: '12px',
  lineHeight: 1.43,
  letterSpacing: '0.17px',
});

export const CollapsedHeader = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: 0.1px;
  color: rgba(33, 33, 52, 0.87);
  margin-top: 24px;
`;

export const CollapsedTableLabel = styled(MuiTableCell)`
  height: 20px;
  flex-grow: 1;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.66;
  letter-spacing: 0.4px;
  color: rgba(33, 33, 52, 0.6);
`;

export const CollapsedTableCell = styled(MuiTableCell)({
  height: '20px',
  width: '52%',
  flexGrow: '1',
  fontSize: '12px',
  lineHeight: 1.66,
  letterSpacing: '0.4px',
  color: 'rgba(33, 33, 52, 0.87)',
  border: 'none',
});

export const IconContainer = styled(IconButton)({
  color: 'rgba(74, 74, 106, 0.5)',
  margin: '0 10px',
  '&:hover': {
    background: 'none',
  },
});

export const NoAssetsText = styled(Typography)`
  width: 73px;
  height: 14px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: rgba(33, 33, 52, 0.38);
`;

export const TabsContainer = styled(MuiBox)({
  borderBottom: '1px solid rgba(33,33,52,0.12)',
  minWidth: '1050px',
  marginTop: '32px',
});

export const Tab = styled(MuiTab)({
  flexGrow: 1,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: 1.71,
  letterSpacing: '0.4px',
  textTransform: 'none',
  color: 'rgba(33, 33, 52, 0.6)',
  '&.Mui-selected': {
    color: '#37345b',
    background: '#f6f6f7',
  },
});

export const SearchContainer = styled(MuiBox)`
  height: 54px;
  width: 100%;
  padding: 8px 0;
  padding-left: 32px;
  gap: 10px;
  background-color: #f6f6f7;
  border-bottom: 1px solid #e5e5e5;
`;

export const SearchBar = styled(MuiBox)`
  width: 340px;
  height: 38px;
  flex-grow: 0;
  display: flex;
  justify-content: flex-start;
  align-self: center;
  padding: 6px 12px;
  border-radius: 6px;
  border: solid 1px rgba(33, 33, 52, 0.23);
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.15px;
  background-color: #ffffff;
`;

export const SearchInput = styled(Input)({
  width: '340px',
  height: '38px',
  flexGrow: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignSelf: 'center',
  padding: '6px 12px',
  border: 'none',
  fontSize: '12px',
  lineHeight: 1,
  letterSpacing: '0.15px',
  textDecoration: 'none !important',

  '&.MuiInput-root': {
    outline: 'none !important',
  },

  '&.MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '&.MuiInput-underline:after': {
    borderBottom: 'none',
  },
  '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: 'none',
  },
});

export const Select = styled(MuiSelect)({
  fontSize: '12px',
  lineHeight: 1.67,
  letterSpacing: '0.4px',
  minWidth: '220px',
  backgroundColor: '#ffffff',
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid rgba(33, 33, 52, 0.23)',
  },
});
