import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { formatDateToMonthAndYear } from '~/core/helpers';
import { useGetAssessmentStatuses } from '~/core/services/graphql/sourceAccounts/hooks';
import { GetAssessmentStatusesQueryVariables } from '~/core/services/graphql/sourceAccounts/sourceAccounts.queries.generated';
import { useContexts } from '~/store/context/useContext';
import { integrationAccountsSelector } from '~/store/selectors/integrationAccounts.selector';

import * as Styled from './styles';

type DateSelectorProps = {
  demoMode?: boolean;
};

export const DateSelector: React.FC<DateSelectorProps> = ({ demoMode }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    selectedPlatformAccountId,
    selectedSourceAccountId,
    selectedDateRange,
    setSelectedDateRange,
    campaignSummariesLoading,
  } = useContexts();
  const integrationAccounts = useSelector(integrationAccountsSelector);

  const dateKeysMap: Record<string, string> = {};
  const sourceAccountIds = integrationAccounts?.map(({ customerId }) => customerId);

  const getAssessmentStatusesSkip = !selectedSourceAccountId || !selectedPlatformAccountId;
  const getAssessmentStatusesVariables: GetAssessmentStatusesQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerId: selectedSourceAccountId,
  };

  const { assessmentStatusLoading, getAssessmentStatuses } = useGetAssessmentStatuses(
    getAssessmentStatusesSkip,
    getAssessmentStatusesVariables,
  );
  const availableDates = getAssessmentStatuses?.assessmentJobs.map(({ dateRange }) => dateRange) || [];

  availableDates
    .sort((a, b) => b.localeCompare(a))
    .forEach((key) => {
      const startDate = key.split(' | ')[0];
      dateKeysMap[key] = formatDateToMonthAndYear(startDate);
    });

  const handleDateChange = (value: string) => {
    if (demoMode) return;
    searchParams.set('dateRange', value);
    setSearchParams(searchParams, { replace: true });
    setSelectedDateRange(value);
  };

  useEffect(() => {
    const firstAvailableDate = availableDates[0];
    if (!selectedDateRange && firstAvailableDate) {
      setSelectedDateRange(firstAvailableDate);
    }
  }, [getAssessmentStatuses, selectedDateRange]);

  if (!sourceAccountIds || assessmentStatusLoading || campaignSummariesLoading) {
    return (
      <Box display="flex" alignItems="center" height="55px">
        <Styled.CustomSkeleton variant="text" animation="wave" />
      </Box>
    );
  }

  return (
    <Styled.Select
      data-testid="dateSelector"
      value={selectedDateRange || ''}
      disabled={!selectedDateRange || !availableDates.length}
    >
      {Object.entries(dateKeysMap).map(([key, availableDate], index) => (
        <Styled.CustomMenuItem
          key={key}
          data-testid={`menuItem-${index}`}
          onClick={() => handleDateChange(key)}
          value={key || ''}
        >
          {availableDate}
        </Styled.CustomMenuItem>
      ))}
    </Styled.Select>
  );
};

export default DateSelector;
