/* eslint-disable no-nested-ternary */
import { Button, Chip, TableCell, TableRow, TableRowProps } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { convertToTitleCase, numberWithCommas } from '~/core/helpers';
import { AdGroupSummarySchema, TrafficSegmentSchema } from '~/core/types/graphql.types';
import { useContexts } from '~/store/context/useContext';
import { Tooltip } from '~/core/components/shared';

import * as Styled from '../styles';
import KeywordsDialog from './KeywordsDialog';

interface SegmentsTableRowProps extends TableRowProps {
  row: TrafficSegmentSchema;
  index: number;
}

// SegmentsTableRow Component Definition
const SegmentsTableRow: React.FC<SegmentsTableRowProps> = ({ row, ...props }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedCampaignId, setSelectedAdGroupId, selectedSourceAccountId, selectedAccountName, selectedDateRange } =
    useContexts();
  const [open, setOpen] = useState(false);
  const [adGroupDialogOpen, setAdGroupDialogOpen] = useState(false);

  let url = `intent-themes?`;

  if (selectedCampaignId) {
    url += `&campaignId=${selectedCampaignId}`;
  }

  if (selectedDateRange) {
    url += `&dateRange=${selectedDateRange}`;
  }

  if (selectedSourceAccountId) {
    url += `&sourceAccountId=${selectedSourceAccountId}`;
  }

  if (row?.segmentId) {
    url += `&segmentId=${row?.segmentId}`;
  }

  if (selectedAccountName) {
    url += `&accountName=${selectedAccountName}`;
  }

  if (row?.segmentName) {
    url += `&segmentName=${row?.segmentName}`;
  }

  const handleNoLabel = (id: string): boolean => {
    const NO_LABEL_ID = '00000000-0000-0000-0000-000000000000';
    return id === NO_LABEL_ID;
  };

  const handleClick = (event: any, row: AdGroupSummarySchema) => {
    event.stopPropagation();
    setSelectedAdGroupId(row?.adGroupId);
    // searchParams.set('campaignName', campaignName);
    setSearchParams(searchParams);

    navigate(url);
  };

  return (
    <>
      <TableRow
        sx={{
          background: '#ffffff',
        }}
        key={row?.segmentId}
        onClick={() => setOpen(!open)}
        {...props}
      >
        {handleNoLabel(row?.segmentId) ? (
          <TableCell
            size="small"
            data-testid="name"
            sx={{
              minWidth: '240px',
              paddingLeft: '40px',
            }}
          >
            <span style={{ opacity: '50%', marginRight: '8px' }}>Segment</span>
            <Tooltip
              title="This can happen when there is keyword activity,
              but not enought to extract Search Intent to define a Segment or it's Themes."
              sx={{
                cursor: 'default',
              }}
            >
              <Chip label="Insufficient Traffic" color="error" size="small" variant="outlined" />
            </Tooltip>
          </TableCell>
        ) : (
          <TableCell
            size="small"
            data-testid="name"
            sx={{
              minWidth: '240px',
              paddingLeft: '40px',
            }}
          >
            {convertToTitleCase(row?.segmentName)}
          </TableCell>
        )}
        {/* <Styled.MainTableCell size="small">{row?.adGroups}</Styled.MainTableCell> */}
        {/* <Styled.MainTableCell size="small">{fetchCampaignName(row?.campaignId)}</Styled.MainTableCell> */}
        {/* <Styled.MainTableCell size="small">
          <>
            {row?.keywords}{' '}
            <Typography
              onClick={() => setAdGroupDialogOpen(true)}
              sx={{
                display: 'inline',
                color: '#3154aa',
                fontSize: '14px',
                lineHeight: 1.43,
                letterSpacing: '0.17px',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              View
            </Typography>
          </>
        </Styled.MainTableCell> */}
        <Styled.MainTableCell size="small">
          {numberWithCommas(Number(row?.auctions?.toFixed(0)))}

          {/* // ! This is the part that needs to be fixed */}
        </Styled.MainTableCell>
        <>
          <Styled.MainTableCell
            size="small"
            sx={{
              color: '#324ba4',
            }}
          >
            {numberWithCommas(row?.impressions)}
          </Styled.MainTableCell>
          <Styled.MainTableCell
            size="small"
            color="#e76e50"
            sx={{
              color: '#e76e50',
            }}
          >
            {numberWithCommas(row?.auctionsLost?.toFixed(0))}
          </Styled.MainTableCell>
          <Styled.MainTableCell size="small">
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={(e) => handleClick(e, null)}
              sx={{
                height: '30px',
                width: '95px',
                padding: '0px',
                fontSize: '10px',
                fontWeight: 600,
                lineHeight: 2.2,
                letterSpacing: '0.46px',
                textTransform: 'none',
              }}
              disabled={handleNoLabel(row?.segmentId)}
              // disabled={row?.impressions === 0}
            >
              View Segment
            </Button>
          </Styled.MainTableCell>
        </>
      </TableRow>

      {open && (
        <KeywordsDialog
          open={adGroupDialogOpen}
          setDialogOpen={setAdGroupDialogOpen}
          segmentId={row?.segmentId}
          segmentName={row?.segmentName || row?.segmentId}
        />
      )}
    </>
  );
};

export default SegmentsTableRow;
