export enum SidebarLabels {
  MotiveMap = 'MotiveMap',
  Opportunities = 'Content Gap Analysis',
  Segments = 'Traffic Gap Analysis',
  Admin = 'Admin',
  Support = 'Support',
}

export enum SidebarLinks {
  MotiveMap = '/motive-map',
  Opportunities = '/content-gap-analysis',
  Segments = '/traffic-gap-analysis',
  Admin = '/admin/settings',
  Support = 'https://motivemetrics.atlassian.net/servicedesk/customer/portals',
  UserOptions = '/my-account',
}
