import { Box, Slider, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-charts';

import { CircularProgress, Tooltip } from '~/core/components/shared';
import { numberWithCommas } from '~/core/helpers';
import { useGetKeywordComparison } from '~/core/services/graphql/keywords/hooks';
import { useContexts } from '~/store/context/useContext';

import { InfoIcon } from '../ContentGapAnalysis/components/BubbleChart/styles';

const keyItems = [
  { color: 'rgb(33,33,52,0.87)', label: 'current' },
  { color: '#8BA7FF', label: 'recommended' },
];

const RecommendationsVisual = () => {
  const elementType = 'bar';
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange, selectedCampaignId } = useContexts();
  const [keys, setKeys] = useState(keyItems.map((item) => item.label));

  const getKeywordComparisonVariables = useMemo(() => {
    const variables = {
      platformAccountId: selectedPlatformAccountId,
      customerId: selectedSourceAccountId,
      dateRange: selectedDateRange,
    };
    if (selectedCampaignId) {
      variables.campaignId = selectedCampaignId;
    }
    return variables;
  }, [selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange, selectedCampaignId]);

  const skip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange;
  const { getKeywordComparison, keywordComparisonssLoading } = useGetKeywordComparison(
    skip,
    getKeywordComparisonVariables,
  );

  const calculateBaseMax = () => {
    if (!getKeywordComparison) return 0;

    const currentData = getKeywordComparison.currentAdGroups || [];
    const recommendedData = getKeywordComparison.recommendedAdGroups || [];

    // Get all unique totalKeywords values
    const allTotalKeywords = new Set([
      ...currentData.map((item) => item.totalKeywords),
      ...recommendedData.map((item) => item.totalKeywords),
    ]);

    // Return the count of unique keyword groups minus 1 (for 0-based index)
    return Math.max(0, allTotalKeywords.size - 1);
  };

  const baseMax = calculateBaseMax();

  const [sliderRange, setSliderRange] = useState([0, 0]);

  useEffect(() => {
    if (getKeywordComparison) {
      const maxRange = calculateBaseMax();
      setSliderRange([0, maxRange]);
    }
  }, [getKeywordComparison]);

  const processedDataset = useMemo(() => {
    if (!getKeywordComparison) return [];
    const currentData = getKeywordComparison.currentAdGroups || [];
    const recommendedData = getKeywordComparison.recommendedAdGroups || [];

    // Create a set of all unique totalKeywords values
    const allTotalKeywords = new Set([
      ...currentData.map((item) => item.totalKeywords),
      ...recommendedData.map((item) => item.totalKeywords),
    ]);

    // Create a map to store the processed data
    const processedMap = new Map();

    allTotalKeywords.forEach((totalKeywords) => {
      const currentItem = currentData.find((item) => item.totalKeywords === totalKeywords) || {
        totalKeywords,
        totalAdGroups: 0,
      };
      const recommendedItem = recommendedData.find((item) => item.totalKeywords === totalKeywords) || {
        totalKeywords,
        totalAdGroups: 0,
      };

      processedMap.set(totalKeywords, {
        current: currentItem,
        recommended: recommendedItem,
      });
    });

    // Convert the map to an array and sort by totalKeywords
    const processedArray = Array.from(processedMap.values()).sort(
      (a, b) => a.current.totalKeywords - b.current.totalKeywords,
    );

    // Apply slider range after sorting
    const slicedArray = processedArray.slice(sliderRange[0], sliderRange[1] + 1);

    return [
      { label: 'current', data: slicedArray.map((item) => item.current) },
      { label: 'recommended', data: slicedArray.map((item) => item.recommended) },
    ].filter((item) => keys.includes(item.label));
  }, [getKeywordComparison, keys, sliderRange]);

  const primaryAxis = useMemo(
    () => ({
      position: 'bottom',
      getValue: (datum) => datum?.totalKeywords,
      elementType: 'bar',
      scaleType: 'band',
      min: 0,
      showTicks: true,
      padBandRange: true,
      innerBandPadding: 0.3,
      outerBandPadding: 0.3,
    }),
    [elementType],
  );

  const secondaryAxes = useMemo(
    () => [
      {
        position: 'left',
        getValue: (datum) => datum?.totalAdGroups,
        min: 0,
        showGrid: true,
      },
    ],
    [],
  );

  const currentKeywordsTotal = useMemo(
    () =>
      getKeywordComparison?.currentAdGroups
        .slice(sliderRange[0], sliderRange[1] + 1)
        .reduce((acc, curr) => acc + curr.totalKeywords * curr.totalAdGroups, 0),
    [getKeywordComparison, sliderRange],
  );
  const recommendedKeywordsTotal = useMemo(
    () =>
      getKeywordComparison?.recommendedAdGroups
        .slice(sliderRange[0], sliderRange[1] + 1)
        .reduce((acc, curr) => acc + curr.totalKeywords * curr.totalAdGroups, 0),
    [getKeywordComparison, sliderRange],
  );
  const currentAdGroupsTotal = getKeywordComparison?.currentAdGroups
    .slice(sliderRange[0], sliderRange[1] + 1)
    .reduce((acc, curr) => acc + curr.totalAdGroups, 0);
  const recommendedAdGroupsTotal = getKeywordComparison?.recommendedAdGroups
    .slice(sliderRange[0], sliderRange[1] + 1)
    .reduce((acc, curr) => acc + curr.totalAdGroups, 0);

  const noData = !getKeywordComparison?.currentAdGroups?.length && !getKeywordComparison?.recommendedAdGroups?.length;

  const hasData =
    getKeywordComparison?.currentAdGroups?.length > 0 || getKeywordComparison?.recommendedAdGroups?.length > 0;

  const handleKeyClick = (keyLabel) => {
    if (keys.includes(keyLabel)) {
      if (keys.length === 1) return;
      setKeys(keys.filter((k) => k !== keyLabel));
    } else {
      setKeys([...keys, keyLabel]);
    }

    // Calculate the proper max for the slider based on the unique keywords count
    const currentData = getKeywordComparison?.currentAdGroups || [];
    const recommendedData = getKeywordComparison?.recommendedAdGroups || [];

    // Get all unique totalKeywords values
    const allTotalKeywords = new Set([
      ...currentData.map((item) => item.totalKeywords),
      ...recommendedData.map((item) => item.totalKeywords),
    ]);

    // Use the count of unique values as the max index
    const maxValue = allTotalKeywords.size - 1;

    setSliderRange([0, Math.max(0, maxValue)]);
  };

  const handleSliderChange = useCallback(
    (_, newValue) => {
      // Ensure newValue is an array of two numbers
      if (!Array.isArray(newValue) || newValue.length !== 2) {
        return;
      }

      // Convert values to numbers
      const validatedRange = newValue.map(Number);

      // Ensure the range is within valid bounds
      const maxIndex = baseMax;

      const clampedRange = [
        Math.max(0, Math.min(validatedRange[0], maxIndex)),
        Math.max(0, Math.min(validatedRange[1], maxIndex)),
      ];

      setSliderRange(clampedRange);
    },
    [baseMax],
  );

  if (!selectedDateRange || keywordComparisonssLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          justifyContent: 'center',
          marginLeft: '100px',
          height: '400px',
        }}
      >
        <CircularProgress />
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '12px',
            lineHeight: 1.66,
            letterSpacing: '0.4px',
            color: 'rgba(33, 33, 52, 0.6)',
          }}
        >
          Loading Visualization
        </Typography>
      </Box>
    );
  }

  if (noData) {
    return (
      <Box
        sx={{
          width: '100%',
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
            color: 'rgb(33,33,52,0.87)',
            minWidth: '660px',
          }}
        >
          No data available
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          mb: 2,
        }}
      >
        {keyItems.map((item) => (
          <Box
            key={item.label}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              cursor: 'pointer',
              opacity: keys.includes(item.label) ? 1 : 0.4,
            }}
            onClick={() => handleKeyClick(item.label)}
          >
            <Box
              sx={{
                width: 30,
                height: 10,
                backgroundColor: item.color,
              }}
            />
            <Typography sx={{ fontSize: '0.875rem', color: 'rgb(33,33,52,0.87)' }}>
              {item.label.charAt(0).toUpperCase() + item.label.slice(1)}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '235px',
          left: '-35px',
          transform: 'rotate(-90deg)',
          transformOrigin: 'left top 0',
          fontSize: '12px',
        }}
      >
        Total Ad Groups
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: '-75px',
          left: '50%',
          transform: 'translateX(-50%)',
          fontSize: '12px',
        }}
      >
        Keywords Per Ad Group{' '}
        <Tooltip
          sx={{
            position: 'absolute',
            zIndex: 2,
            right: '-20px',
            fontSize: '20px',
          }}
          placement="right"
          // eslint-disable-next-line max-len
          title="Intent Segments refer to a collection of keyword traffic sent by Google that is closely related in intent based on Google's determination. They can be used to organize paid search campaigns into well-structured, thematically aligned ad groups."
        >
          <InfoIcon />
        </Tooltip>
      </Box>
      {hasData &&
        selectedDateRange &&
        (getKeywordComparison?.currentAdGroups?.length > 0 ||
          getKeywordComparison?.recommendedAdGroups?.length > 0) && (
          <>
            <Chart
              options={{
                data: processedDataset,
                primaryAxis,
                secondaryAxes,
                getSeriesStyle: (series) => ({
                  color: series.label === 'current' ? 'rgb(33,33,52,0.87)' : '#8BA7FF',
                }),
                dark: false,
                padding: {
                  left: 40,
                  bottom: 40,
                  top: 20,
                  right: 20,
                },
              }}
            />

            <Box sx={{ width: '100%', mt: 2 }}>
              <Slider
                size="small"
                value={sliderRange}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                min={0}
                max={baseMax}
                valueLabelFormat={() => `Adjust Range`}
                step={1}
                sx={{
                  position: 'absolute',
                  bottom: '-57px',
                  right: '0px',
                  width: '96%',
                  '& .MuiSlider-track': {
                    height: '8px',
                    borderRadius: '4px',
                    background: '#D9D9D9',
                  },
                  '& .MuiSlider-rail': {
                    height: '8px',
                    borderRadius: '4px',
                    background: '#D9D9D9',
                  },
                  '& .MuiSlider-thumb': {
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    background: '#D9D9D9',
                    border: '1px solid #fff',
                  },
                }}
              />
            </Box>
          </>
        )}

      <Box
        sx={{
          position: 'absolute',
          top: '40px',
          // height: '250px',
          right: '-230px',
          gap: '5px',
          padding: '20px',
          backgroundColor: 'white',
          borderRadius: '5px',
          boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
        }}
      >
        <Box
          sx={{
            // borderBottom: '1px solid #E5E7EB',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography fontSize={18} sx={{ fontWeight: 'bold', color: 'rgb(33,33,52,0.87)' }}>
            Summary
          </Typography>
          <Box
            sx={{
              paddingY: '8px',
              paddingBottom: '5px',
              borderBottom: '1px solid #E5E7EB',
            }}
          >
            <Typography sx={{ color: 'rgb(33,33,52,0.87)', fontSize: '13px', fontWeight: 'bold' }}>Segments</Typography>
            <Typography fontSize={20} fontWeight={600}>
              {numberWithCommas(getKeywordComparison?.totalSegments) || 'N/A'}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px', marginTop: '5px' }}>
          <Typography variant="body2" sx={{ color: 'rgb(33,33,52,0.87)', fontSize: '12px', fontWeight: 'bold' }}>
            Keywords
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '40px',
              paddingBottom: '5px',
              borderBottom: '1px solid #E5E7EB',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography fontSize={12}>Current</Typography>
              <Typography fontSize={16} fontWeight={600}>
                {numberWithCommas(currentKeywordsTotal)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '2px',
                fontSize: '12px',
              }}
            >
              <Typography fontSize={12}>Recommended</Typography>
              <Typography
                fontSize={16}
                fontWeight={600}
                sx={{
                  color: keyItems[1].color,
                }}
              >
                {numberWithCommas(recommendedKeywordsTotal)}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px', marginTop: '5px' }}>
            <Typography variant="body2" sx={{ color: 'rgb(33,33,52,0.87)', fontSize: '12px', fontWeight: 'bold' }}>
              Ad Groups
            </Typography>

            <Box
              sx={{
                display: 'flex',
                gap: '40px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Typography fontSize={12}>Current</Typography>
                <Typography fontSize={16} fontWeight={600}>
                  {numberWithCommas(currentAdGroupsTotal)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Typography fontSize={12}>Recommended</Typography>
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  sx={{
                    color: keyItems[1].color,
                  }}
                >
                  {numberWithCommas(recommendedAdGroupsTotal)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RecommendationsVisual;
