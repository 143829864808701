import { LinearProgress, Link, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { AuthContainer } from '~/core/components/layouts/AuthContainer';
import { SignInPayload } from '~/core/types/auth.types';
import { loginAction } from '~/store/actions/auth.action';
import { authLoadingSelector, authUserSelector } from '~/store/selectors/auth.selector';

import { SignInForm } from './components/SignInForm';
import * as Styled from './styles';

const SignInPage: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);
  const isLoading = useSelector(authLoadingSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('../../traffic-gap-analysis', { replace: true });
    }
  }, [user, navigate]);

  const onSubmit = (data: SignInPayload) => {
    dispatch(loginAction.request(data));
  };

  return (
    <AuthContainer isSignIn data-testid="signInContainer">
      {isLoading && <LinearProgress />}
      <Typography align="center" fontSize={24} fontWeight="700">
        Sign In
      </Typography>

      <SignInForm onSubmit={onSubmit} />
      <Styled.LinksContainer>
        <Link fontSize={16} underline="none" href="/auth/password-reset-request">
          Forgot Password?
        </Link>
        <Styled.LinksContainerRow>
          {`No account? `}
          <Link fontSize={16} underline="none" href="/auth/create-account">
            Sign Up
          </Link>
        </Styled.LinksContainerRow>
      </Styled.LinksContainer>
    </AuthContainer>
  );
};

export default SignInPage;
