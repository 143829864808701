import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Typography } from '@mui/material';
import { ExpandMore, AddCircle, Cancel } from '@mui/icons-material';

import { GroupedAssets } from '../types';

interface AssetsStepProps {
  groupedAssets: GroupedAssets;
  deploymentName: string;
  nameError: string;
  maxNameLength: number;
  onDeploymentNameChange: (value: string) => void;
  isAssetRemoved: (
    campaignId: string,
    adGroupId: string,
    assetText: string,
    assetType: 'HEADLINE' | 'DESCRIPTION',
  ) => boolean;
  handleRemoveAsset: (
    campaignId: string,
    adGroupId: string,
    assetText: string,
    assetType: 'HEADLINE' | 'DESCRIPTION',
  ) => void;
  handleRestoreAsset: (
    campaignId: string,
    adGroupId: string,
    assetText: string,
    assetType: 'HEADLINE' | 'DESCRIPTION',
  ) => void;
  getRemovedAssetCount: (campaignId: string, adGroupId: string) => { headlines: number; descriptions: number };
}

export const AssetsStep: React.FC<AssetsStepProps> = ({
  groupedAssets,
  deploymentName,
  nameError,
  maxNameLength,
  onDeploymentNameChange,
  isAssetRemoved,
  handleRemoveAsset,
  handleRestoreAsset,
  getRemovedAssetCount,
}) => (
  <>
    <Box mb={3}>
      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
        Deployment Name
      </Typography>
      <input
        type="text"
        value={deploymentName}
        onChange={(e) => onDeploymentNameChange(e.target.value)}
        maxLength={maxNameLength}
        placeholder="Enter a name for this deployment"
        style={{
          width: '100%',
          padding: '8px',
          border: `1px solid ${nameError ? '#d32f2f' : '#ccc'}`,
          borderRadius: '4px',
          fontSize: '16px',
        }}
      />
      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
        {nameError && (
          <Typography variant="caption" color="error">
            {nameError}
          </Typography>
        )}
        <Typography variant="caption" color={deploymentName.length > maxNameLength ? 'error' : 'text.secondary'}>
          {deploymentName.length}/{maxNameLength}
        </Typography>
      </Box>
    </Box>
    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
      Assets by Campaign
    </Typography>
    {Object.entries(groupedAssets).map(([campaignId, campaign]) => (
      <Box
        key={campaignId}
        sx={{
          mb: 2,
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            px: 2,
            py: 1.5,
            borderBottom: '1px solid',
            borderColor: 'divider',
            bgcolor: 'background.paper',
          }}
        >
          <Typography variant="h6">
            Campaign: {campaign.campaignName} ({campaignId})
          </Typography>
        </Box>
        {Object.entries(campaign.adGroups).map(([adGroupId, adGroup]) => (
          <Accordion
            key={adGroupId}
            defaultExpanded
            sx={{
              '&:before': { display: 'none' },
              boxShadow: 'none',
              borderBottom: '1px solid',
              borderColor: 'divider',
              '&:last-child': {
                borderBottom: 'none',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                px: 2,
                minHeight: '48px',
                '& .MuiAccordionSummary-content': {
                  padding: '12px 0',
                },
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1">
                  Ad Group: {adGroup.adGroupName} ({adGroupId})
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Chip
                    label={`${
                      adGroup.assets.headlines.length - getRemovedAssetCount(campaignId, adGroupId).headlines
                    } Headlines`}
                    size="small"
                    sx={{ mr: 1 }}
                    onClick={() => {
                      adGroup.assets.headlines.forEach((asset) => {
                        const isRemoved = isAssetRemoved(campaignId, adGroupId, asset.assetText, 'HEADLINE');
                        if (isRemoved) {
                          handleRestoreAsset(campaignId, adGroupId, asset.assetText, 'HEADLINE');
                        } else {
                          handleRemoveAsset(campaignId, adGroupId, asset.assetText, 'HEADLINE');
                        }
                      });
                    }}
                  />
                  <Chip
                    label={`${
                      adGroup.assets.descriptions.length - getRemovedAssetCount(campaignId, adGroupId).descriptions
                    } Descriptions`}
                    size="small"
                    onClick={() => {
                      adGroup.assets.descriptions.forEach((asset) => {
                        const isRemoved = isAssetRemoved(campaignId, adGroupId, asset.assetText, 'DESCRIPTION');
                        if (isRemoved) {
                          handleRestoreAsset(campaignId, adGroupId, asset.assetText, 'DESCRIPTION');
                        } else {
                          handleRemoveAsset(campaignId, adGroupId, asset.assetText, 'DESCRIPTION');
                        }
                      });
                    }}
                  />
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 2, py: 1.5, bgcolor: 'background.default' }}>
              <Box>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Headlines
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2 }}>
                  {adGroup.assets.headlines.length === 0 && (
                    <Typography variant="body2" color="text.secondary" m={1}>
                      No headlines found
                    </Typography>
                  )}
                  {adGroup.assets.headlines.map((asset) => {
                    const isRemoved = isAssetRemoved(campaignId, adGroupId, asset.assetText, 'HEADLINE');
                    return (
                      <Chip
                        key={`headline-${campaignId}-${adGroupId}-${asset.assetText}`}
                        label={asset.assetText}
                        onClick={() => {
                          if (isRemoved) {
                            handleRestoreAsset(campaignId, adGroupId, asset.assetText, 'HEADLINE');
                          } else {
                            handleRemoveAsset(campaignId, adGroupId, asset.assetText, 'HEADLINE');
                          }
                        }}
                        onDelete={() => {
                          if (isRemoved) {
                            handleRestoreAsset(campaignId, adGroupId, asset.assetText, 'HEADLINE');
                          } else {
                            handleRemoveAsset(campaignId, adGroupId, asset.assetText, 'HEADLINE');
                          }
                        }}
                        deleteIcon={isRemoved ? <AddCircle /> : <Cancel />}
                        color={isRemoved ? 'default' : 'primary'}
                        sx={{
                          maxWidth: '100%',
                          cursor: 'pointer',
                          '& .MuiChip-label': {
                            whiteSpace: 'normal',
                            height: 'auto',
                            padding: '8px 12px',
                          },
                        }}
                      />
                    );
                  })}
                </Box>

                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Descriptions
                </Typography>
                {adGroup.assets.descriptions.length === 0 && (
                  <Typography variant="body2" color="text.secondary" m={1}>
                    No descriptions found
                  </Typography>
                )}
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {adGroup.assets.descriptions.map((asset) => {
                    const isRemoved = isAssetRemoved(campaignId, adGroupId, asset.assetText, 'DESCRIPTION');
                    return (
                      <Chip
                        key={`description-${campaignId}-${adGroupId}-${asset.assetText}`}
                        label={asset.assetText}
                        onClick={() => {
                          if (isRemoved) {
                            handleRestoreAsset(campaignId, adGroupId, asset.assetText, 'DESCRIPTION');
                          } else {
                            handleRemoveAsset(campaignId, adGroupId, asset.assetText, 'DESCRIPTION');
                          }
                        }}
                        onDelete={() => {
                          if (isRemoved) {
                            handleRestoreAsset(campaignId, adGroupId, asset.assetText, 'DESCRIPTION');
                          } else {
                            handleRemoveAsset(campaignId, adGroupId, asset.assetText, 'DESCRIPTION');
                          }
                        }}
                        deleteIcon={isRemoved ? <AddCircle /> : <Cancel />}
                        color={isRemoved ? 'default' : 'primary'}
                        sx={{
                          maxWidth: '100%',
                          cursor: 'pointer',
                          '& .MuiChip-label': {
                            whiteSpace: 'normal',
                            height: 'auto',
                            padding: '8px 12px',
                          },
                        }}
                      />
                    );
                  })}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    ))}
  </>
);
