import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Divider, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { Breadcrumbs, Button, CircularProgress, DateSelector, SelectorMenu, Tooltip } from '~/core/components/shared';
import ZoomableCirclePacking from '~/core/components/shared/ZoomableCirclePacking/ZoomableCirclePacking';
import { useContexts } from '~/store/context/useContext';
import { IntentThemesSortEnum, QueryGetIntentThemesArgs } from '~/core/types/graphql.types';
import { useGetIntentThemes, useGetIntentThemeTree } from '~/core/services/graphql/intents/hooks';
import { numberWithCommas } from '~/core/helpers';
import { CustomMenuItem, CustomSkeleton, Select } from '~/core/components/shared/Selectors/DateSelector/styles';
import { useGetKeywordsText } from '~/core/services/graphql/keywords/hooks';
import { GetKeywordsTextQueryVariables } from '~/core/services/graphql/keywords/keywords.queries.generated';

import { InfoIcon } from '../../../ContentGapAnalysis/components/BubbleChart/styles';

const Header = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: rgba(33, 33, 52, 0.87);
  text-transform: capitalize;
`;

const ChartDetails = styled('p')`
  height: 20px;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.4px;
  text-align: center;
  color: rgba(33, 33, 52, 0.6);
  margin: 0 0 14px 0;
`;

const ChartBubble = styled(Box)({
  backgroundColor: '#2584DF',
  borderRadius: '50%',
  border: '2px solid #D1D6DD',
  padding: 12,
  height: '100%',
  width: '100%',
  maxWidth: 'calc(100vh - 380px)',
});

const IntentThemes = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange } = useContexts();
  const orderBy = IntentThemesSortEnum.Impressions;
  const desc = true;
  const pageSize = 25;
  const [page, setPage] = useState<number>(0);
  const [currentDepth, setCurrentDepth] = useState<number>(0);
  const [selectedKeyword, setSelectedKeyword] = useState<string | null>(null);
  const [cachedIntentThemes, setCachedIntentThemes] = useState<any>(null);

  const getQueryValue = useCallback(
    (selected: string | null, queryParam: string) => selected || searchParams.get(queryParam),
    [searchParams],
  );
  const platformAccountId = useMemo(
    () => getQueryValue(selectedPlatformAccountId?.toString(), 'platformAccountId') as string,
    [getQueryValue, selectedPlatformAccountId] as const,
  );
  const sourceAccountId =
    useMemo(
      () => getQueryValue(selectedSourceAccountId, 'sourceAccountId'),
      [getQueryValue, selectedSourceAccountId],
    ) || searchParams.get('sourceAccountId');

  const dateRange =
    useMemo(() => getQueryValue(selectedDateRange, 'dateRange'), [getQueryValue, selectedDateRange]) ||
    searchParams.get('dateRange');

  const adGroupId = useMemo(() => searchParams.get('adGroupId'), [searchParams]);
  const adGroupName = useMemo(() => searchParams.get('adGroupName'), [searchParams]);
  const segmentId = useMemo(() => searchParams.get('segmentId'), [searchParams]);
  const segmentName = useMemo(() => searchParams.get('segmentName'), [searchParams]);
  const tableType = searchParams.get('tableType');
  const defaultFilters = [{ fieldName: 'segment_id', value: segmentId, operation: 'eq' }];

  if (adGroupId) {
    defaultFilters.push({ fieldName: 'ad_group_id', value: adGroupId, operation: 'eq' });
  }

  const [filters, setFilters] = useState(defaultFilters);

  const importedState = useMemo(() => {
    const params = { platformAccountId, sourceAccountId, dateRange, adGroupId, adGroupName, segmentId, tableType };
    return Object.entries(params).reduce((acc: any, [key, value]) => {
      if (value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }, [platformAccountId, sourceAccountId, dateRange, searchParams, adGroupId, adGroupName, segmentId]);

  const intentThemesSkip = !segmentId;

  useEffect(() => {
    if (selectedKeyword) {
      if (selectedKeyword === 'All Keywords') {
        setFilters(defaultFilters);
        return;
      }
      setFilters([...defaultFilters, { fieldName: 'keyword_text', value: selectedKeyword, operation: 'eq' }]);
    }
  }, [selectedKeyword]);

  const intentThemesVariables: QueryGetIntentThemesArgs = {
    platformAccountId: selectedPlatformAccountId,
    customerId: selectedSourceAccountId,
    dateRange: selectedDateRange,
    filterField: {
      filters,
    },
    sortBy: { field: orderBy, desc },
    page: page + 1,
    pageSize,
  };

  const { getIntentThemes, intentThemesLoading } = useGetIntentThemes(intentThemesSkip, intentThemesVariables);

  const keywordsSkip = !segmentId;
  const keywordsVariables: GetKeywordsTextQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    dateRange: selectedDateRange,
    filterField: {
      filters: defaultFilters,
    },
    page: 1,
    pageSize: 999999,
    negative: false,
  };

  const { getKeywords, keywordsLoading } = useGetKeywordsText(keywordsSkip, keywordsVariables);
  const deduplicatedKeywords = useMemo(() => {
    if (getKeywords?.edges) {
      return getKeywords.edges.reduce((acc: string[], { keyword_text: keywordText }) => {
        if (!acc.includes(keywordText)) {
          acc.push(keywordText);
        }
        return acc;
      }, []);
    }
    return [];
  }, [getKeywords]);

  const { getIntentThemeTree, intentThemeTreeLoading } = useGetIntentThemeTree(!segmentId, {
    platformAccountId: selectedPlatformAccountId,
    customerId: selectedSourceAccountId,
    dateRange: selectedDateRange,
    filterField: {
      filters,
    },
  });

  const handleClick = (event: any) => {
    event.stopPropagation();
    navigate(-1);
  };

  useEffect(() => {
    if (cachedIntentThemes === null && getIntentThemes?.edges?.length > 0) {
      setCachedIntentThemes(getIntentThemes);
    }
  }, [getIntentThemes]);

  const handleChange = (value: string) => {
    setCachedIntentThemes(null);
    setSelectedKeyword(value);
    setCurrentDepth(0);
    setPage(0);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'none !important',
        }}
      >
        <SelectorMenu />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          backgroundColor: '#fff',
          borderBottom: '1px solid rgba(0,0,0,.12)',
        }}
      >
        <Breadcrumbs
          importedState={importedState}
          headerStyle={{
            borderBottom: 'none',
          }}
        />
        <DateSelector />
      </Box>
      <Box margin="0px 24px">
        <Box flex="1" display="flex" gap="22px" padding="17px 0px" alignItems="center">
          <Button
            label="All Segments"
            variant="outlined"
            startIcon={<ArrowBack />}
            color="secondary"
            onClick={handleClick}
          />
          <Header>{segmentName || 'Intent Segment'}</Header>
        </Box>

        {/* start of content container */}
        <Box
          sx={{
            border: '1px solid #2121341e',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'column',
            background: '#fff',
            maxWidth: '100vw',
            minHeight: 'calc(100vh - 280px)',
            boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.1)',
            marginBottom: useMediaQuery(theme.breakpoints.down('lg')) ? '30vh' : '0px',
            minWidth: '900px',
          }}
        >
          <Box
            sx={{
              padding: '22px 16px',
              fontSize: '16px',
              fontWeight: 'bold',
              lineHeight: 1.5,
              letterSpacing: '0.15px',
              color: 'rgba(33, 33, 52, 0.87)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                width: '40%',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <Typography fontWeight={800} fontSize={18}>
                {intentThemesLoading || !selectedDateRange ? (
                  <CustomSkeleton variant="text" animation="wave" width={200} />
                ) : (
                  `${
                    getIntentThemes?.pageInfo?.total ? numberWithCommas(getIntentThemes?.pageInfo?.total) : 0
                  } Intent Themes`
                )}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: 'rgba(33, 33, 52, 0.6)',
                  }}
                >
                  Ranked by nValue{' '}
                  <Tooltip
                    title="nValue is a proprietary metric that evaluates the opportunity of every term across intent themes
                    and sizes them according to that opportunity."
                    placement="top"
                  >
                    <InfoIcon
                      sx={{
                        position: 'absolute',
                        right: '-25px',
                      }}
                    />
                  </Tooltip>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Typography fontSize={14}>Viewing Intent Themes of</Typography>
              {keywordsLoading || !getKeywords?.edges ? (
                <Box display="flex" alignItems="center" height="20px">
                  <CustomSkeleton variant="text" animation="wave" />
                </Box>
              ) : (
                <>
                  <Select
                    sx={{
                      height: '28px',
                      margin: '0px',
                      padding: '0px',
                      fontSize: '16px !important',
                      borderRadius: '6px',
                      border: 'solid 1px rgba(33, 33, 52, 0.23)',
                    }}
                    data-testid="dateSelector"
                    placeholder={!selectedDateRange ? 'Loading...' : undefined}
                    value={selectedKeyword || 'All Keywords'}
                    disabled={!selectedDateRange || !getKeywords?.edges?.length}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom', // Dropdown will be aligned to the bottom of the select
                        horizontal: 'left', // Dropdown will be aligned to the left of the select
                      },
                      transformOrigin: {
                        vertical: 'top', // Dropdown will be transformed from the top
                        horizontal: 'left', // Dropdown will be transformed from the left
                      },
                      PaperProps: {
                        style: {
                          maxHeight: '300px',
                        },
                      },
                    }}
                  >
                    {[{ keyword_text: 'All Keywords' }, ...(deduplicatedKeywords || [])].map((keyObj, index) => {
                      const key = typeof keyObj === 'string' ? keyObj : keyObj.keyword_text;
                      return (
                        <CustomMenuItem
                          key={key}
                          data-testid={`menuItem-${index}`}
                          onClick={() => handleChange(key)}
                          value={key}
                        >
                          {key}
                        </CustomMenuItem>
                      );
                    })}
                  </Select>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '7px',
                    }}
                  >
                    {selectedKeyword && selectedKeyword !== 'All Keywords' && (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          cursor: 'pointer',
                        }}
                        color="secondary"
                        onClick={() => setSelectedKeyword('All Keywords')}
                      >
                        Clear
                      </Typography>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Divider />

          <Box
            sx={{
              display: 'flex',
              flexDirection: useMediaQuery(theme.breakpoints.down('lg')) ? 'column' : 'row',
              justifyContent: 'space-between',
              maxHeight: 'calc(100vh - 250px)',
              position: 'relative',
            }}
          >
            {/* start of visual */}
            <Box
              width="100%"
              padding="25px"
              sx={{
                backgroundColor: '#fff',
                borderBottomLeftRadius: useMediaQuery(theme.breakpoints.down('lg')) ? '0px' : '6px',
              }}
            >
              {!getIntentThemeTree || intentThemeTreeLoading ? (
                <Box
                  sx={{
                    height: '100%',
                    minHeight: 'calc(100vh - 250px)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <ChartDetails>click to zoom in/out</ChartDetails>
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <ChartBubble>
                      {/* <BubbleChart data={intentThemesCache?.edges} /> */}
                      <ZoomableCirclePacking data={getIntentThemeTree} currentDepth={currentDepth} />
                    </ChartBubble>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          {/* end of visual */}
        </Box>
        {/* end of content container */}
      </Box>
    </Box>
  );
};

export default IntentThemes;
