'use client';

import React, { useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Typography, Box, Skeleton, CircularProgress } from '@mui/material';
import { ResponsiveContainer } from 'recharts';

import { useGetIntentsByTheme, useGetIntentThemes } from '~/core/services/graphql/intents/hooks';
import {
  GetIntentsByThemeQueryVariables,
  GetIntentThemesQueryVariables,
} from '~/core/services/graphql/intents/intents.queries.generated';
import { useContexts } from '~/store/context/useContext';
import { IntentsSortEnum, IntentThemesSchema, IntentThemesSortEnum, KeywordSchema } from '~/core/types/graphql.types';
import { Tooltip } from '~/core/components/shared';

import WordCloud from './WordCloud';
import ThemeInsights from './ThemeInsights';
import KeywordSelector from './KeywordSelector';
import { InfoIcon } from '../ContentGapAnalysis/Tables/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 1200,
  width: '100%',
  boxShadow: theme.shadows[3],
  marginBottom: 24,
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const ScrollArea = styled(Box)(({ theme }) => ({
  height: '620px',
  overflowY: 'auto',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.5),
}));

const EmptyState = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.5),
  height: '100%',
}));

const LoadingView = () => (
  <StyledCard>
    <StyledCardHeader title={<Skeleton variant="text" width={200} height={32} />} />
    <StyledCardContent>
      <Box display="grid" gridTemplateColumns="3fr 2fr" gap={4}>
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Box>
              <Skeleton variant="text" width={150} height={32} sx={{ mb: 1 }} />
              <Skeleton variant="text" width={100} height={20} />
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-start" alignItems="center" mb={2} gap={2}>
            <Box display="flex" alignItems="center">
              <Skeleton variant="rectangular" width={16} height={16} sx={{ mr: 1 }} />
              <Skeleton variant="text" width={60} height={20} />
            </Box>
            <Box display="flex" alignItems="center">
              <Skeleton variant="rectangular" width={16} height={16} sx={{ mr: 1 }} />
              <Skeleton variant="text" width={80} height={20} />
            </Box>
          </Box>
          <Box
            height="550px"
            width="100%"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
        <Box
          sx={{
            height: '100%',
          }}
        >
          <EmptyState>
            <Box>
              <Skeleton variant="text" width="80%" height={32} sx={{ mb: 2 }} />
              <Box display="flex" gap={2} mb={2}>
                <Box flex={1} bgcolor="grey.100" borderRadius={1} p={1.5}>
                  <Skeleton variant="text" width={80} height={16} />
                  <Skeleton variant="text" width={120} height={24} />
                </Box>
              </Box>
              <Skeleton variant="rectangular" width="100%" height={48} sx={{ mb: 2 }} />
              <Box mt={2} minWidth={400}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                  <Box key={item} bgcolor="grey.100" borderRadius={1} p={1} mb={1}>
                    <Skeleton variant="text" width="90%" height={20} />
                  </Box>
                ))}
              </Box>
            </Box>
          </EmptyState>
        </Box>
      </Box>
    </StyledCardContent>
  </StyledCard>
);

export const IntentThemeAnalysis = ({
  selectedKeyword,
  setSelectedKeyword,
  keywords,
}: {
  selectedKeyword: KeywordSchema;
  setSelectedKeyword: (keyword: KeywordSchema) => void;
  keywords: KeywordSchema[];
}) => {
  const [searchParams] = useSearchParams();
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange } = useContexts();
  const [selectedTheme, setSelectedTheme] = useState<IntentThemesSchema | null>(null);
  const [tabValue, setTabValue] = useState(0);

  const selectedSegmentId = searchParams.get('segmentId');

  const getIntentThemesSkip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange;
  const getIntentThemesVars: GetIntentThemesQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerId: selectedSourceAccountId,
    dateRange: selectedDateRange,
    page: 0,
    pageSize: 30,
    sortBy: {
      field: IntentThemesSortEnum.Impressions,
      desc: true,
    },
    filterField: {
      filters: [
        {
          fieldName: 'segment_id',
          operation: 'eq',
          value: selectedSegmentId,
        },
      ],
    },
  };

  if (selectedKeyword) {
    getIntentThemesVars.filterField = {
      filters: [
        {
          fieldName: 'segment_id',
          operation: 'eq',
          value: selectedSegmentId,
        },
        {
          fieldName: 'keyword_text',
          operation: 'eq',
          value: selectedKeyword.keyword_text,
        },
        {
          fieldName: 'keyword_match_type',
          operation: 'eq',
          value: selectedKeyword.keyword_match_type,
        },
      ],
    };
  }

  const { getIntentThemes, intentThemesLoading } = useGetIntentThemes(getIntentThemesSkip, getIntentThemesVars);

  const getIntentsSkip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange || !selectedTheme;

  const getIntentsVars: GetIntentsByThemeQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerId: selectedSourceAccountId,
    dateRange: selectedDateRange,
    theme: selectedTheme?.theme,
    sortBy: {
      field: IntentsSortEnum.Aligned,
      desc: false,
    },
    filterField: {
      filters: [
        {
          fieldName: 'segment_id',
          operation: 'eq',
          value: selectedSegmentId,
        },
      ],
    },
    page: 0,
    pageSize: 200,
  };
  if (selectedKeyword) {
    getIntentsVars.filterField = {
      filters: [
        {
          fieldName: 'segment_id',
          operation: 'eq',
          value: selectedSegmentId,
        },
        {
          fieldName: 'keyword_text',
          operation: 'eq',
          value: selectedKeyword.keyword_text,
        },
        {
          fieldName: 'keyword_match_type',
          operation: 'eq',
          value: selectedKeyword.keyword_match_type,
        },
      ],
    };
  }
  const { getIntentsByTheme, intentsByThemeLoading } = useGetIntentsByTheme(getIntentsSkip, getIntentsVars);

  const handleWordClick = useCallback(
    (theme: IntentThemesSchema | null) => {
      if (!theme) {
        setSelectedTheme(null);
        return;
      }
      const matchedTheme: IntentThemesSchema = getIntentThemes?.edges?.find((t) => t.theme === theme.theme);
      if (matchedTheme) {
        setSelectedTheme(matchedTheme);
      }
    },
    [getIntentThemes],
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);

  const isLoadingVisuals = intentThemesLoading || getIntentThemes?.edges?.length === 0;

  const handleKeywordSelect = (keyword: KeywordSchema) => {
    setSelectedKeyword(keyword);
    setSelectedTheme(null);
  };

  const handleClearKeyword = () => {
    setSelectedKeyword(null);
    setSelectedTheme(null);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // Check if the click target is part of the WordCloud container or theme insights
    const wordCloudContainer = document.querySelector('.word-cloud-container');
    const themeInsightsContainer = document.querySelector('.theme-insights-container');

    const isClickInWordCloud = wordCloudContainer?.contains(event.target as Node);
    const isClickInThemeInsights = themeInsightsContainer?.contains(event.target as Node);

    // Only clear selection if click is not in either container
    if (!isClickInWordCloud && !isClickInThemeInsights) {
      setSelectedTheme(null);
    }
  };

  if (isLoadingVisuals || !selectedDateRange) {
    return <LoadingView />;
  }

  return (
    <StyledCard>
      <StyledCardHeader
        title={
          <Typography variant="h6" fontWeight={600} fontSize="1.125rem" display="flex" alignItems="center">
            Segment Intent Themes
            <Tooltip
              title={
                'Segments are created from shared search intent, but nuances often exist within it. Themes act as higher-level aggregations, organizing related search intents across a segment.' +
                'This allows advertisers to understand and act on unique patterns, offering insights beyond what keywords alone can provide without becoming overwhelmed by individual search intent.'
              }
            >
              <InfoIcon />
            </Tooltip>
          </Typography>
        }
      />
      <StyledCardContent onClick={handleOutsideClick}>
        <Box display="grid" gridTemplateColumns="3fr 2fr" gap={1}>
          <Box>
            <Box display="flex" flexDirection="column">
              <Box>
                <Box display="flex" alignItems="center">
                  <Typography fontSize="1rem" fontWeight={500} variant="body2" color="rgb(33, 33, 52)" mr={1}>
                    Viewing Intent Themes of
                  </Typography>
                  <KeywordSelector
                    keywords={keywords}
                    selectedKeyword={selectedKeyword}
                    onSelect={handleKeywordSelect}
                    onClear={handleClearKeyword}
                  />
                </Box>
              </Box>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                variant="body2"
                mb={1}
                mt={1}
              >
                Word Size = Traffic Growth Opportunity{' '}
                <Tooltip title="We use a proprietary method that evaluates the opportunity of every search intent within each theme. This allows you to focus on areas with the highest potential for traffic growth.">
                  <InfoIcon />
                </Tooltip>
              </Typography>
            </Box>
            <Box
              className="word-cloud-container"
              height="550px"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ResponsiveContainer
                width="100%"
                height="100%"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <WordCloud words={getIntentThemes?.edges} onWordClick={handleWordClick} selectedTheme={selectedTheme} />
              </ResponsiveContainer>
            </Box>
          </Box>
          <Box>
            {selectedTheme ? (
              <ScrollArea className="theme-insights-container">
                <ThemeInsights
                  selectedTheme={selectedTheme}
                  tabValue={tabValue}
                  handleTabChange={handleTabChange}
                  intents={
                    getIntentsByTheme?.edges
                      ? getIntentsByTheme.edges
                          .filter((intent, index, self) => index === self.findIndex((i) => i.name === intent.name))
                          .sort((a, b) => a.name.localeCompare(b.name))
                      : []
                  }
                  isLoading={intentsByThemeLoading || intentThemesLoading}
                  setSelectedTheme={setSelectedTheme}
                />
              </ScrollArea>
            ) : (
              <EmptyState>
                <Typography color="textSecondary" fontWeight={400}>
                  Select a theme to view insights
                </Typography>
              </EmptyState>
            )}
          </Box>
        </Box>
      </StyledCardContent>
    </StyledCard>
  );
};

export default IntentThemeAnalysis;
