import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const BubbleChart = ({ data, preview = false }) => {
  const svgRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 60, height: 60 });

  useEffect(() => {
    const updateDimensions = () => {
      const isMacBookPro15 = window.matchMedia('(min-width: 1640px)').matches;
      setDimensions({
        width: isMacBookPro15 ? 180 : 180,
        height: isMacBookPro15 ? 180 : 180,
      });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    if (!data || !svgRef.current) return;

    const svg = d3.select(svgRef.current);
    const { width, height } = dimensions;

    if (preview) {
      svg.attr('width', width).attr('height', height);
    } else {
      svg.attr('viewBox', `0 0 ${width} ${height}`).attr('preserveAspectRatio', 'xMidYMid meet');
    }

    const bubble = d3.pack().size([width, height]).padding(1);

    const root = d3.hierarchy({ children: data }).sum((d) => d.impressions);

    const nodes = bubble(root).descendants().slice(1);

    const node = svg
      .selectAll('.node')
      .data(nodes)
      .join('g')
      .attr('class', 'node')
      .attr('transform', (d) => `translate(${d.x},${d.y})`);

    node
      .selectAll('circle')
      .data((d) => [d])
      .join('circle')
      .attr('r', (d) => d.r)
      .style('fill', '#3054AA');

    node
      .selectAll('text')
      .data((d) => [d])
      .join('text')
      .attr('dy', '.3em')
      .style('text-anchor', 'middle')
      .style('fill', 'white')
      .style('font-size', (d) => {
        const themeLength = d.data.theme?.length || 0;
        return `${Math.max(0, (d.r / themeLength) * 3.2)}px`;
      })
      .text((d) => d.data.theme);
  }, [data, dimensions, preview]);

  return (
    <svg
      ref={svgRef}
      style={{
        width: preview ? `${dimensions.width}px` : '100%',
        height: preview ? `${dimensions.height}px` : 'auto',
      }}
    />
  );
};

export default BubbleChart;
