import { PlatformAccountUsersSortableEnum } from '~/core/types/graphql.types';

export const headerCells = [
  {
    id: PlatformAccountUsersSortableEnum.Name,
    label: 'Name',
    numeric: false,
    disablePadding: true,
    sx: {
      '& .MuiTableSortLabel-root': {
        justifyContent: 'flex-end !important',
      },
    },
  },
  {
    id: PlatformAccountUsersSortableEnum.Email,
    label: 'Email Address',
    numeric: false,
    disablePadding: true,
    sx: {
      '& .MuiTableSortLabel-root': {
        justifyContent: 'flex-end !important',
      },
    },
  },
  // {
  //   id: PlatformAccountUsersSortableEnum.Role,
  //   label: 'Role',
  //   numeric: false,
  //   disablePadding: true,
  //   sx: {
  //     '& .MuiTableSortLabel-root': {
  //       justifyContent: 'flex-end !important',
  //     },
  //   },
  // },
  {
    id: PlatformAccountUsersSortableEnum.AccountStatus,
    label: 'User Status',
    numeric: false,
    disablePadding: true,
    sx: {
      '& .MuiTableSortLabel-root': {
        justifyContent: 'flex-end !important',
      },
    },
  },
  // {
  //   id: PlatformAccountUsersSortableEnum.LastSignOn,
  //   label: 'Last Sign On',
  //   numeric: false,
  //   disablePadding: true,
  //   sx: {
  //     '& .MuiTableSortLabel-root': {
  //       justifyContent: 'flex-end !important',
  //     },
  //   },
  // },
  // {
  //   id: 'actions',
  //   label: '',
  //   numeric: false,
  //   disablePadding: true,
  // },
];
