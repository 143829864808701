/* eslint react/jsx-no-literals: "off", no-nested-ternary: "off" */

import { Box, Table, TableBody, TableContainer } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TableHead } from '~/core/components/shared';
import { QueryGetTrafficSegmentsV2Args, TrafficSegmentSortEnum } from '~/core/types/graphql.types';
import { useGetTrafficSegmentsV2 } from '~/core/services/graphql/adGroups/hooks';
import { SortType } from '~/core/types/table.types';
import { useContexts } from '~/store/context/useContext';

import LoadingTable from '../LoadingTable';
import * as Styled from '../styles';
import { segmentsHeaderCells } from '../tableHeader.data';
import { CustomTablePagination } from '../../components';
import CollapsibleRow from './SegmentsTableRow';

type SegmentsTableProps = {
  searchValue: string;
  segmentsTotal: number;
  setSegmentsTotal: (total: number) => void;
};

const SegmentsTable = ({ searchValue, segmentsTotal, setSegmentsTotal }: SegmentsTableProps) => {
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedCampaignId, selectedDateRange } = useContexts();
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderBy, setOrderBy] = useState<TrafficSegmentSortEnum>(TrafficSegmentSortEnum.Auctions);
  const [desc, setDesc] = useState<boolean>(true);
  const [page, setPage] = useState<number>((searchParams.get('page') && Number(searchParams.get('adGroupsPage'))) || 0);
  const [pageSize, setPageSize] = useState<number>(
    (searchParams.get('pageSize') && Number(searchParams.get('pageSize'))) || 25,
  );

  const filters: any[] = [
    ...(selectedCampaignId ? [{ operation: 'eq', fieldName: 'campaign_id', value: selectedCampaignId }] : []),
    ...(searchValue ? [{ operation: 'like', fieldName: 'segment_name', value: searchValue }] : []),
  ];

  const trafficSegmentsV2Skip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange;
  const trafficSegmentsV2Variables: QueryGetTrafficSegmentsV2Args = {
    platformAccountId: selectedPlatformAccountId,
    customerId: selectedSourceAccountId,
    dateRange: selectedDateRange,
    page: page + 1,
    pageSize,
    sortBy: {
      field: orderBy,
      desc,
    },
    filterField: {
      filters,
    },
  };

  const { trafficSegmentsLoading, getTrafficSegments } = useGetTrafficSegmentsV2(
    trafficSegmentsV2Skip,
    trafficSegmentsV2Variables,
  );

  if (getTrafficSegments?.pageInfo?.total !== segmentsTotal && !trafficSegmentsLoading) {
    setSegmentsTotal(getTrafficSegments?.pageInfo?.total);
  }

  const handleRequestSort = (event: MouseEvent<unknown>, property: any) => {
    const isAsc = property === orderBy ? !desc : true;
    setDesc(isAsc);
    setOrderBy(property);
    searchParams.set('orderBy', property);
    searchParams.set('desc', String(!isAsc));
    setSearchParams(searchParams);
  };

  return trafficSegmentsLoading || !selectedDateRange ? (
    <>
      <LoadingTable headerCells={segmentsHeaderCells} />
    </>
  ) : (
    <>
      {getTrafficSegments?.edges?.length > 0 ? (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              headCells={segmentsHeaderCells}
              order={desc ? SortType.Desc : SortType.Asc}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {getTrafficSegments.edges.map((row, index) => (
                <CollapsibleRow key={row?.segmentId} row={row} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              headCells={segmentsHeaderCells}
              order={desc ? SortType.Desc : SortType.Asc}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              <Styled.TableCell
                sx={{
                  textAlign: 'center',
                  height: '150px',
                }}
                colSpan={7}
              >
                No Segments to Display
              </Styled.TableCell>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          borderTop: '1px solid #E5E5E5',
        }}
      >
        {getTrafficSegments?.pageInfo?.total > 0 && (
          <CustomTablePagination
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            pageTotal={getTrafficSegments?.pageInfo?.total}
          />
        )}
      </Box>
    </>
  );
};

export default SegmentsTable;
