import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
  styled,
} from '@mui/material';

import { KeywordTableProps } from '../../../../types';

const CustomTableCell = styled(TableCell)({
  fontSize: '0.875rem',
});

export const KeywordTable = ({ data }: KeywordTableProps) => (
  <TableContainer
    component={Paper}
    sx={{
      height: '100%',
      maxHeight: 400,
      overflow: 'auto',
    }}
  >
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <CustomTableCell>Keyword</CustomTableCell>
          <CustomTableCell align="right">Eligible Impr.</CustomTableCell>
          <CustomTableCell align="right">Missing Impr.</CustomTableCell>
          <CustomTableCell align="right">Gap</CustomTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((keyword) => (
          <TableRow
            key={keyword.name}
            // onClick={() => onSelect(keyword)}
            // onMouseEnter={() => onHover(keyword.name)}
            // onMouseLeave={() => onHover(null)}
            // sx={{
            //   cursor: 'pointer',
            //   '&:hover': { backgroundColor: 'action.hover' },
            //   backgroundColor:
            //     hoveredKeyword === keyword.name ||
            //     (selectedKeyword &&
            //       `${selectedKeyword.keyword_text} (${selectedKeyword.keyword_match_type})` === keyword.name)
            //       ? 'action.hover'
            //       : 'inherit',
            // }}
          >
            <TableCell component="th" scope="row">
              {/* <Box display="flex" alignItems="center" gap={1}>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    backgroundColor: CHART_COLORS[index % CHART_COLORS.length],
                  }}
                /> */}
              <Typography noWrap style={{ maxWidth: 200, fontSize: '0.875rem' }} title={keyword.name}>
                {keyword.name}
              </Typography>
              {/* </Box> */}
            </TableCell>
            <TableCell align="right">{keyword.eligibleImpressions?.toLocaleString()}</TableCell>
            <TableCell
              align="right"
              sx={{
                color: '#e76e50',
              }}
            >
              {keyword.missingImpressions?.toLocaleString()}
            </TableCell>
            <TableCell align="right">
              <Chip label={`${Math.round(keyword.gap)}%`} size="small" />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
