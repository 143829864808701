import { Info } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Input,
  Box as MuiBox,
  Select as MuiSelect,
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Typography,
  styled,
} from '@mui/material';

import { SearchField } from '~/core/components/shared';

import { theme } from '../../../../../../theme';

// Table Header Styles
export const InfoIcon = styled(Info)({
  color: '#9e9e9e',
  paddingLeft: '5px',
});

export const TableRow = styled(MuiTableRow)({
  '&:hover': {
    background: '#F6F6F7',
    cursor: 'pointer',
  },
  borderBottom: '1px solid #E5E5E5',
});

export const MainCell = styled(MuiTableCell)({
  paddingBottom: 0,
  paddingTop: 0,
  borderTop: '0px solid !important',
});

export const TableCell = styled(MuiTableCell)({
  color: 'rgb(33, 33, 52, 0.6)',
  fontSize: '12px',
  letterSpacing: '0.17px',
  padding: '6px 0',
  paddingLeft: '26px',
});

export const CollapsedHeader = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: 0.1px;
  color: rgba(33, 33, 52, 0.87);
  margin-top: 24px;
`;

export const CollapsedTableLabel = styled(TableCell)`
  height: 20px;
  flex-grow: 1;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.66;
  letter-spacing: 0.4px;
  color: rgba(33, 33, 52, 0.6);
`;

export const CollapsedTableCell = styled(TableCell)({
  height: '20px',
  width: '52%',
  flexGrow: '1',
  fontSize: '12px',
  lineHeight: 1.66,
  letterSpacing: '0.4px',
  color: 'rgba(33, 33, 52, 0.87)',
  border: 'none',
});

export const IconContainer = styled(IconButton)({
  color: 'rgba(74, 74, 106, 0.5)',
  margin: '0 10px',
  '&:hover': {
    background: 'none',
  },
});

export const NoAssetsText = styled(Typography)`
  width: 73px;
  height: 14px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: rgba(33, 33, 52, 0.38);
`;

export const TableHeader = styled(MuiBox)({
  background: '#F6F6F7',
  height: '43px',
  padding: '10px 35px',
  fontWeight: 'bold',
  lineHeight: 1.66,
  letterSpacing: '0.4px',
  color: 'rgba(33, 33, 52, 0.87)',
  borderBottom: '1px solid #E5E5E5',
});

export const Circle = styled(MuiBox)({
  height: '15px',
  width: '15px',
  borderRadius: '50%',
});

// CollapisbleRow styles

export const CollapsibleBox = styled(MuiBox)({
  margin: '8px 0',
  background: '#f6f6f7',
  padding: '8px 12px',
  marginLeft: '48px',
  borderRadius: '6px',
});

export const Table = styled(MuiTable)({
  background: '#f6f6f7',
  padding: '8px 12px !important',
  marginTop: '4px',
});

export const MainTableCell = styled(MuiTableCell)({
  paddingLeft: '35px',
  borderBottom: 'none',
});

export const DisplayFlexBox = styled(MuiBox)({
  display: 'flex',
  gap: '560px',
});

export const BoldBox = styled(MuiBox)({
  fontWeight: 'bold',
  paddingBottom: '4px',
  lineHeight: 1.66,
  letterSpacing: '0.4px',
});

export const InnerTable = styled(MuiTable)({
  border: '0.5px solid lightgrey',
  padding: '5px',
});

export const HeadCell = styled(MuiTableCell)({
  color: 'rgb(33, 33, 52, 0.6)',
  height: '29px',
  fontSize: '12px',
  lineHeight: 1.43,
  letterSpacing: '0.17px',
  width: '25%',
  paddingTop: '0px',
  paddingBottom: '0px',
});

export const DetailCell = styled(MuiTableCell)({
  background: '#ffffff',
  width: '25%',
  height: '32px',
  paddingTop: '0px',
  paddingBottom: '0px',
});

export const CollapisbleHeader = styled(MuiBox)({
  background: '#F6F6F7',
  height: '43px',
  padding: '10px 35px',
  fontWeight: 'bold',
  lineHeight: 1.66,
  letterSpacing: '0.4px',
  color: 'rgba(33, 33, 52, 0.87)',
  borderBottom: '1px solid #E5E5E5',
});

export const SearchContainer = styled(Box)`
  height: 54px;
  width: 100%;
  padding: 8px 0;
  padding-left: 32px;
  background-color: #f6f6f7;
  border-bottom: 1px solid #e5e5e5;
`;

export const SearchBar = styled(SearchField)({
  minWidth: '300px',
});

export const SearchInput = styled(Input)({
  width: '340px',
  height: '100%',
  flexGrow: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignSelf: 'center',
  alignItems: 'center',
  border: 'none',
  paddingLeft: '6px',
  fontSize: '12px',
  lineHeight: 1.67,
  letterSpacing: '0.4px',
  textDecoration: 'none !important',
  color: 'rgba(33, 33, 52, 0.6)',

  '&.MuiInput-root': {
    outline: 'none !important',
  },

  '&.MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '&.MuiInput-underline:after': {
    borderBottom: 'none',
  },
  '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: 'none',
  },
  '& input::placeholder': {
    color: 'rgba(33, 33, 52, 0.6)',
    opacity: 1,
  },
});

export const Select = styled(MuiSelect)({
  fontSize: '12px',
  lineHeight: 1.67,
  letterSpacing: '0.4px',
  minWidth: '220px',
  height: '38px',
  backgroundColor: '#ffffff',
  borderRadius: '6px',
  color: 'rgba(33, 33, 52, 0.6)',
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid rgba(33, 33, 52, 0.23)',
  },
});

export const DialogTitle = styled(Typography)`
  padding: 8px 12px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: ${theme.palette.text.primary};
`;

export const DialogHeader = styled(Typography)`
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: 0.17px;
  color: ${theme.palette.text.primary};
  margin-top: 13px;
  margin-bottom: 6px;
`;

export const DialogText = styled(Box)`
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.17px;
  color: ${theme.palette.text.primary};
  text-transform: none;
`;

export const Badge = styled(Box)({
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 6.5px',
  borderRadius: '64px',
  background: '#81c784',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: 1.67,
  letterSpacing: '0.14px',
});

export const SearchBarHeading = styled(Typography)`
  flex-grow: 1;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: 0.17px;
  color: rgb(33, 33, 52, 0.87);
`;
