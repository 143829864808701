import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { useGetTrafficSegmentsImpressionsTotal } from '~/core/services/graphql/adGroups/hooks';
import { useContexts } from '~/store/context/useContext';
import { GetTrafficSegmentsImpressionTotalsQueryVariables } from '~/core/services/graphql/adGroups/adGroups.queries.generated';
import { Tooltip } from '~/core/components/shared';

import { AdGroupsSummaryTable, SegmentsTable } from '../ContentGapAnalysis/Tables';
import RecommendationsVisual from './RecommendationsVisual';
import SearchContainerComponent from './SearchContainerComponent';
import PieChartSummary from './PieChartSummary';
import * as Styled from './styles';
import { InfoIcon } from '../ContentGapAnalysis/Tables/styles';

type SegmentationAnalysisProps = {
  demoMode: boolean;
};

export enum SegmentationTableType {
  Segments = 'segments',
  AdGroups = 'adGroups',
}

export const SegmentationAnalysis: React.FC<SegmentationAnalysisProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const cachedTableType = searchParams.get('tableType') as SegmentationTableType;
  const [inputValue, setInputValue] = useState('');
  const [segmentsTotal, setSegmentsTotal] = useState(0);
  // const [showSegmentsWithAnomalies, setShowSegmentsWithAnomalies] = useState(false);
  const [tableType, setTableType] = useState(cachedTableType || SegmentationTableType.Segments);
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedCampaignId, selectedDateRange } = useContexts();

  useEffect(() => {
    // Remove segmentId from searchParams only once
    if (searchParams.has('segmentId')) {
      searchParams.delete('segmentId');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTableType(event.target.checked ? SegmentationTableType.AdGroups : SegmentationTableType.Segments);
    const currentSearchParams = new URLSearchParams(searchParams);
    currentSearchParams.set(
      'tableType',
      event.target.checked ? SegmentationTableType.AdGroups : SegmentationTableType.Segments,
    );
    setSearchParams(currentSearchParams);
  };

  const getTrafficSegmentsImpressionsTotalSkip =
    !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange;
  const getTrafficSegmentsImpressionsTotalVariables: GetTrafficSegmentsImpressionTotalsQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerId: selectedSourceAccountId,
    dateRange: selectedDateRange,
  };

  if (selectedCampaignId) {
    getTrafficSegmentsImpressionsTotalVariables.filterField = {
      filters: [
        {
          fieldName: 'campaign_id',
          operation: 'eq',
          value: selectedCampaignId,
        },
      ],
    };
  }

  const { getTrafficSegmentsImpressionsTotal, trafficSegmentsImpressionsTotalLoading } =
    useGetTrafficSegmentsImpressionsTotal(
      getTrafficSegmentsImpressionsTotalSkip,
      getTrafficSegmentsImpressionsTotalVariables,
    );

  const chartData = useMemo(
    () => ({
      totalSegments: segmentsTotal || 0,
      totalImpressions: getTrafficSegmentsImpressionsTotal?.totalImpressions || 0,
      totalEligibleImpressions: getTrafficSegmentsImpressionsTotal?.totalEligibleImpressions || 0,
      missedImpressions: Math.max(
        0,
        (getTrafficSegmentsImpressionsTotal?.totalEligibleImpressions || 0) -
          (getTrafficSegmentsImpressionsTotal?.totalImpressions || 0),
      ),
    }),
    [getTrafficSegmentsImpressionsTotal, segmentsTotal],
  );

  return (
    <div
      style={{
        backgroundColor: '#fff',
        minWidth: '660px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px 0 10px 0',
          }}
        >
          <Typography
            sx={{
              fontSize: '1.25rem',
              lineHeight: 1.6,
              color: 'rgba(33, 33, 52, 0.87)',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            Traffic Gap Analysis
          </Typography>
          <Tooltip
            placement="right"
            title="Segments group incoming traffic by shared search intent. The Traffic Gap Analysis shows how effectively you target these segments by comparing their potential impressions with actual performance. This comparison helps you identify coverage gaps and opportunities for growth."
          >
            <InfoIcon />
          </Tooltip>
        </div>
        {tableType === SegmentationTableType.AdGroups && (
          <div
            style={{
              height: '350px',
              width: '50%',
              margin: 'auto',
              paddingBottom: '80px',
            }}
          >
            <Box
              sx={{
                height: '100%',
                width: '100%',
                paddingBottom: '10px',
              }}
            >
              <RecommendationsVisual />
            </Box>
          </div>
        )}
        {tableType === SegmentationTableType.Segments && (
          <PieChartSummary data={chartData} loading={trafficSegmentsImpressionsTotalLoading} />
        )}
      </div>
      <Styled.FlexContainer>
        <Styled.CenterContainer>
          <SearchContainerComponent
            inputValue={inputValue}
            setInputValue={setInputValue}
            tableType={tableType}
            handleSwitchChange={handleSwitchChange}
            // showSegmentsWithAnomalies={showSegmentsWithAnomalies}
            // setShowSegmentsWithAnomalies={setShowSegmentsWithAnomalies}
          />

          {tableType === SegmentationTableType.AdGroups && <AdGroupsSummaryTable searchValue={inputValue} />}

          {tableType === SegmentationTableType.Segments && (
            <SegmentsTable searchValue={inputValue} segmentsTotal={segmentsTotal} setSegmentsTotal={setSegmentsTotal} />
          )}
        </Styled.CenterContainer>
      </Styled.FlexContainer>
    </div>
  );
};

export default SegmentationAnalysis;
