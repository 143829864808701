export const CHART_COLORS = [
  '#6929C4',
  '#1192E8',
  '#005D5D',
  '#9F1853',
  '#FA4D56',
  '#570408',
  '#198038',
  '#002D9C',
  '#EE538B',
  '#B28600',
  '#009D9A',
  '#012749',
  '#8A3800',
  '#A56EFF',
  '#66aa00',
  '#b82e2e',
  '#316395',
  '#994499',
  '#22aa99',
  '#aaaa11',
  '#6633cc',
  '#e67300',
  '#8b0707',
  '#651067',
  '#329262',
  '#5574a6',
  '#3b3eac',
  '#b77322',
  '#16d620',
  '#b91383',
  '#f4359e',
  '#9c5935',
  '#a9c413',
  '#2a778d',
  '#668d1c',
  '#bea413',
];
