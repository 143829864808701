export enum AuthPaths {
  Auth = 'auth',
  SignIn = 'sign-in',
  CreateAccount = 'create-account',
  PasswordReset = 'password-reset',
  PasswordResetRequest = 'password-reset-request',
  ConfirmEmail = 'confirm-email',
  EmailNotVerified = 'confirm',
}

export enum HelperPaths {
  GoogleRedirect = '/google-redirect',
}

export enum DashboardPaths {
  MotiveMap = 'motive-map',
  Opportunities = 'content-gap-analysis',
  Segments = 'traffic-gap-analysis',
  PaidSearchIntegrations = 'paid-search-integrations',
  CampaignSummaryView = 'campaign',
  CampaignTopic = 'campaign/topic',
  IntentRecommendations = 'campaign/topic/intent-recommendations',
}

export enum PlatformPaths {
  Platform = 'platform',
  CreateNewPlatformAccount = 'create-new-platform-account',
  PlatformConsole = 'platform-admin-console',
}

export enum AdminSettingsPaths {
  Admin = 'admin',
  CompanySettings = 'company-settings',
  Customers = 'customers',
  ManageAgencies = 'manage-agencies',
  AdminSettings = 'settings',
}

export enum EngineeringPaths {
  Engineering = 'engineering',
}
