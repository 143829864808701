import { Box, TablePagination, TablePaginationBaseProps } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import React from 'react';

interface PaginationComponentProps extends TablePaginationBaseProps {
  page: number;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  pageTotal: number;
  disabled?: boolean;
  skeleton?: React.ReactNode;
}

interface DisplayedRowsProps {
  from: number;
  to: number;
  count: number;
  disabled?: boolean;
  skeleton?: React.ReactNode;
  fontSize?: string;
}

// Changed to use React.ReactElement return type explicitly
const DisplayedRows = ({
  from,
  to,
  count,
  disabled,
  skeleton,
  fontSize = '14px',
}: DisplayedRowsProps): React.ReactElement => {
  if (disabled && skeleton) {
    // Wrap skeleton in a fragment to ensure ReactElement return type
    return <>{skeleton}</>;
  }

  return (
    <span
      style={{
        fontSize,
        color: disabled ? '#c4c4c4' : '#212134',
      }}
    >
      {`${from}-${to} of ${count?.toLocaleString()}`}
    </span>
  );
};

interface RowsPerPageLabelProps {
  disabled?: boolean;
  fontSize?: string;
}

const RowsPerPageLabel = ({ disabled, fontSize = '14px' }: RowsPerPageLabelProps): React.ReactElement => (
  <span
    style={{
      fontSize,
      color: disabled ? '#c4c4c4' : '#212134',
    }}
  >
    Rows per page:
  </span>
);

const getDisplayedRowsComponent = (
  { from, to, count }: { from: number; to: number; count: number },
  disabled?: boolean,
  skeleton?: React.ReactNode,
  fontSize?: string,
): React.ReactElement => (
  <DisplayedRows from={from} to={to} count={count} disabled={disabled} skeleton={skeleton} fontSize={fontSize} />
);

const CustomTablePagination: React.FC<PaginationComponentProps> = ({
  page,
  pageSize,
  setPage,
  setPageSize,
  pageTotal,
  disabled,
  skeleton,
  ...props
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    setSearchParams(searchParams);
  };

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    searchParams.set('pageSize', String(event.target.value));
    setSearchParams(searchParams);
  };

  return (
    <Box
      sx={{
        '.MuiTableCell-root': {
          display: 'flex',
          justifyContent: 'flex-end',
          minHeight: '48px',
        },
      }}
    >
      <TablePagination
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
        disabled={disabled}
        count={pageTotal}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangePageSize}
        rowsPerPageOptions={[25, 50, 100]}
        labelDisplayedRows={(paginationInfo) =>
          getDisplayedRowsComponent(paginationInfo, disabled, skeleton, String(props?.style?.fontSize))
        }
        labelRowsPerPage={<RowsPerPageLabel disabled={disabled} fontSize={String(props?.style?.fontSize)} />}
        {...props}
      />
    </Box>
  );
};

export default CustomTablePagination;
