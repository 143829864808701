import { styled, Box as MuiBox, Card as MuiCard, CardContent as MuiCardContent } from '@mui/material';

export const FlexContainer = styled(MuiBox)({
  display: 'flex',
  background: '#fff',
});

export const CenterContainer = styled(MuiBox)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const LoadingContainer = styled(MuiBox)({
  width: '100%',
  height: '350px',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ChartCard = styled(MuiCard)({
  width: '100%',
  maxWidth: '768px', // max-w-3xl
  backgroundColor: 'transparent',
  boxShadow: 'none',
  border: 'none',
});

export const ChartContent = styled(MuiCardContent)({
  padding: '24px', // p-6
});

export const ChartContainer = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px',

  // Responsive design for smaller screens
  '@media (max-width: 840px)': {
    flexDirection: 'column',
  },
});

export const ChartTitle = styled('h3')({
  fontSize: '0.875rem',
  fontWeight: '600',
  color: '#6b7280',
  marginBottom: '4px',
  textAlign: 'center',
});

export const PieChartWrapper = styled(MuiBox)({
  height: '300px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const NoDataContainer = styled(MuiBox)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 400,
});

export const NoDataText = styled('p')({
  margin: 0,
  fontSize: '1rem',
  color: '#6b7280',
});

export const StatsCard = styled(MuiCard)({
  width: '100%',
  // Adjusted for smaller screens
  backgroundColor: 'white',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  border: '1px solid #e5e7eb',

  '@media (min-width: 840px)': {
    maxWidth: '220px',
  },
});

export const StatsContent = styled(MuiCardContent)({
  padding: '12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  // Added space between sections
  '& > div': {
    marginBottom: '12px',
  },
  '&:last-child': {
    paddingBottom: '0',
  },
});

export const StatsSection = styled(MuiBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  // Responsive design for smaller screens
  '@media (max-width: 840px)': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '8px',
  },
});

export const StatLabel = styled('p')({
  margin: 0,
  fontSize: '0.875rem',
  fontWeight: '500',
  color: '#6b7280',
});

export const StatValue = styled('p')({
  margin: 0,
  fontSize: '1.125rem',
  fontWeight: '700',
});

export const StatsGrid = styled(MuiBox)({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '8px',
});

export const ActualValue = styled(StatValue)({
  fontSize: '1rem',
  fontWeight: '600',
  color: '#324ba4',
});

export const MissedValue = styled(StatValue)({
  fontSize: '1rem',
  fontWeight: '600',
  color: '#e76e50',
});
