import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GetCampaignsQueryVariables } from '~/core/services/graphql/campaigns/campaigns.queries.generated';
import { useGetCampaigns } from '~/core/services/graphql/campaigns/hooks';
import { useGetIntentsAvailability } from '~/core/services/graphql/intents/hooks';
import { GetIntentsAvailabilityQueryVariables } from '~/core/services/graphql/intents/intents.queries.generated';
import { useContexts } from '~/store/context/useContext';

import * as Styled from './styles';

type CampaignSelectorProps = {
  demoMode?: boolean;
};

const CampaignSelector: React.FC<CampaignSelectorProps> = ({ demoMode }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    selectedPlatformAccountId,
    selectedSourceAccountId,
    selectedCampaignId,
    selectedDateRange,
    setSelectedCampaignId,
  } = useContexts();

  const handleCampaignChange = (value: string) => {
    if (demoMode) return;
    const updatedSearchParams = new URLSearchParams();

    searchParams.delete('campaignId');

    if (value) {
      updatedSearchParams.set('campaignId', value);
    }

    searchParams.forEach((value, key) => {
      updatedSearchParams.set(key, value);
    });

    setSearchParams(updatedSearchParams, { replace: true });
    setSelectedCampaignId(value);
  };

  useEffect(() => {
    if (!selectedCampaignId) setSelectedCampaignId(searchParams.get('campaignId') || null);
  }, []);

  const useGetCampaignsSkip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange;
  const useGetCampaignsVariables: GetCampaignsQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    dateRange: selectedDateRange,
  };

  const { campaignsLoading, campaignsError, getCampaigns } = useGetCampaigns(
    useGetCampaignsSkip,
    useGetCampaignsVariables,
  );

  const hasCampaigns = getCampaigns?.length > 0;

  const campaignIds = getCampaigns?.map(({ id }) => id);

  const getIntentsAvailabilitySkip = !selectedPlatformAccountId || !selectedSourceAccountId || !campaignIds?.length;
  const getIntentsAvailabilityVariables: GetIntentsAvailabilityQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    campaignIds,
  };
  const { getIntentsAvailability } = useGetIntentsAvailability(
    getIntentsAvailabilitySkip,
    getIntentsAvailabilityVariables,
  );

  const hasData = (id: string) => getIntentsAvailability?.some((data) => data.campaignId === id);
  const currentRoute = window.location.pathname;

  return (
    <>
      <Styled.TextContainer
        sx={{
          color:
            getCampaigns?.length < 1 || campaignsLoading || !!campaignsError || !getCampaigns?.length
              ? '#D9D9DC'
              : 'rgba(33, 33, 52, 0.87)',
        }}
      >
        View {currentRoute.includes('/traffic-gap-analysis') ? 'Traffic Gap' : 'Content Gap'} in{' '}
      </Styled.TextContainer>
      <Styled.Select
        MenuProps={{
          sx: {
            maxHeight: '500px',
            marginLeft: '50px',
            marginTop: '5px',
          },
        }}
        value={selectedCampaignId || 'all'}
        disabled={!hasCampaigns || campaignsLoading || !!campaignsError}
      >
        <Styled.CustomMenuItem
          sx={{
            fontWeight: !selectedCampaignId ? 'bold' : 'normal',
          }}
          value="all"
          onClick={() => handleCampaignChange(null)}
        >
          All Campaigns
        </Styled.CustomMenuItem>
        {getCampaigns?.length > 0 &&
          getCampaigns
            ?.slice()
            ?.sort((a, b) => {
              // check if getIntentsAvailability has id
              if (!hasData(a?.id) && hasData(b?.id)) {
                return 1;
              }
              if (hasData(a?.id) && !hasData(b?.id)) {
                return -1;
              }
              return a?.name.localeCompare(b?.name);
            })
            ?.map(({ id, name }) => (
              <Styled.CustomMenuItem
                disabled={!hasData(id)}
                sx={{
                  fontWeight: id === selectedCampaignId ? 'bold' : 'normal',
                }}
                key={id}
                value={id}
                onClick={() => handleCampaignChange(id)}
              >
                {name} {hasData(id) ? '' : <Styled.CustomChip size="small" label="No Data" />}
              </Styled.CustomMenuItem>
            ))}
      </Styled.Select>
    </>
  );
};

export default CampaignSelector;
