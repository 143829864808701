export const successNotificationMessage = {
  successSignUp: 'Account Successfully Created',
  successSentConfirmationCode: 'Confirmation Code Sent',
  successChangePassword: 'Password Successfully Updated',
  successLinkAccount: 'Account Successfully Linked',
  successVerifyEmail: 'Email Updated Successfully',
  successRefreshAccounts: 'Accounts Successfully Refreshed',
  successDeployContent: 'Success, deploying content into your account',
};

export const infoNotificationMessages = {
  infoLinkedAccount: 'The account you selected has already been added',
};

export const errorNotificationMessages = {
  errorRefreshAccounts: 'Accounts Were Not Successfully Loaded. Try Refreshing the Page.',
  errorLinkingNewAccount:
    'There was an error linking your account. Please try again or contact support if the issue persists.',
  errorDeployContent: 'There was a problem when deploying content into your account',
};

export enum SuccessUserUpdate {
  Name = 'Name Updated Successfully',
  Password = 'Password Updated Successfully',
}
