import { Box, styled } from '@mui/material';

import { PieChart } from '~/core/components/shared/PieChart';
import { numberWithCommas } from '~/core/helpers';

const CustomCard = styled(Box)`
  height: 200px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 12px;
  border-radius: 6px;
  border: solid 1px rgba(33, 33, 52, 0.12);
  background-color: #fff;
  margin-top: 10px;
`;

const ChartContainer = styled(Box)({
  margin: '0 auto',
  height: '180px',
});

const TextContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '0 auto',
  textAlign: 'center',
  gap: 4,
});

const ChartDetails = styled('p')`
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.17px;
  text-align: center;
  color: rgba(33, 33, 52, 0.87);
  margin: 0;
  padding: 0;
`;

const ImpressionsTotal = styled('p')`
  align-self: stretch;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: 0.1px;
  text-align: center;
  color: rgba(33, 33, 52, 0.87);
  margin: 0;
`;

const ChartKeyBox = styled(Box)`
  width: 13px;
  height: 13px;
  flex-grow: 0;
  border-radius: 2px;
  background-color: #4a57bc;
  margin-right: 4px;
  margin-top: 3px;
`;

const ChartKeyText = styled('p')`
  display: flex;
  font-size: 12px;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.4px;
  color: rgba(33, 33, 52, 0.87);
  width: 100%;
  margin: 0;
  padding: 0;
`;

type PercentageDisplayProps = {
  totalImpressions: number;
  segmentImpressions: number;
};

const PercentageDisplay: React.FC<PercentageDisplayProps> = ({ totalImpressions, segmentImpressions }) => {
  const segmentImpressionPercentage = (segmentImpressions / totalImpressions) * 100;
  return (
    <CustomCard
      sx={{
        position: 'relative',
      }}
    >
      <TextContainer>
        <ImpressionsTotal>Impressions</ImpressionsTotal>
        <ChartDetails>{segmentImpressionPercentage?.toFixed(2)}% of Ad Group Impressions</ChartDetails>
      </TextContainer>
      <ChartContainer>
        <PieChart coveredPercent={Math.round(segmentImpressionPercentage)} />

        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            bottom: '8px',
            width: '100%',
            left: '60px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '3px',
              width: '40%',
            }}
          >
            <ChartKeyBox />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ChartKeyText>This Segment </ChartKeyText>
              <ChartKeyText
                style={{
                  fontWeight: 'bold',
                }}
              >
                {numberWithCommas(segmentImpressions)}
              </ChartKeyText>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '40%',
            }}
          >
            <ChartKeyBox
              sx={{
                border: 'solid 1px rgba(0, 0, 0, 0.18)',
                backgroundColor: 'rgba(51, 76, 140, 0.12)   ',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ChartKeyText>Other Segments </ChartKeyText>
              <ChartKeyText
                style={{
                  fontWeight: 'bold',
                }}
              >
                {numberWithCommas(totalImpressions - segmentImpressions)}
              </ChartKeyText>
            </Box>
          </Box>
        </Box>
      </ChartContainer>
    </CustomCard>
  );
};

export default PercentageDisplay;
