import { Info } from '@mui/icons-material';
import { Box, Typography, styled } from '@mui/material';

export const Container = styled(Box)({
  height: '350px',
  position: 'relative',
});

export const Heading = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '16px',
  fontSize: '1.25rem',
  lineHeight: 1.6,
  color: 'rgba(33, 33, 52, 0.87)',
  fontWeight: 700,
  letterSpacing: '0.1px',
  width: '300px',
  position: 'relative',
  margin: '20px 0 10px 0',
});

export const InfoIcon = styled(Info)({
  color: '#9e9e9e',
  paddingLeft: '5px',
});

export const Circle = styled(Box)({
  height: '15px',
  width: '15px',
  borderRadius: '50%',
});

export const ChartKeyMainContainer = styled(Box)({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  height: '300px',
  width: '220px',
});

export const ChartKeyContainer = styled(Box)({
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
  marginTop: '2px',
});

export const ChartKeyText = styled(Typography)({
  fontSize: '12px',
  lineHeight: 1.66,
  letterSpacing: '0.4px',
  // cursor: 'pointer',

  // '&:hover': {
  //   textDecoration: 'underline',
  // },
});

export const ChartContainer = styled(Box)({
  height: '300px',
});
