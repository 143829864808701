import { Divider } from '@mui/material';
import { Pie, PieChart, Tooltip, Cell } from 'recharts';

import { CircularProgress } from '~/core/components/shared';

import * as Styled from './styles';

// Define the TooltipContent component outside of PieChartSummary
type TooltipContentProps = {
  payload: any;
  totalEligibleImpressions: number;
};
const TooltipContent = ({ payload, totalEligibleImpressions }: TooltipContentProps) => {
  if (payload && payload.length > 0) {
    const value = payload[0]?.value;
    const color = payload[0].name === 'Actual' ? '#324ba4' : '#e76e50';
    return (
      <div
        style={{
          backgroundColor: 'white',
          padding: '12px',
          borderRadius: '8px',
          boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e5e7eb',
        }}
      >
        <p style={{ margin: 0, fontWeight: '600', color: '#1f2937' }}>{payload[0].name}</p>
        <p style={{ margin: 0, fontSize: '1.125rem', color }}>{value.toLocaleString()} Impr.</p>
        <p style={{ margin: 0, fontSize: '0.875rem', color: '#4b5563' }}>
          of {totalEligibleImpressions.toLocaleString()} total
        </p>
      </div>
    );
  }
  return null;
};

const chartColors = ['#324ba4', '#e76e50'];

type PieChartSummaryProps = {
  data: {
    totalSegments: number;
    totalImpressions: number;
    totalEligibleImpressions: number;
  };
  loading: boolean;
};

const PieChartSummary = ({ data, loading }: PieChartSummaryProps) => {
  const totalSegments = data?.totalSegments || 0;
  const totalEligibleImpressions = Math.round(data?.totalEligibleImpressions) || 0;
  const actualImpressions = Math.round(data?.totalImpressions) || 0;
  const missedImpressions = Math.max(0, totalEligibleImpressions - actualImpressions);

  const chartData = [
    { name: 'Actual', value: actualImpressions },
    { name: 'Missed', value: missedImpressions },
  ];

  if (loading) {
    return (
      <Styled.LoadingContainer>
        <CircularProgress />
      </Styled.LoadingContainer>
    );
  }

  return (
    <Styled.ChartCard>
      <Styled.ChartContent>
        <Styled.ChartContainer>
          <div>
            <Styled.PieChartWrapper>
              {actualImpressions > 0 && (
                <PieChart width={415} height={300}>
                  <Pie
                    data={chartData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  >
                    {chartData.map((entry, index) => (
                      <Cell
                        key={`cell-${entry.name}`}
                        fill={chartColors[index]}
                        style={{ transition: 'opacity 300ms', opacity: 1, outline: 'none' }}
                        onMouseEnter={(e) => {
                          const target = e?.currentTarget;
                          if (target) {
                            target.style.opacity = '0.8';
                          }
                        }}
                        onMouseLeave={(e) => {
                          const target = e?.currentTarget;
                          if (target) {
                            target.style.opacity = '1';
                          }
                        }}
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    content={<TooltipContent payload={chartData} totalEligibleImpressions={totalEligibleImpressions} />}
                  />
                </PieChart>
              )}

              {actualImpressions === 0 && (
                <Styled.NoDataContainer>
                  <Styled.NoDataText>No Data Available</Styled.NoDataText>
                </Styled.NoDataContainer>
              )}
            </Styled.PieChartWrapper>
          </div>
          <Styled.StatsCard>
            <Styled.StatsContent>
              <Styled.StatsSection>
                <div>
                  <Styled.StatLabel>Total Segments</Styled.StatLabel>
                  <Styled.StatValue>{totalSegments.toLocaleString()}</Styled.StatValue>
                </div>
                <div>
                  <Styled.StatLabel>Total Eligible Impr.</Styled.StatLabel>
                  <Styled.StatValue>{totalEligibleImpressions.toLocaleString()}</Styled.StatValue>
                </div>
              </Styled.StatsSection>
              <Divider />
              <Styled.StatsGrid>
                <div>
                  <Styled.StatLabel>Actual Impr.</Styled.StatLabel>
                  <Styled.ActualValue>{actualImpressions.toLocaleString()}</Styled.ActualValue>
                </div>
                <div>
                  <Styled.StatLabel>Missed Impr.</Styled.StatLabel>
                  <Styled.MissedValue>{missedImpressions.toLocaleString()}</Styled.MissedValue>
                </div>
              </Styled.StatsGrid>
            </Styled.StatsContent>
          </Styled.StatsCard>
        </Styled.ChartContainer>
      </Styled.ChartContent>
    </Styled.ChartCard>
  );
};

export default PieChartSummary;
