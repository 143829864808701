/* eslint-disable no-nested-ternary */

import { Close } from '@mui/icons-material';
import {
  Box,
  DialogContent,
  DialogProps,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Skeleton,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { Tab, Tabs } from '~/core/components/shared';
import { GetAssetsQueryVariables } from '~/core/services/graphql/assets/assets.queries.generated';
import { useGetAssets } from '~/core/services/graphql/assets/hooks';
import { useGetKeywords } from '~/core/services/graphql/keywords/hooks';
import { GetKeywordsQueryVariables } from '~/core/services/graphql/keywords/keywords.queries.generated';
import { KeywordSchema } from '~/core/types/graphql.types';
import { useContexts } from '~/store/context/useContext';

import * as Styled from '../styles';

type ScrollDialogProps = {
  open: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  adGroupId: string;
  adGroupName: string;
  closestKeywordText: string;
  closetKeywordMatchType: string;
};

const AdGroupDialog = ({
  open,
  setDialogOpen,
  adGroupId,
  adGroupName,
  closestKeywordText,
  closetKeywordMatchType,
}: ScrollDialogProps) => {
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange } = useContexts();
  const [scroll] = useState<DialogProps['scroll']>('paper');
  const [activeTab, setActiveTab] = useState<string>('keywords');
  const [adId, setAdId] = useState<string>('all');
  const [adIds, setAdIds] = useState<string[]>([]);

  const getAssetsSkip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange;
  const getAssetsVariables: GetAssetsQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    adGroupIds: [adGroupId],
    dateRange: selectedDateRange,
    adStatus: 'ENABLED',
  };
  const { assetsLoading, getAssets } = useGetAssets(getAssetsSkip, getAssetsVariables);

  const uniqueAssets = Array.from(new Set(getAssets?.map((asset) => asset))) || [];
  const headlines =
    uniqueAssets?.length > 0 &&
    uniqueAssets
      .filter((asset) => {
        if (adId === 'all') return asset?.enabled && asset.fieldType === 'HEADLINE';
        return asset?.enabled && asset.fieldType === 'HEADLINE' && asset.adId === adId;
      })
      ?.sort((a, b) => (a.text > b.text ? 1 : -1));

  const descriptions =
    uniqueAssets?.length > 0 &&
    uniqueAssets
      .filter((asset) => {
        if (adId === 'all') return asset?.enabled && asset.fieldType === 'DESCRIPTION';
        return asset?.enabled && asset.fieldType === 'DESCRIPTION' && asset.adId === adId;
      })
      ?.sort((a, b) => (a.text > b.text ? 1 : -1));

  if (adId === 'all' && adIds.length === 0 && uniqueAssets) {
    const calcIds = uniqueAssets?.map((asset) => asset.adId);
    // remove duplicates
    const uniqueIds = Array.from(new Set(calcIds));
    if (uniqueIds.length > 0 && adIds?.length === 0) setAdIds(uniqueIds);
  }

  const getKeywordsSkip = !selectedPlatformAccountId || !selectedSourceAccountId || !adGroupId || !selectedDateRange;
  const getKeywordsVariables: GetKeywordsQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    adGroupIds: [adGroupId],
    dateRange: selectedDateRange,
    negative: false,
  };

  const { keywordsLoading, getKeywords } = useGetKeywords(getKeywordsSkip, getKeywordsVariables);

  const handleClose = () => {
    setDialogOpen(false);
    setActiveTab('keywords');
    setAdId('all');
    setAdIds([]);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const handleAdFilter = (id: string) => {
    setAdId(id);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, []);

  // sort keywords

  const sortedKws = getKeywords?.edges
    ?.slice()
    .sort((a, b) => a?.keyword_match_type.localeCompare(b?.keyword_match_type))

    // keep order but only return matching keyword with matching type to top of list
    ?.sort((a, b) => {
      if (a?.keyword_text === closestKeywordText && a?.keyword_match_type === closetKeywordMatchType) return -1;
      if (b.keyword_text === closestKeywordText) {
        return 1; // Put the matching item first
      }
      return a?.keyword_text.localeCompare(b?.keyword_text);
    });

  const deduplicateKws = (keywords: KeywordSchema[]) => {
    type KeywordProps = {
      keyword_text: string;
      keyword_match_type: string[];
    };
    const updatedData: KeywordProps[] = [];

    keywords?.forEach((keyword) => {
      const keywordText = keyword.keyword_text;
      const matchType = keyword.keyword_match_type;

      const existingKeyword = updatedData.find((keyword) => keyword.keyword_text === keywordText);

      if (existingKeyword) {
        existingKeyword.keyword_match_type.push(matchType);
      } else {
        updatedData.push({
          keyword_text: keywordText,
          keyword_match_type: [matchType],
        });
      }
    });
    return updatedData;
  };

  const deduplicatedKws = deduplicateKws(sortedKws);

  return (
    <Drawer
      sx={{
        height: '100vh',
        maxHeight: '100vh',
        width: '50vw',
        maxWidth: 'none', // Override maxWidth to ensure half width is respected
        position: 'absolute',
      }}
      PaperProps={{
        style: { width: '50vw' },
      }}
      anchor="right"
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Styled.DialogTitle id="scroll-dialog-title">Ad Group: {adGroupName}</Styled.DialogTitle>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <Tabs
        TabIndicatorProps={{
          style: { backgroundColor: '#3154aa' },
        }}
        onChange={(_, newValue) => handleTabChange(_, newValue)}
        value={activeTab}
      >
        <Tab
          value="keywords"
          label={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              Current Keywords
            </Box>
          }
        />
        <Tab
          value="assets"
          label={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              Current Assets
            </Box>
          }
        />
      </Tabs>

      {activeTab === 'assets' && (
        <DialogContent dividers={scroll === 'paper'}>
          <Box
            sx={{
              display: 'flex',
              height: '38px',
              alignItems: 'center',
            }}
          >
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="Filter By RSA">Filter By RSA</InputLabel>
              <Styled.Select
                labelId="Filter By RSA"
                label="Filter By RSA 12"
                size="small"
                value={adId}
                // displayEmpty
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginTop: 4,
                      borderRadius: 8,
                      color: 'rgba(33, 33, 52, 0.6)',
                    },
                  },
                }}
              >
                <MenuItem
                  sx={{ fontSize: '12px', color: 'rgba(33, 33, 52, 0.6)' }}
                  value="all"
                  onClick={() => handleAdFilter('all')}
                >
                  All
                </MenuItem>
                {adIds?.map((adId) => (
                  <MenuItem
                    key={adId}
                    sx={{ fontSize: '12px', color: 'rgba(33, 33, 52, 0.6)' }}
                    value={adId}
                    onClick={() => handleAdFilter(adId)}
                  >
                    RSA (ID): {adId}
                  </MenuItem>
                ))}
              </Styled.Select>
              {/* <FormHelperText>Without label</FormHelperText> */}
            </FormControl>
          </Box>
          <Styled.DialogHeader>Headlines</Styled.DialogHeader>

          {assetsLoading ? (
            <Styled.DialogText>
              <Skeleton variant="text" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" />
            </Styled.DialogText>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '6px',
              }}
            >
              {Array.from(new Set(headlines?.map((headline) => headline.text))).map((text) => (
                <Styled.DialogText key={text} ref={descriptionElementRef} tabIndex={-1}>
                  {text}
                </Styled.DialogText>
              ))}
            </Box>
          )}

          <Styled.DialogHeader>Descriptions</Styled.DialogHeader>
          {assetsLoading ? (
            <Styled.DialogText>
              <Skeleton variant="text" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="text" />
            </Styled.DialogText>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '6px',
              }}
            >
              {Array.from(new Set(descriptions?.map((description) => description.text))).map((text) => (
                <Styled.DialogText key={text} ref={descriptionElementRef} tabIndex={-1}>
                  {text}
                </Styled.DialogText>
              ))}
            </Box>
          )}
        </DialogContent>
      )}

      {activeTab === 'keywords' && (
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '6px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '6px',
                  alignItems: 'center',
                }}
              >
                <Styled.DialogText
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  Best Aligned Keyword
                </Styled.DialogText>
                <Box
                  sx={{
                    height: '14px',
                    width: '14px',
                    backgroundColor: '#81c784',
                    borderRadius: '50%',
                  }}
                />
              </Box>
            </Box>
            {keywordsLoading ? (
              <>
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </>
            ) : (
              deduplicatedKws?.map((keyword) => (
                <Styled.DialogText
                  key={keyword.keyword_text}
                  sx={{
                    display: 'flex',
                    gap: '6px',
                    alignItems: 'center',
                  }}
                >
                  {keyword?.keyword_text}{' '}
                  {keyword?.keyword_match_type
                    .sort((a: string, b: string) => a.localeCompare(b))
                    .map((matchType: string) => (
                      <Styled.Badge
                        key={matchType}
                        sx={{
                          backgroundColor:
                            matchType === closetKeywordMatchType && keyword?.keyword_text === closestKeywordText
                              ? 'default'
                              : matchType === 'EXACT'
                              ? '#f0f0f0'
                              : matchType === 'BROAD'
                              ? '#f0f0f0'
                              : matchType === 'PHRASE'
                              ? '#f0f0f0'
                              : '#f0f0f0',
                          color:
                            matchType === closetKeywordMatchType && keyword?.keyword_text === closestKeywordText
                              ? 'black'
                              : matchType === 'EXACT'
                              ? '#3154aa'
                              : matchType === 'BROAD'
                              ? '#3154aa'
                              : matchType === 'PHRASE'
                              ? '#3154aa'
                              : '#3154aa',
                        }}
                      >
                        {matchType}
                      </Styled.Badge>
                    ))}
                </Styled.DialogText>
              ))
            )}
          </Box>
        </DialogContent>
      )}
    </Drawer>
  );
};

export default AdGroupDialog;
