import { gql } from '@apollo/client';

export const GET_ASSESSMENT_AVAILABILITY = gql`
  query getAssessmentAvailability($platformAccountId: Int!) {
    getAssessmentAvailability(platformAccountId: $platformAccountId) {
      customerId
      hasAvailableData
    }
  }
`;

export const GET_ASSESSMENT_STATUSES = gql`
  query getAssessmentStatuses($platformAccountId: Int!, $customerId: String!) {
    getAssessmentStatuses(platformAccountId: $platformAccountId, customerId: $customerId) {
      customerId
      latestAvailable
      assessmentJobs {
        customerId
        dateRange
        status
        hasData
      }
    }
  }
`;
