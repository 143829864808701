/* eslint react/jsx-no-literals: "off", no-nested-ternary: "off" */

import { Box, Table, TableBody, TableContainer } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TableHead } from '~/core/components/shared';
import { AdGroupSummarySortEnum, QueryGetAdGroupSummariesArgs } from '~/core/types/graphql.types';
import { useGetAdGroupSummaries } from '~/core/services/graphql/adGroups/hooks';
import { SortType } from '~/core/types/table.types';
import { useContexts } from '~/store/context/useContext';

import * as Styled from '../styles';
import { adGroupHeaderCells } from '../tableHeader.data';
import { CustomTablePagination } from '../../components';
import CollapsibleRow from './CollapisbleRow';
import LoadingTable from '../LoadingTable';

type AdGroupsSummaryTableProps = {
  searchValue: string;
};

const AdGroupsSummaryTable = ({ searchValue }: AdGroupsSummaryTableProps) => {
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedCampaignId, selectedDateRange } = useContexts();
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderBy, setOrderBy] = useState<AdGroupSummarySortEnum>(AdGroupSummarySortEnum.KeywordsWithTraffic);
  const [desc, setDesc] = useState<boolean>(true);
  const [page, setPage] = useState<number>((searchParams.get('page') && Number(searchParams.get('adGroupsPage'))) || 0);
  const [pageSize, setPageSize] = useState<number>(
    (searchParams.get('pageSize') && Number(searchParams.get('pageSize'))) || 25,
  );
  const adGroupsSkip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange;
  const adGroupsVariables: QueryGetAdGroupSummariesArgs = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    dateRange: selectedDateRange,
    searchValue,
    page: page + 1,
    pageSize,
    sortBy: {
      field: orderBy,
      desc,
    },
  };

  if (selectedCampaignId) {
    adGroupsVariables.filterField = {
      filters: [
        {
          operation: 'eq',
          fieldName: 'campaign_id',
          value: selectedCampaignId,
        },
      ],
    };
  }

  const { adGroupSummariesLoading, getAdGroupSummaries } = useGetAdGroupSummaries(adGroupsSkip, adGroupsVariables);

  const handleRequestSort = (event: MouseEvent<unknown>, property: any) => {
    const isAsc = property === orderBy ? !desc : true;
    setDesc(isAsc);
    setOrderBy(property);
    searchParams.set('orderBy', property);
    searchParams.set('desc', String(!isAsc));
    setSearchParams(searchParams);
  };

  if (adGroupSummariesLoading || !selectedDateRange) {
    return (
      <>
        <LoadingTable headerCells={adGroupHeaderCells} />
      </>
    );
  }

  return (
    <>
      {getAdGroupSummaries?.edges?.length > 0 ? (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              headCells={adGroupHeaderCells}
              order={desc ? SortType.Desc : SortType.Asc}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {getAdGroupSummaries?.edges?.map((row: any, index: number) => (
                <CollapsibleRow key={row?.adGroupId} row={row} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              headCells={adGroupHeaderCells}
              order={desc ? SortType.Desc : SortType.Asc}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              <Styled.TableCell
                sx={{
                  textAlign: 'center',
                  height: '150px',
                }}
                colSpan={7}
              >
                No Ad Groups to Display
              </Styled.TableCell>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          borderTop: '1px solid #E5E5E5',
        }}
      >
        <CustomTablePagination
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          pageTotal={getAdGroupSummaries?.pageInfo?.total}
        />
      </Box>
    </>
  );
};

export default AdGroupsSummaryTable;
