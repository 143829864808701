import { Skeleton } from '@mui/material';

import { SortType } from '~/core/types/table.types';

import { TableCell, TableRow } from '../components/shared';
import { FilterAccessStatusEnum, FilterAccountStatusEnum, FilterAccountTypeEnum } from '../constants/table.constants';
import { AccessStatusEnum, AccountStatusEnum, PlatformAccountTypeEnum } from '../types/graphql.types';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: SortType,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === SortType.Desc
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

export const accessStatusEnumReuse = (value: FilterAccessStatusEnum) => {
  if (value === FilterAccessStatusEnum.Active) {
    return AccessStatusEnum.Active;
  }
  if (value === FilterAccessStatusEnum.Inactive) {
    return AccessStatusEnum.Inactive;
  }
  if (value === FilterAccessStatusEnum.Pending) {
    return AccessStatusEnum.Pending;
  }
  return null;
};

export const accountTypeEnumReuse = (value: FilterAccountTypeEnum) => {
  if (value === FilterAccountTypeEnum.Agency) {
    return PlatformAccountTypeEnum.Agency;
  }
  if (value === FilterAccountTypeEnum.Customer) {
    return PlatformAccountTypeEnum.Customer;
  }
  return null;
};

export const accountStatusEnumReuse = (value: FilterAccountStatusEnum) => {
  if (value === FilterAccountStatusEnum.Active) {
    return AccountStatusEnum.Active;
  }
  if (value === FilterAccountStatusEnum.Inactive) {
    return AccountStatusEnum.Inactive;
  }
  return null;
};

export const renderSkeletonRows = (columnCount: number, rowCount: number, height?: any) => {
  const skeletonRows = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rowCount; i++) {
    const skeletonColumns = [];
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < columnCount; j++) {
      skeletonColumns.push(
        <TableCell key={`skeleton-column-${j}`}>
          <Skeleton variant="text" height={height || '22.5px'} />
        </TableCell>,
      );
    }
    skeletonRows.push(
      <TableRow key={`skeleton-row-${i}`} data-testid="skeletonRow">
        {skeletonColumns}
      </TableRow>,
    );
  }
  return skeletonRows;
};
