/* eslint-disable no-console */

type FlagParameter = {
  platformAccountId?: number;
  email?: string;
  engineerLock?: boolean;
  // * ryan: boolean;
  // ! stenli: bully
};

type Account = {
  platformAccountId?: string;
  email?: string;
};

export const featureFlagUnlock = ({ platformAccountId, email, engineerLock }: FlagParameter) => {
  // When attempting to check the email please reference the authUserSelector as following
  // const user = useSelector(authUserSelector);
  // const email = user?.Email;

  // When attempting to check the platformAccountId please reference the currentUserAccountIdSelector as following
  // const currentPlatformAccountId = useSelector(currentUserAccountIdSelector);

  // Also when adding new Accounts to the unlocked accounts list please verify the JSON w/ https://jsonlint.com/
  let unlockedAccounts: Account[] = [];
  let engineerAccounts: string[] = [];

  try {
    unlockedAccounts = JSON.parse(process.env.REACT_APP_UNLOCKED_ACCOUNTS || '[]');
  } catch (error) {
    console.error('Error parsing UNLOCKED_ACCOUNT:', error);
  }

  try {
    engineerAccounts = JSON.parse(process.env.REACT_APP_ENGINEER_ACCOUNTS || '[]');
  } catch (error) {
    console.error('Error parsing ENGINEER_ACCOUNTS:', error);
    if (engineerLock) {
      return false;
    }
  }

  if (engineerAccounts?.includes(email)) {
    return true;
  }

  if (engineerLock) {
    return false;
  }

  return unlockedAccounts.some(
    (account) => platformAccountId?.toString() === account.platformAccountId || email === account.email,
  );
};
