import { gql } from '@apollo/client';

export const GET_AD_GROUPS = gql`
  query getAdGroups(
    $platformAccountId: Int!
    $customerIds: [String!]!
    $campaignIds: [String!]
    $adGroupIds: [String!]
    $dateRange: String!
    $sortBy: AdGroupSortSchema
    $filterField: BaseFilterSchema
    $searchValue: String
  ) {
    getAdGroups(
      platformAccountId: $platformAccountId
      customerIds: $customerIds
      campaignIds: $campaignIds
      adGroupIds: $adGroupIds
      dateRange: $dateRange
      sortBy: $sortBy
      filterField: $filterField
      searchValue: $searchValue
    ) {
      id
      startDate
      endDate
      campaignId
      name
      customerId
      dateRange
    }
  }
`;

export const GET_AD_GROUP_SUMMARIES = gql`
  query getAdGroupSummaries(
    $platformAccountId: Int!
    $customerIds: [String!]!
    $dateRange: String!
    $sortBy: AdGroupSummarySortSchema
    $filterField: BaseFilterSchema
    $page: Int!
    $pageSize: Int!
    $searchValue: String
  ) {
    getAdGroupSummaries(
      platformAccountId: $platformAccountId
      customerIds: $customerIds
      dateRange: $dateRange
      sortBy: $sortBy
      filterField: $filterField
      page: $page
      pageSize: $pageSize
      searchValue: $searchValue
    ) {
      edges {
        adGroupId
        campaignId
        adGroupName
        customerId
        dateRange
        impressions
        totalEligibleImpressions
        totalKeywords
        keywordsWithTraffic
        totalSegments
      }
      pageInfo {
        pageSize
        page
        total
        pages
        previousPage
        nextPage
      }
    }
  }
`;

export const GET_TRAFFIC_SEGMENTS = gql`
  query getTrafficSegments(
    $platformAccountId: Int!
    $customerId: String!
    $dateRange: String!
    $adGroupId: String!
    $sortBy: TrafficSegmentSortSchema
    $filterField: BaseFilterSchema
  ) {
    getTrafficSegments(
      platformAccountId: $platformAccountId
      customerId: $customerId
      adGroupId: $adGroupId
      dateRange: $dateRange
      sortBy: $sortBy
      filterField: $filterField
    ) {
      keywords
      intentThemes
      impressions
      clicks
      conversions
      cost
      segmentId
    }
  }
`;

export const GET_TRAFFIC_SEGMENTS_V2 = gql`
  query getTrafficSegmentsV2(
    $platformAccountId: Int!
    $customerId: String!
    $dateRange: String!
    $sortBy: TrafficSegmentSortSchema
    $filterField: BaseFilterSchema
    $page: Int!
    $pageSize: Int!
  ) {
    getTrafficSegmentsV2(
      platformAccountId: $platformAccountId
      customerId: $customerId
      dateRange: $dateRange
      sortBy: $sortBy
      filterField: $filterField
      page: $page
      pageSize: $pageSize
    ) {
      edges {
        segmentId
        segmentName
        adGroups
        keywords
        intents
        intentThemes
        auctions
        impressions
        auctionsLost
        clicks
        conversions
      }
      pageInfo {
        pageSize
        page
        total
        pages
        previousPage
        nextPage
      }
    }
  }
`;

export const GET_TRAFFIC_SEGMENTS_IMPRESSION_TOTALS = gql`
  query getTrafficSegmentsImpressionTotals(
    $platformAccountId: Int!
    $customerId: String!
    $dateRange: String!
    $filterField: BaseFilterSchema
  ) {
    getTrafficSegmentsImpressionTotals(
      platformAccountId: $platformAccountId
      customerId: $customerId
      dateRange: $dateRange
      filterField: $filterField
    ) {
      totalImpressions
      totalEligibleImpressions
    }
  }
`;
