import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { featureFlagUnlock } from '~/core/helpers/featureFlagUnlock.helper';
import { authUserSelector } from '~/store/selectors/auth.selector';
import { useContexts } from '~/store/context/useContext';

import { CustomSwitch, SearchBar } from '../ContentGapAnalysis/Tables/styles';
import { SearchContainer } from '../ContentGapAnalysis/styles';
import { SegmentationTableType } from './SegmentationAnalysis';

type SearchContainerComponentProps = {
  inputValue: string;
  setInputValue: (value: string) => void;
  tableType: SegmentationTableType;
  // showSegmentsWithAnomalies: boolean;
  // setShowSegmentsWithAnomalies: (value: boolean) => void;
  handleSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const SearchContainerComponent: React.FC<SearchContainerComponentProps> = ({
  inputValue,
  setInputValue,
  tableType,
  // showSegmentsWithAnomalies,
  // setShowSegmentsWithAnomalies,
  handleSwitchChange,
}) => {
  const { selectedPlatformAccount } = useContexts();
  const user = useSelector(authUserSelector);
  const [searchValue, setSearchValue] = useState<string>(inputValue);
  const handleSearchValue = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setInputValue(searchValue);
    }
  };
  return (
    <SearchContainer
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <SearchBar
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleSearchValue}
          placeholder={tableType === SegmentationTableType.Segments ? 'Search for segment' : 'Search for ad group'}
          sx={{
            '.MuiInputBase-root': {
              minWidth: '250px',
              marginRight: '12px',
            },
          }}
        />
        {/* TODO: Add once anomalies are implemented */}
        {/* <Box
          sx={{
            height: '38px',
            alignItems: 'center',
            display: tableType === SegmentationTableType.Segments ? 'flex' : 'none',
          }}
        >
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              size="small"
              value={showSegmentsWithAnomalies ? 'covered' : 'all'}
              displayEmpty
              onChange={(e) => {
                setPage(0);
                setShowSegmentsWithAnomalies(e.target.value === 'covered');
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: 4,
                    borderRadius: 8,
                    color: 'rgba(33, 33, 52, 0.6)',
                  },
                },
              }}
            >
              <MenuItem sx={{ fontSize: '12px', color: 'rgba(33, 33, 52, 0.6)' }} value="all">
                All Segments
              </MenuItem>
              <MenuItem sx={{ fontSize: '12px', color: 'rgba(33, 33, 52, 0.6)' }} value="covered">
                Segments with Anomalies
              </MenuItem>
            </Select>
          </FormControl>
        </Box> */}
      </Box>

      {user?.IsPlatformAdmin &&
        featureFlagUnlock({
          platformAccountId: selectedPlatformAccount?.id,
          email: user?.Email,
          engineerLock: true,
        }) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              fontSize: '12px',
              paddingRight: '24px',
            }}
          >
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
              >
                Segment View
              </Typography>
              <CustomSwitch checked={tableType === SegmentationTableType.AdGroups} onChange={handleSwitchChange} />
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
              >
                Ad Group View
              </Typography>
            </Stack>
          </Box>
        )}
    </SearchContainer>
  );
};

export default SearchContainerComponent;
