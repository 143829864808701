/* eslint-disable no-nested-ternary */

import { Close } from '@mui/icons-material';
import { Box, DialogContent, Drawer, IconButton, Skeleton } from '@mui/material';
import React, { useEffect, useRef } from 'react';

import { Tab, Tabs } from '~/core/components/shared';
import { useGetKeywords } from '~/core/services/graphql/keywords/hooks';
import { GetKeywordsQueryVariables } from '~/core/services/graphql/keywords/keywords.queries.generated';
import { KeywordSchema } from '~/core/types/graphql.types';
import { useContexts } from '~/store/context/useContext';

import * as Styled from '../styles';

type ScrollDialogProps = {
  open: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  adGroupId: string;
  adGroupName: string;
  closestKeywordText?: string;
  closetKeywordMatchType?: string;
  activeTab?: string;
  setActiveTab?: React.Dispatch<React.SetStateAction<string>>;
};

const AdGroupDialog = ({
  open,
  setDialogOpen,
  adGroupId,
  adGroupName,
  closestKeywordText = '',
  closetKeywordMatchType = '',
  activeTab = 'keywords',
  setActiveTab,
}: ScrollDialogProps) => {
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange } = useContexts();

  const getKeywordsSkip =
    !selectedPlatformAccountId || !selectedSourceAccountId || !adGroupId || !selectedDateRange || !open;
  const getKeywordsVariables: GetKeywordsQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    adGroupIds: [adGroupId],
    dateRange: selectedDateRange,
    pageSize: 99999,
    negative: false,
    filterField: {
      filters: [
        {
          operation: 'eq',
          fieldName: 'status',
          value: 'ENABLED',
        },
      ],
    },
  };

  const { keywordsLoading, getKeywords } = useGetKeywords(getKeywordsSkip, getKeywordsVariables);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, []);

  // sort keywords

  const sortedKws = getKeywords?.edges
    ?.slice()
    .sort((a, b) => a?.keyword_match_type.localeCompare(b?.keyword_match_type))

    // keep order but only return matching keyword with matching type to top of list
    ?.sort((a, b) => {
      if (a?.keyword_text === closestKeywordText && a?.keyword_match_type === closetKeywordMatchType) return -1;
      if (b.keyword_text === closestKeywordText) {
        return 1; // Put the matching item first
      }
      return a?.keyword_text.localeCompare(b?.keyword_text);
    });

  const deduplicateKws = (keywords: KeywordSchema[]) => {
    type KeywordProps = {
      keyword_text: string;
      keyword_match_type: string[];
      impressions: number;
    };
    const updatedData: KeywordProps[] = [];

    keywords?.forEach((keyword) => {
      const keywordText = keyword.keyword_text;
      const matchType = keyword.keyword_match_type;

      const existingKeyword = updatedData.find((keyword) => keyword.keyword_text === keywordText);

      if (existingKeyword) {
        existingKeyword.keyword_match_type.push(matchType);
      } else {
        updatedData.push({
          keyword_text: keywordText,
          keyword_match_type: [matchType],
          impressions: keyword.impressions,
        });
      }
    });
    return updatedData;
  };

  const deduplicatedKws = deduplicateKws(sortedKws);

  return (
    <Drawer
      sx={{
        height: '100vh',
        maxHeight: '100vh',
        width: '50vw',
        maxWidth: 'none', // Override maxWidth to ensure half width is respected
        position: 'absolute',
      }}
      PaperProps={{
        style: { width: '50vw' },
      }}
      anchor="right"
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Styled.DialogTitle id="scroll-dialog-title">Ad Group: {adGroupName}</Styled.DialogTitle>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <Tabs
        TabIndicatorProps={{
          style: { backgroundColor: '#3154aa' },
        }}
        onChange={(_, newValue) => handleTabChange(_, newValue)}
        value={activeTab}
      >
        <Tab
          value="assets"
          label={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              Keywords with Traffic
            </Box>
          }
        />
        <Tab
          value="keywords"
          label={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              Keywords without Traffic
            </Box>
          }
        />
      </Tabs>

      {activeTab === 'assets' && (
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '6px',
            }}
          >
            {keywordsLoading ? (
              <>
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </>
            ) : (
              deduplicatedKws
                ?.filter((keyword) => keyword.impressions > 0)
                ?.map((keyword) => (
                  <Styled.DialogText
                    key={keyword.keyword_text}
                    sx={{
                      display: 'flex',
                      gap: '6px',
                      alignItems: 'center',
                    }}
                  >
                    {keyword?.keyword_text}{' '}
                    {keyword?.keyword_match_type
                      .sort((a: string, b: string) => a.localeCompare(b))
                      .map((matchType: string) => (
                        <Styled.Badge
                          key={matchType}
                          sx={{
                            backgroundColor:
                              matchType === closetKeywordMatchType && keyword?.keyword_text === closestKeywordText
                                ? 'default'
                                : matchType === 'EXACT'
                                ? '#f0f0f0'
                                : matchType === 'BROAD'
                                ? '#f0f0f0'
                                : matchType === 'PHRASE'
                                ? '#f0f0f0'
                                : '#f0f0f0',
                            color:
                              matchType === closetKeywordMatchType && keyword?.keyword_text === closestKeywordText
                                ? 'black'
                                : matchType === 'EXACT'
                                ? '#3154aa'
                                : matchType === 'BROAD'
                                ? '#3154aa'
                                : matchType === 'PHRASE'
                                ? '#3154aa'
                                : '#3154aa',
                          }}
                        >
                          {matchType}
                        </Styled.Badge>
                      ))}
                  </Styled.DialogText>
                ))
            )}
          </Box>
        </DialogContent>
      )}

      {activeTab === 'keywords' && (
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '6px',
            }}
          >
            {keywordsLoading ? (
              <>
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </>
            ) : (
              deduplicatedKws
                ?.filter((keyword) => keyword.impressions === 0)
                ?.map((keyword) => (
                  <Styled.DialogText
                    key={keyword.keyword_text}
                    sx={{
                      display: 'flex',
                      gap: '6px',
                      alignItems: 'center',
                    }}
                  >
                    {keyword?.keyword_text}{' '}
                    {keyword?.keyword_match_type
                      .sort((a: string, b: string) => a.localeCompare(b))
                      .map((matchType: string) => (
                        <Styled.Badge
                          key={matchType}
                          sx={{
                            backgroundColor:
                              matchType === closetKeywordMatchType && keyword?.keyword_text === closestKeywordText
                                ? 'default'
                                : matchType === 'EXACT'
                                ? '#f0f0f0'
                                : matchType === 'BROAD'
                                ? '#f0f0f0'
                                : matchType === 'PHRASE'
                                ? '#f0f0f0'
                                : '#f0f0f0',
                            color:
                              matchType === closetKeywordMatchType && keyword?.keyword_text === closestKeywordText
                                ? 'black'
                                : matchType === 'EXACT'
                                ? '#3154aa'
                                : matchType === 'BROAD'
                                ? '#3154aa'
                                : matchType === 'PHRASE'
                                ? '#3154aa'
                                : '#3154aa',
                          }}
                        >
                          {matchType}
                        </Styled.Badge>
                      ))}
                  </Styled.DialogText>
                ))
            )}
          </Box>
        </DialogContent>
      )}
    </Drawer>
  );
};

export default AdGroupDialog;
