import { Box, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';

import { CircularProgress } from '~/core/components/shared';
import { useGetAlignmentPercentages } from '~/core/services/graphql/intents/hooks';
import { GetAlignmentPercentagesQueryVariables } from '~/core/services/graphql/intents/intents.queries.generated';
import { useContexts } from '~/store/context/useContext';

import * as Styled from './styles';
import VennDiagram from './VennDiagram';

const searchIntentColor = '#90CAF9';
const contentYouHaveColor = '#3054AA';

type BubbleChartProps = {
  demoMode?: boolean;
};

const BubbleChart: React.FC<BubbleChartProps> = ({ demoMode }) => {
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange, selectedCampaignId } = useContexts();
  const [keyPosition, setKeyPosition] = useState<number>(0);

  const variables: GetAlignmentPercentagesQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerId: selectedSourceAccountId,
    dateRange: selectedDateRange,
  };

  if (selectedCampaignId) variables.campaignId = selectedCampaignId;

  const skip = !selectedSourceAccountId || !selectedDateRange;
  const { alignmentLoading, getAlignmentPercentages, alignmentError } = useGetAlignmentPercentages(skip, variables);
  const intentCoveragePercentage = getAlignmentPercentages?.intentCoveragePercentage;
  const assetCoveragePercentage = getAlignmentPercentages?.assetCoveragePercentage;

  if (demoMode) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Styled.Container
          sx={{
            width: '900px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Styled.Heading>
            Paid Search Content Gap{' '}
            <Tooltip
              sx={{
                position: 'absolute',
                zIndex: 2,
                right: 0,
              }}
              placement="right"
              // eslint-disable-next-line max-len
              title="A content gap analysis is the comparison of what potential consumers want (search intent) and what content you currently have. It allows you to identify business opportunities caused by not having content needed that a targeted consumer wants."
            >
              <Styled.InfoIcon />
            </Tooltip>
          </Styled.Heading>

          <Styled.ChartContainer>
            <VennDiagram intentCoveragePercentage={0.2} assetCoveragePercentage={0.5} setKeyPosition={setKeyPosition} />
            <Styled.ChartKeyMainContainer
              sx={{
                left: `${keyPosition}px`,
              }}
            >
              <Styled.ChartKeyContainer>
                <Styled.Circle
                  sx={{
                    backgroundColor: searchIntentColor,
                  }}
                />
                <Styled.ChartKeyText>Consumer Search Intent</Styled.ChartKeyText>
              </Styled.ChartKeyContainer>
              <Styled.ChartKeyContainer>
                <Styled.Circle
                  sx={{
                    backgroundColor: contentYouHaveColor,
                  }}
                />
                <Styled.ChartKeyText>Content You Have</Styled.ChartKeyText>
              </Styled.ChartKeyContainer>
            </Styled.ChartKeyMainContainer>
          </Styled.ChartContainer>
        </Styled.Container>
      </Box>
    );
  }

  if (alignmentLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Styled.Container
          sx={{
            width: '900px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Styled.Heading>
            Paid Search Content Gap{' '}
            <Tooltip
              sx={{
                position: 'absolute',
                zIndex: 2,
                right: 0,
              }}
              placement="right"
              // eslint-disable-next-line max-len
              title="A content gap analysis is the comparison of what potential consumers want (search intent) and what content you currently have. It allows you to identify business opportunities caused by not having content needed that a targeted consumer wants."
            >
              <Styled.InfoIcon />
            </Tooltip>
          </Styled.Heading>
          <Styled.ChartContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                justifyContent: 'center',
                marginLeft: '100px',
                height: '400px',
              }}
            >
              <CircularProgress />
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: 1.66,
                  letterSpacing: '0.4px',
                  color: 'rgba(33, 33, 52, 0.6)',
                }}
              >
                Loading Visualization
              </Typography>
            </Box>
          </Styled.ChartContainer>
        </Styled.Container>
      </Box>
    );
  }

  if (alignmentError) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Styled.Container
          sx={{
            width: '900px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Styled.ChartContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  fontSize: '16px',
                  lineHeight: 1.66,
                  letterSpacing: '0.4px',
                  color: 'rgba(33, 33, 52, 0.6)',
                  height: '100%x',
                  textAlign: 'center',
                  marginTop: '150px',
                }}
              >
                An error occurred while loading the content gap analysis.
                <br />
                <span style={{ fontWeight: 'bold', fontSize: '12px' }}>
                  Please try again or contact support if the issue persists.
                </span>
              </Box>
            </Box>
          </Styled.ChartContainer>
        </Styled.Container>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Styled.Container
        sx={{
          width: '900px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Styled.Heading>
          Paid Search Content Gap{' '}
          <Tooltip
            sx={{
              position: 'absolute',
              zIndex: 2,
              right: 0,
            }}
            placement="right"
            // eslint-disable-next-line max-len
            title="A content gap analysis is the comparison of what potential consumers want (search intent) and what content you currently have. It allows you to identify business opportunities caused by not having content needed that a targeted consumer wants."
          >
            <Styled.InfoIcon />
          </Tooltip>
        </Styled.Heading>

        <Styled.ChartContainer>
          <VennDiagram
            intentCoveragePercentage={intentCoveragePercentage}
            assetCoveragePercentage={assetCoveragePercentage}
            setKeyPosition={setKeyPosition}
          />
          <Styled.ChartKeyMainContainer
            sx={{
              left: `${keyPosition}px`,
            }}
          >
            <Styled.ChartKeyContainer>
              <Styled.Circle
                sx={{
                  backgroundColor: searchIntentColor,
                }}
              />
              <Styled.ChartKeyText>Consumer Search Intent</Styled.ChartKeyText>
            </Styled.ChartKeyContainer>
            <Styled.ChartKeyContainer>
              <Styled.Circle
                sx={{
                  backgroundColor: contentYouHaveColor,
                }}
              />
              <Styled.ChartKeyText>Content You Have</Styled.ChartKeyText>
            </Styled.ChartKeyContainer>
          </Styled.ChartKeyMainContainer>
        </Styled.ChartContainer>
      </Styled.Container>
    </Box>
  );
};

export default BubbleChart;
