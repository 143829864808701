import { useQuery } from '@apollo/client';

import { AccountStatusSchema, AvailableDataSchema } from '~/core/types/graphql.types';

import { GET_ASSESSMENT_AVAILABILITY, GET_ASSESSMENT_STATUSES } from './sourceAccounts.queries';
import {
  GetAssessmentAvailabilityQueryVariables,
  GetAssessmentStatusesQueryVariables,
} from './sourceAccounts.queries.generated';

export const useGetAssesmentAvailability = (skip: boolean, variables: GetAssessmentAvailabilityQueryVariables) => {
  const {
    data: assessmentAvailabilityData,
    loading: assessmentAvailabilityLoading,
    error: assessmentAvailabilityError,
  } = useQuery<{ getAssessmentAvailability: AvailableDataSchema[] }>(GET_ASSESSMENT_AVAILABILITY, {
    skip,
    variables,
  });

  const getAssessmentAvailability = assessmentAvailabilityData?.getAssessmentAvailability;

  return { assessmentAvailabilityLoading, assessmentAvailabilityError, getAssessmentAvailability };
};

export const useGetAssessmentStatuses = (skip: boolean, variables: GetAssessmentStatusesQueryVariables) => {
  const {
    data: assessmentStatusData,
    loading: assessmentStatusLoading,
    error: assessmentStatusError,
  } = useQuery<{ getAssessmentStatuses: AccountStatusSchema }>(GET_ASSESSMENT_STATUSES, {
    skip,
    variables,
  });

  const getAssessmentStatuses = assessmentStatusData?.getAssessmentStatuses;

  return { assessmentStatusLoading, assessmentStatusError, getAssessmentStatuses };
};
