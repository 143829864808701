/* eslint-disable no-nested-ternary */
import { CheckCircle, Error, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  TableHead as MuiTableHead,
  TableCell,
  TableRow,
  TableRowProps,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { convertToTitleCase, numberWithCommas } from '~/core/helpers';
import { IntentsSchema } from '~/core/types/graphql.types';
import { useContexts } from '~/store/context/useContext';

import * as Styled from '../styles';
import AdGroupDialog from './AdGroupDialog';

interface CollapsibleRowProps extends TableRowProps {
  row: IntentsSchema;
  index: number;
  hasRecommendations?: boolean;
  onIntentSelect?: (intentId: string | null) => void;
  selectedIntentIds?: (string | null)[];
}

// CollapsibleRow Component Definition
const CollapsibleRow: React.FC<CollapsibleRowProps> = ({
  row,
  hasRecommendations,
  onIntentSelect,
  selectedIntentIds = [],
  ...props
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { selectedCampaignId, setSelectedClusterId, selectedSourceAccountId, selectedAccountName, selectedDateRange } =
    useContexts();

  const [open, setOpen] = useState(false);
  const [adGroupDialogOpen, setAdGroupDialogOpen] = useState(false);

  const campaignName = row?.campaignName;
  const adGroupName = row?.adGroupName;
  const totalAssets = (row.alignedHeadlines || 0) + (row.alignedDescriptions || 0);

  const covered = row?.aligned;

  let url = `${covered ? 'assets' : 'recommendations'}?${searchParams.toString()}&intentId=${row?.id}`;

  if (selectedCampaignId) {
    url += `&campaignId=${selectedCampaignId}`;
  }

  if (selectedDateRange) {
    url += `&dateRange=${selectedDateRange}`;
  }

  if (selectedSourceAccountId) {
    url += `&sourceAccountId=${selectedSourceAccountId}`;
  }

  url += `&adGroup=${row?.adGroupId}`;

  url += `&accountName=${selectedAccountName}`;
  url += `&intentName=${row?.name}`;

  const handleClick = (event: any, row: IntentsSchema) => {
    event.stopPropagation();
    setSelectedClusterId(row?.id);

    navigate(url);
  };

  const handleCheckboxClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (onIntentSelect && !covered && hasRecommendations) {
      onIntentSelect(row?.id);
    }
  };

  return (
    <>
      <TableRow
        sx={{
          background: '#ffffff',
          border: !open ? 'default' : 'none',
        }}
        data-testid="parentRow"
        key={row?.id}
        onClick={() => setOpen(!open)}
        {...props}
      >
        <TableCell
          size="small"
          data-testid="name"
          sx={{ display: 'flex', alignItems: 'center', minWidth: '240px', borderBottom: 'none' }}
        >
          <Styled.IconContainer aria-label="expand row" size="small" disableRipple>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Styled.IconContainer>
          {convertToTitleCase(row?.name)}
        </TableCell>
        <Styled.MainTableCell
          sx={{
            border: 'none',
          }}
          size="small"
        >
          {row?.impressions?.toLocaleString()}
        </Styled.MainTableCell>
        <Styled.MainTableCell
          sx={{
            border: 'none',
          }}
          size="small"
        >
          {numberWithCommas(Number(row?.conversions?.toFixed(0)))}
        </Styled.MainTableCell>

        <Styled.MainTableCell
          sx={{
            border: 'none',
          }}
          size="small"
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '5.7px',
              minWidth: '100px',
            }}
          >
            {totalAssets > 0 && covered ? (
              <CheckCircle
                sx={{
                  color: '#66bb6a',
                }}
              />
            ) : (
              <Error
                sx={{
                  color: '#EF5350',
                }}
              />
            )}{' '}
            {totalAssets || 0} {totalAssets === 1 ? 'Asset' : 'Assets'}
          </Box>
        </Styled.MainTableCell>
        <Styled.MainTableCell
          sx={{
            border: 'none',
          }}
          size="small"
        >
          <Checkbox
            checked={selectedIntentIds.includes(row?.id)}
            onClick={handleCheckboxClick}
            disabled={covered || !hasRecommendations}
          />
        </Styled.MainTableCell>
        <Styled.MainTableCell
          sx={{
            border: 'none',
          }}
          size="small"
        >
          {totalAssets > 0 && covered ? (
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={(e) => handleClick(e, row)}
              sx={{
                height: '30px',
                width: '95px',
                padding: '0px',
                fontSize: '10px',
                fontWeight: 600,
                lineHeight: 2.2,
                letterSpacing: '0.46px',
                textTransform: 'none',
              }}
            >
              View Content
            </Button>
          ) : (
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={(e) => handleClick(e, row)}
              disabled={!hasRecommendations}
              sx={{
                height: '30px',
                width: '95px',
                padding: '0px',
                fontSize: '10px',
                fontWeight: 600,
                lineHeight: 2.2,
                letterSpacing: '0.46px',
                textTransform: 'none',
              }}
            >
              Find Content
            </Button>
          )}
        </Styled.MainTableCell>
      </TableRow>
      <TableRow
        sx={{
          background: '#ffffff',
        }}
        data-testid="childRow"
      >
        <Styled.MainCell colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Styled.CollapsibleBox>
              <Styled.Table aria-label="ad-groups">
                <Styled.DisplayFlexBox>
                  <Styled.BoldBox>Where This Search Intent Shows Up</Styled.BoldBox>
                </Styled.DisplayFlexBox>
                <Styled.InnerTable>
                  <MuiTableHead data-testid="tableHead">
                    <Styled.HeadCell>Campaign</Styled.HeadCell>
                    <Styled.HeadCell>Ad Group (ID)</Styled.HeadCell>
                    <Styled.HeadCell>Best Aligned Keyword</Styled.HeadCell>
                  </MuiTableHead>

                  <TableRow>
                    <Styled.DetailCell>{campaignName}</Styled.DetailCell>
                    <Styled.DetailCell>
                      {adGroupName} ({row?.adGroupId}){' '}
                      <Typography
                        onClick={() => setAdGroupDialogOpen(true)}
                        sx={{
                          display: 'inline',
                          textDecoration: 'underline',
                          color: '#3154aa',
                          fontSize: '14px',
                          fontWeight: 'bold',
                          lineHeight: 1.43,
                          letterSpacing: '0.17px',
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                      >
                        View
                      </Typography>
                    </Styled.DetailCell>
                    <Styled.DetailCell>{row?.closestKeywordText}</Styled.DetailCell>
                  </TableRow>
                </Styled.InnerTable>
              </Styled.Table>
            </Styled.CollapsibleBox>
          </Collapse>
        </Styled.MainCell>
      </TableRow>

      {open && (
        <AdGroupDialog
          open={adGroupDialogOpen}
          setDialogOpen={setAdGroupDialogOpen}
          adGroupId={row?.adGroupId}
          adGroupName={adGroupName}
          closestKeywordText={row?.closestKeywordText}
          closetKeywordMatchType={row?.closestKeywordMatchType}
        />
      )}
    </>
  );
};

export default CollapsibleRow;
