import axios from 'axios';

import { setAccessTokenAction } from '~/store/actions/auth.action';
import { store } from '~/store/index';

import { refreshTokenRequest } from './auth.service';

interface RsaAdAsset {
  text: string;
  assetType: 'headline' | 'description';
}

interface RsaAdGroup {
  adGroupId: string;
  adGroupName: string;
  assets: RsaAdAsset[];
}

interface RsaAdCampaign {
  campaignId: string;
  campaignName: string;
  adGroups: RsaAdGroup[];
}

interface RsaAdPulseRequest {
  customerId: string;
  taskName: string;
  assets: RsaAdCampaign[];
}

const AdFactoryApiClient = axios.create({
  baseURL:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? `${process.env.REACT_APP_API_BASE_URL}/ad-factory/api/v1`
      : `${window.location.protocol}//backend-${window.location.hostname}/ad-factory/api/v1`,
});

AdFactoryApiClient.interceptors.request.use(async (config) => {
  const { tokenExpiredTimestamp, AccessToken, RefreshToken } = store.getState().auth;
  const currentTime = new Date().getTime();

  if (tokenExpiredTimestamp && tokenExpiredTimestamp * 1000 < currentTime) {
    const { AccessToken, IdToken, User } = await refreshTokenRequest(RefreshToken);

    store.dispatch(setAccessTokenAction.request({ AccessToken, IdToken, User }));

    config.headers = {
      Authorization: `${AccessToken}`,
    };

    return config;
  }

  config.headers = {
    Authorization: `${AccessToken}`,
  };

  return config;
});

AdFactoryApiClient.interceptors.response.use((response) => response);

export const rsaAdPulse = async (data: RsaAdPulseRequest) => {
  try {
    const response = await AdFactoryApiClient.post('/rsa_ad_pulse', data);
    return response.data;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    throw error;
  }
};

export default AdFactoryApiClient;
