import { useQuery } from '@apollo/client';

import {
  AdGroupSchema,
  QueryGetAdGroupSummariesArgs,
  QueryGetAdGroupsArgs,
  QueryGetTrafficSegmentsArgs,
  QueryGetTrafficSegmentsV2Args,
  TrafficSegmentSchema,
  TrafficSegmentSchemaPaginationSchema,
} from '~/core/types/graphql.types';

import {
  GET_AD_GROUPS,
  GET_AD_GROUP_SUMMARIES,
  GET_TRAFFIC_SEGMENTS,
  GET_TRAFFIC_SEGMENTS_IMPRESSION_TOTALS,
  GET_TRAFFIC_SEGMENTS_V2,
} from './adGroups.queries';
import {
  GetTrafficSegmentsImpressionTotalsQuery,
  GetTrafficSegmentsImpressionTotalsQueryVariables,
} from './adGroups.queries.generated';

export const useGetAdGroups = (skip: boolean, variables: QueryGetAdGroupsArgs) => {
  const {
    loading: adGroupsLoading,
    error: adGroupsError,
    data: adGroupsData,
  } = useQuery<{ getAdGroups: AdGroupSchema[] }>(GET_AD_GROUPS, {
    skip,
    variables,
  });

  const getAdGroups = adGroupsData?.getAdGroups;

  return { adGroupsLoading, adGroupsError, getAdGroups };
};

export const useGetAdGroupSummaries = (skip: boolean, variables: QueryGetAdGroupSummariesArgs) => {
  const {
    loading: adGroupSummariesLoading,
    error: adGroupSummariesError,
    data: adGroupSummariesData,
  } = useQuery<{ getAdGroupSummaries: any }>(GET_AD_GROUP_SUMMARIES, {
    skip,
    variables,
  });

  const getAdGroupSummaries = adGroupSummariesData?.getAdGroupSummaries;

  return { adGroupSummariesLoading, adGroupSummariesError, getAdGroupSummaries };
};

export const useGetTrafficSegments = (skip: boolean, variables: QueryGetTrafficSegmentsArgs) => {
  const {
    loading: trafficSegmentsLoading,
    error: trafficSegmentsError,
    data: trafficSegmentsData,
  } = useQuery<{ getTrafficSegments: TrafficSegmentSchema[] }>(GET_TRAFFIC_SEGMENTS, {
    skip,
    variables,
  });

  const getTrafficSegments = trafficSegmentsData?.getTrafficSegments;

  return { trafficSegmentsLoading, trafficSegmentsError, getTrafficSegments };
};

export const useGetTrafficSegmentsV2 = (skip: boolean, variables: QueryGetTrafficSegmentsV2Args) => {
  const {
    loading: trafficSegmentsLoading,
    error: trafficSegmentsError,
    data: trafficSegmentsV2Data,
  } = useQuery<{ getTrafficSegmentsV2: TrafficSegmentSchemaPaginationSchema }>(GET_TRAFFIC_SEGMENTS_V2, {
    skip,
    variables,
    // may need to add back due to uuid issue
    // fetchPolicy: 'network-only',
  });

  const getTrafficSegments = trafficSegmentsV2Data?.getTrafficSegmentsV2;

  return { trafficSegmentsLoading, trafficSegmentsError, getTrafficSegments };
};

export const useGetTrafficSegmentsImpressionsTotal = (
  skip: boolean,
  variables: GetTrafficSegmentsImpressionTotalsQueryVariables,
) => {
  const {
    loading: trafficSegmentsImpressionsTotalLoading,
    error: trafficSegmentsImpressionsTotalError,
    data: trafficSegmentsImpressionsTotalData,
  } = useQuery<GetTrafficSegmentsImpressionTotalsQuery>(GET_TRAFFIC_SEGMENTS_IMPRESSION_TOTALS, {
    skip,
    variables,
  });

  const getTrafficSegmentsImpressionsTotal = trafficSegmentsImpressionsTotalData?.getTrafficSegmentsImpressionTotals;

  return {
    trafficSegmentsImpressionsTotalLoading,
    trafficSegmentsImpressionsTotalError,
    getTrafficSegmentsImpressionsTotal,
  };
};
