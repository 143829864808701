import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Skeleton,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Close } from '@mui/icons-material';

import { useGetIntentsAssetMapPaginated } from '~/core/services/graphql/intents/hooks';
import { IntentsAssetMapSchema, IntentsSchema } from '~/core/types/graphql.types';
import { useContexts } from '~/store/context/useContext';
import { useGetCampaigns } from '~/core/services/graphql/campaigns/hooks';
import { GetCampaignsQueryVariables } from '~/core/services/graphql/campaigns/campaigns.queries.generated';
import { useGetAdGroups } from '~/core/services/graphql/adGroups/hooks';
import { Tooltip } from '~/core/components/shared';

import { InfoIcon } from '../ContentGapAnalysis/Tables/styles';
// import { useGetKeywords } from '~/core/services/graphql/keywords/hooks';
// import { GetKeywordsQueryVariables } from '~/core/services/graphql/keywords/keywords.queries.generated';

interface ThemeInsightsProps {
  selectedTheme: any;
  tabValue: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  intents: IntentsSchema[];
  isLoading: boolean;
  setSelectedTheme: (theme: any) => void;
}

const StyledAccordion = styled(Accordion)(() => ({
  '&::before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-content': {
    margin: '8px 0',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 10,
  minHeight: '40px !important',
  padding: '0 16px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiTypography-root': {
    fontSize: '12px',
    fontWeight: 600,
  },
  '& .MuiAccordionSummary-content': {
    margin: '8px 0',
  },
  cursor: 'pointer',
  '&:hover': {
    color: '#4b5563', // text-gray-700
    textDecoration: 'underline',
  },
}));

const AssetAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&::before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-content': {
    margin: '4px 0',
  },
}));

const AssetAccordionSummary = styled(AccordionSummary)(() => ({
  minHeight: '36px !important',
  padding: '0 16px',
  '& .MuiTypography-root': {
    fontSize: '12px',
    lineHeight: '1.43',
  },
  cursor: 'pointer',
  '&:hover': {
    color: '#4b5563', // text-gray-700
    textDecoration: 'underline',
  },
}));

const ScrollableBox = styled(Box)({
  maxHeight: '460px',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
  },
});

const AssetText = styled(Typography)(() => ({
  fontSize: '12px',
  color: '#6b7280', // text-gray-500
  textAlign: 'left',
}));

const AssetDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: '4px 16px',
  '& .MuiTypography-root': {
    fontSize: '11px',
    lineHeight: '1.43',
    color: theme.palette.text.secondary,
  },
}));

const ThemeInsights: React.FC<ThemeInsightsProps> = ({
  selectedTheme,
  tabValue,
  handleTabChange,
  intents,
  isLoading,
  setSelectedTheme,
}) => {
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange } = useContexts();
  const intentIds = useMemo(() => intents?.map((intent) => intent.id), [intents]);
  const getIntentsAssetMapSkip =
    !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange || !intentIds;
  const getIntentsAssetMapVars = {
    platformAccountId: selectedPlatformAccountId,
    customerId: selectedSourceAccountId,
    dateRange: selectedDateRange,
    intentIds,
    pageSize: 500,
    page: 1,
  };

  const { getIntentsAssetMapPaginated, assetMapPaginatedLoading } = useGetIntentsAssetMapPaginated(
    getIntentsAssetMapSkip,
    getIntentsAssetMapVars,
  );

  const [headlines, setHeadlines] = useState<IntentsAssetMapSchema[]>([]);
  const [descriptions, setDescriptions] = useState<IntentsAssetMapSchema[]>([]);

  useEffect(() => {
    const newHeadlines: IntentsAssetMapSchema[] = [];
    const newDescriptions: IntentsAssetMapSchema[] = [];
    const seenAssets = new Set<string>();

    getIntentsAssetMapPaginated?.edges?.forEach((asset: IntentsAssetMapSchema) => {
      const assetKey = `${asset.assetText}-${asset.adGroupId}`;

      if (!seenAssets.has(assetKey)) {
        seenAssets.add(assetKey);

        if (asset.assetType === 'HEADLINE') {
          newHeadlines.push(asset);
        }
        if (asset.assetType === 'DESCRIPTION') {
          newDescriptions.push(asset);
        }
      }
    });

    setHeadlines(newHeadlines);
    setDescriptions(newDescriptions);
  }, [getIntentsAssetMapPaginated]);

  const getCampaignsSkip = !selectedPlatformAccountId || !selectedSourceAccountId;
  const getCampaignsVars: GetCampaignsQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: selectedSourceAccountId,
    dateRange: selectedDateRange,
  };
  const { getCampaigns, campaignsLoading } = useGetCampaigns(getCampaignsSkip, getCampaignsVars);
  const campaignIds = useMemo(() => getCampaigns?.map((campaign) => campaign.id), [getCampaigns]);

  const getAdGroupsSkip = !selectedPlatformAccountId || !selectedSourceAccountId || !campaignIds?.length;
  const { getAdGroups, adGroupsLoading } = useGetAdGroups(getAdGroupsSkip, {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    dateRange: selectedDateRange,
    campaignIds,
  });

  // ! May use when adding keywords to the theme insights ! //
  // const adGroupIds = useMemo(() => getAdGroups?.map((adGroup) => adGroup.id), [getAdGroups]);
  // const getKeywordsSkip =
  //   !selectedPlatformAccountId || !selectedSourceAccountId || !campaignIds?.length || !adGroupIds?.length;
  // const getKeywordsVars: GetKeywordsQueryVariables = {
  //   platformAccountId: selectedPlatformAccountId,
  //   customerIds: [selectedSourceAccountId],
  //   dateRange: selectedDateRange,
  //   campaignIds,
  //   adGroupIds,
  //   filterField: {
  //     filters: [
  //       {
  //         fieldName: 'theme',
  //         operation: 'eq',
  //         value: selectedTheme.theme,
  //       },
  //     ],
  //   },
  // };
  // const { getKeywords, keywordsLoading } = useGetKeywords(getKeywordsSkip, getKeywordsVars);

  if (isLoading || assetMapPaginatedLoading || campaignsLoading || adGroupsLoading) {
    return (
      <Box>
        <Skeleton variant="text" width="200px" height={32} sx={{ mb: 2 }} />
        <Box display="flex" gap={2} mb={2}>
          <Box flex={1} bgcolor="grey.100" borderRadius={1} p={1.5}>
            <Skeleton variant="text" width="80px" height={16} />
            <Skeleton variant="text" width="120px" height={24} />
          </Box>
        </Box>
        <Skeleton variant="rectangular" width="100%" height={48} sx={{ mb: 2 }} />
        <Box mt={2}>
          <Skeleton variant="text" width="200px" height={24} sx={{ mb: 1 }} />
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
            <Box key={item} bgcolor="grey.100" borderRadius={1} p={1} mb={0.8}>
              <Skeleton variant="text" width="80%" height={18} />
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" textTransform="capitalize" gutterBottom>
          {selectedTheme.theme}
        </Typography>
        <IconButton onClick={() => setSelectedTheme(null)}>
          <Close />
        </IconButton>
      </Box>
      {/* <Box display="flex" gap={2} mb={2}>
        <Box flex={1} bgcolor="grey.100" borderRadius={1} p={1.5}>
          <Typography variant="caption" color="textSecondary">
            Impressions
          </Typography>
          <Typography variant="h6">{numberWithCommas(selectedTheme.impressions)}</Typography>
        </Box>
      </Box> */}
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="theme insights tabs" sx={{ width: '100%' }}>
        <Tab
          sx={{
            width: '50%',
          }}
          label={
            <Box display="flex" alignItems="center" gap={0.5}>
              Search Intent
              <Tooltip title="Search intent is traditionally categorized into four main types: commercial, informational, navigational, and transactional. However, our proprietary approach delves deeper by providing more specific descriptive labels that highlight what a distinct group of users is actively looking for. Each type of search intent represents a unique audience, enabling advertisers to align their ads more effectively with user needs.">
                <InfoIcon />
              </Tooltip>
            </Box>
          }
        />
        <Tab
          sx={{
            width: '50%',
          }}
          label="Content"
        />
        {/* <Tab label="Keywords" /> */}
      </Tabs>
      <Box mt={2}>
        {tabValue === 0 && (
          <Box>
            <Box display="flex" alignItems="center" gap={1} mb={1}>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  backgroundColor: '#DCFCE7',
                  border: '1px solid #B0D3BC',
                }}
              />
              <Typography variant="subtitle2">Audience Opportunity</Typography>
            </Box>
            <ScrollableBox>
              {intents?.map((intent) => (
                <Box key={intent.id} bgcolor={!intent.aligned ? '#DCFCE7' : 'grey.100'} borderRadius={1} p={1} mb={1}>
                  <Typography variant="body2">{intent.name}</Typography>
                </Box>
              ))}
            </ScrollableBox>
          </Box>
        )}
        {tabValue === 1 && (
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Assets you have aligning to this audience
            </Typography>
            <StyledAccordion>
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ fontWeight: 500 }}>{headlines?.length} Headlines</Typography>
              </StyledAccordionSummary>
              <AccordionDetails>
                <ScrollableBox sx={{ maxHeight: '190px' }}>
                  {headlines?.map((headline) => (
                    <AssetAccordion key={headline.assetText} elevation={0}>
                      <AssetAccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <AssetText variant="body2">{headline.assetText}</AssetText>
                      </AssetAccordionSummary>
                      <AssetDetails>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                          Campaign: {getCampaigns?.find((campaign) => campaign.id === headline.campaignId)?.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Ad Group: {getAdGroups?.find((adGroup) => adGroup.id === headline.adGroupId)?.name}
                        </Typography>
                      </AssetDetails>
                    </AssetAccordion>
                  ))}
                </ScrollableBox>
              </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ fontWeight: 500 }}>{descriptions.length} Descriptions</Typography>
              </StyledAccordionSummary>
              <AccordionDetails>
                <ScrollableBox>
                  {descriptions?.map((description) => (
                    <AssetAccordion key={description.assetText} elevation={0}>
                      <AssetAccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <AssetText variant="body2">{description.assetText}</AssetText>
                      </AssetAccordionSummary>
                      <AssetDetails>
                        <Typography variant="body2" color="text.secondary">
                          Campaign: {getCampaigns?.find((campaign) => campaign.id === description.campaignId)?.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Ad Group: {getAdGroups?.find((adGroup) => adGroup.id === description.adGroupId)?.name}
                        </Typography>
                      </AssetDetails>
                    </AssetAccordion>
                  ))}
                </ScrollableBox>
              </AccordionDetails>
            </StyledAccordion>
          </Box>
        )}
        {/* {tabValue === 2 && (
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Keywords this audience is coming through
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1}></Box>
          </Box>
        )} */}
      </Box>
    </Box>
  );
};

export default ThemeInsights;
