import { styled } from '@mui/material/styles';
import { Card, CardContent, CardHeader } from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 1200,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  marginBottom: 24,
}));

export const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const GridContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

export const StatsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const StatItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const ChartContainer = styled('div')({
  height: 300,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
