import { Typography, Box } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

import { ChartContainer } from '../../../../styles';
import { PieChartViewProps } from '../../../../types';

const chartColors = ['#324ba4', '#e76e50'];

type TooltipContentProps = {
  payload: any;
  totalEligibleImpressions: number;
};
const TooltipContent = ({ payload, totalEligibleImpressions }: TooltipContentProps) => {
  if (payload && payload.length > 0) {
    const value = payload[0]?.value;
    const color = payload[0].name === 'Total Actual' ? '#324ba4' : '#e76e50';
    return (
      <div
        style={{
          backgroundColor: 'white',
          padding: '12px',
          borderRadius: '8px',
          boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e5e7eb',
        }}
      >
        <p style={{ margin: 0, fontWeight: '600', color: '#1f2937' }}>{payload[0].name}</p>
        <p style={{ margin: 0, fontSize: '1.125rem', color }}>{value.toLocaleString()} Impr.</p>
        <p style={{ margin: 0, fontSize: '0.875rem', color: '#4b5563' }}>
          of {totalEligibleImpressions.toLocaleString()} total
        </p>
      </div>
    );
  }
  return null;
};

// const CustomTooltip = ({ active, payload }: any) => {
//   if (active && payload && payload?.length) {
//     const data = payload[0].payload as KeywordData;
//     return (
//       <Paper elevation={3} sx={{ p: 2, minWidth: 240 }}>
//         <Typography variant="subtitle1" gutterBottom>
//           {data.name}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           Total Eligible Impressions: {data.eligibleImpressions.toLocaleString()}
//         </Typography>
//         <Typography variant="body2" color="text.secondary" fontSize={12}>
//           Missing Impressions: {data.missingImpressions.toLocaleString()}
//         </Typography>
//         {/* <Box
//           sx={{
//             display: 'inline-block',
//             px: 1,
//             py: 0.5,
//             borderRadius: 1,
//             bgcolor: data.gap > 45 ? 'error.main' : 'grey.300',
//             color: data.gap > 45 ? 'white' : 'text.primary',
//             mt: 1,
//             fontSize: '0.875rem',
//           }}
//         >
//           {Math.round(data.gap)}%
//         </Box> */}
//       </Paper>
//     );
//   }
//   return null;
// };

export const PieChartView = ({ data }: PieChartViewProps) => {
  // const renderCustomizedLabel = (props: any) => {
  //   const { cx, cy, midAngle, innerRadius, outerRadius, value, index, payload } = props;
  //   const RADIAN = Math.PI / 180;
  //   const radius = 25 + innerRadius + (outerRadius - innerRadius);
  //   const x = cx + radius * Math.cos(-midAngle * RADIAN);
  //   const y = cy + radius * Math.sin(-midAngle * RADIAN);

  //   return (
  //     <text
  //       x={x}
  //       y={y}
  //       fill={CHART_COLORS[index % CHART_COLORS.length]}
  //       textAnchor={x > cx ? 'start' : 'end'}
  //       dominantBaseline="central"
  //       style={{
  //         fontWeight: 'bold',
  //         fontSize: '12px',
  //         opacity:
  //           hoveredKeyword === null ||
  //           hoveredKeyword === payload.name ||
  //           (selectedKeyword &&
  //             `${selectedKeyword.keyword_text} (${selectedKeyword.keyword_match_type})` === payload.name)
  //             ? 1
  //             : 0.3,
  //       }}
  //     >
  //       {value?.toLocaleString()}
  //     </text>
  //   );
  // };

  const totalMissingImpressions = data.reduce((acc, curr) => acc + curr.missingImpressions, 0);
  const totalImpressions = Math.round(data.reduce((acc, curr) => acc + curr.impressions, 0));
  const totalEligibleImpressions = totalImpressions + totalMissingImpressions;
  const chartData = [
    {
      name: 'Total Actual',
      value: totalImpressions,
    },
    {
      name: 'Total Missing',
      value: totalMissingImpressions,
    },
  ];

  return (
    <div>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h6" gutterBottom fontSize="1rem">
            Segment Total Eligible Impr.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>{totalEligibleImpressions.toLocaleString()}</strong>
          </Typography>
        </Box>
      </Box>
      <ChartContainer>
        <ResponsiveContainer width="100%" height="100%">
          {/* <PieChart width={300} height={300}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={{ stroke: '#888', strokeWidth: 1 }}
              outerRadius={100}
              fill="#8884d8"
              dataKey="eligibleImpressions"
              isAnimationActive={false}
              label={renderCustomizedLabel}
              onMouseEnter={(data) => onHover(data.name)}
              onMouseLeave={() => onHover(null)}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${entry.name}`}
                  fill={CHART_COLORS[index % CHART_COLORS.length]}
                  opacity={
                    hoveredKeyword === null ||
                    hoveredKeyword === entry.name ||
                    (selectedKeyword &&
                      `${selectedKeyword.keyword_text} (${selectedKeyword.keyword_match_type})` === entry.name)
                      ? 1
                      : 0.3
                  }
                  stroke="#fff"
                  strokeWidth={2}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart> */}
          <PieChart width={415} height={300}>
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${entry.name}`}
                  fill={chartColors[index]}
                  style={{ transition: 'opacity 300ms', opacity: 1, outline: 'none' }}
                  onMouseEnter={(e) => {
                    const target = e?.currentTarget;
                    if (target) {
                      target.style.opacity = '0.8';
                    }
                  }}
                  onMouseLeave={(e) => {
                    const target = e?.currentTarget;
                    if (target) {
                      target.style.opacity = '1';
                    }
                  }}
                />
              ))}
            </Pie>
            <Tooltip
              content={<TooltipContent payload={chartData} totalEligibleImpressions={totalEligibleImpressions} />}
            />
          </PieChart>
        </ResponsiveContainer>
      </ChartContainer>
    </div>
  );
};
