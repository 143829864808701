import { Box, Checkbox, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { SortType } from '~/core/types/table.types';

import { TableRow } from '../TableRow';
import * as Styled from './styles';
import { HeadCell, TableHeadProps } from './types';

const TableHead: React.FC<TableHeadProps> = ({
  headCells,
  checkboxOption,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  ...elementProps
}) => {
  const createSortHandler = (id: string) => (event: React.MouseEvent<unknown>) => {
    if (onRequestSort) {
      onRequestSort(event, id);
    }
  };
  return (
    <Styled.TableHead {...elementProps} data-testid="tableHead">
      <TableRow>
        {checkboxOption && (
          <Styled.TableCell padding="checkbox">
            <Checkbox
              color="primary"
              // indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </Styled.TableCell>
        )}
        {headCells.map((headCell: HeadCell) => (
          <Styled.TableCell
            width={headCell.width}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={headCell?.sx}
          >
            {headCell?.id === '' || headCell?.disableSort ? (
              <TableSortLabel
                sx={{
                  paddingLeft: '18px',
                  '&:hover': {
                    cursor: 'default',
                    color: 'rgb(33,33,52, 0.87)',
                  },
                }}
                IconComponent={null}
              >
                {headCell.label}
                {orderBy === headCell.id && (
                  <Box component="span" sx={visuallyHidden}>
                    {order === SortType.Desc ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                )}
              </TableSortLabel>
            ) : (
              <TableSortLabel
                data-testid={`sortBy${headCell.label}`}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : SortType.Asc}
                onClick={headCell?.id !== '' && createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id && (
                  <Box component="span" sx={visuallyHidden}>
                    {order === SortType.Desc ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                )}
              </TableSortLabel>
            )}
          </Styled.TableCell>
        ))}
      </TableRow>
    </Styled.TableHead>
  );
};

export default TableHead;
