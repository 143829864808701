/* eslint-disable no-nested-ternary */
import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';

import ErrorSVG from '~/assets/misc/error.svg';
import { useGetIntents } from '~/core/services/graphql/intents/hooks';
import { IntentsSortEnum, QueryGetIntentsArgs } from '~/core/types/graphql.types';
import { useContexts } from '~/store/context/useContext';
import { GetAssessmentAvailabilityQueryVariables } from '~/core/services/graphql/sourceAccounts/sourceAccounts.queries.generated';
import { useGetAssesmentAvailability } from '~/core/services/graphql/sourceAccounts/hooks';

import { BubbleChart } from './components';
import * as Styled from './styles';
import IntentsTable from './Tables/IntentsTable/IntentsTable';
import DemoTable from './Tables/IntentsTable/DemoTable';

type ContentGapAnalysisProps = {
  demoMode?: boolean;
};

const ContentGapAnalysis: React.FC<ContentGapAnalysisProps> = ({ demoMode }) => {
  const {
    selectedPlatformAccountId,
    selectedSourceAccountId,
    selectedCampaignId,
    selectedDateRange,
    accountSummariesLoading,
  } = useContexts();

  const [coveredState, setCoveredState] = useState<string>('all');

  const variables: QueryGetIntentsArgs = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    campaignIds: selectedCampaignId ? [selectedCampaignId] : null,
    dateRange: selectedDateRange,
    sortBy: {
      field: IntentsSortEnum.Impressions,
      desc: true,
    },
    searchValue: '',
    page: 1,
    pageSize: 25,
    aligned: coveredState === 'covered' ? true : coveredState === 'uncovered' ? false : undefined,
  };

  const skip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange;
  const { intentsLoading, getIntents } = useGetIntents(skip, variables);

  // Get assessment availability
  const getAssessmentAvailabilitySkip = !selectedPlatformAccountId;
  const getAssessmentAvailabilityVariables: GetAssessmentAvailabilityQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
  };
  const { assessmentAvailabilityLoading, getAssessmentAvailability } = useGetAssesmentAvailability(
    getAssessmentAvailabilitySkip,
    getAssessmentAvailabilityVariables,
  );

  const loadingCoverage =
    !selectedSourceAccountId || accountSummariesLoading || intentsLoading || assessmentAvailabilityLoading;

  const hasData = getAssessmentAvailability?.find(
    ({ customerId }) => customerId === selectedSourceAccountId,
  )?.hasAvailableData;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [
    selectedPlatformAccountId,
    selectedCampaignId,
    selectedSourceAccountId,
    selectedDateRange,
    coveredState,
    getIntents,
    intentsLoading,
  ]);

  if (demoMode) {
    return (
      <Styled.Container data-testid="campaignSummaryView">
        <Box
          sx={{
            backdropFilter: 'blur(10px)',
            position: 'absolute',
            zIndex: 1000,
            height: '100%',
            width: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
          }}
        />
        <Styled.Container className="campaignMotiveMap" data-testid="campaignMotiveMap" sx={{}}>
          <Styled.FlexContainer
            sx={{
              height: '100%',
            }}
          >
            <Styled.CenterContainer>
              <>
                <BubbleChart demoMode />
                <DemoTable />
              </>
            </Styled.CenterContainer>
          </Styled.FlexContainer>
        </Styled.Container>
      </Styled.Container>
    );
  }

  if (loadingCoverage && !selectedDateRange && hasData) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!loadingCoverage && !getIntents?.edges?.length && !hasData) {
    return (
      <Styled.ImageContainer>
        <Styled.Image src={ErrorSVG} alt="Could Not Load Data" />
        <Styled.ImageHeading
          sx={{
            marginTop: '10px',
          }}
        >
          {/* {notEnoughData ? 'Not Enough Data' : 'Could Not Load Data'} */}
          Not Enough Data
        </Styled.ImageHeading>
        <Styled.ImageHeading sx={{ fontWeight: '400' }}>
          {/* {notEnoughData ? ( */}
          <Box>There currently isn’t enough data in this ad account to complete the assessment</Box>

          <Styled.SupportLink
            href="https://motivemetrics.atlassian.net/servicedesk/customer/portal/1/group/1/create/6"
            target="_blank"
          >
            Contact Support
          </Styled.SupportLink>
        </Styled.ImageHeading>
      </Styled.ImageContainer>
    );
  }

  return (
    <Styled.FlexContainer>
      <Styled.CenterContainer>
        {selectedDateRange && selectedDateRange !== 'N/A' && selectedDateRange !== 'No Dates' && (
          <>
            <Box>
              <BubbleChart />
            </Box>
            <IntentsTable coveredState={coveredState} setCoveredState={setCoveredState} />
          </>
        )}
      </Styled.CenterContainer>
    </Styled.FlexContainer>
  );
};

export default ContentGapAnalysis;
