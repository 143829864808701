import { useSearchParams } from 'react-router-dom';
import {
  AccountCircleOutlined,
  InsertChartOutlined,
  Logout,
  ManageAccountsOutlined,
  SettingsOutlined,
  AccountTreeOutlined,
} from '@mui/icons-material';
import { Box, Menu } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { UserProfileMenuRoutes } from '~/core/types/globals';
import { logoutAction } from '~/store/actions/auth.action';
import { useContexts } from '~/store/context/useContext';
import { authUserSelector } from '~/store/selectors/auth.selector';
import { featureFlagUnlock } from '~/core/helpers';

import { SidebarLabels, SidebarLinks } from '../../types';
import * as Styled from './styles';

const SidebarOptions: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { selectedPlatformAccount } = useContexts();
  const user = useSelector(authUserSelector);
  const [searchParams] = useSearchParams();
  const sourceAccountId = searchParams.get('sourceAccountId');
  const accountName = searchParams.get('accountName');

  const adminAccess = user?.IsPlatformAdmin || selectedPlatformAccount?.isAdmin;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logoutAction.request());
    navigate('/auth/sign-in');
  };

  return (
    <Styled.Container data-testid="sidebarOptions">
      <Box>
        <Styled.LinkContainer
          to={`${SidebarLinks.Segments}${sourceAccountId || accountName ? '?' : ''}${
            sourceAccountId ? `sourceAccountId=${sourceAccountId}` : ''
          }${sourceAccountId && accountName ? '&' : ''}${accountName ? `accountName=${accountName}` : ''}`}
        >
          <Styled.ListItemButton selected={!!pathname?.includes('/traffic-gap-analysis')} disableRipple>
            <Styled.ListIcon>
              <AccountTreeOutlined />
            </Styled.ListIcon>
            <Styled.ListItemText primary={SidebarLabels.Segments} />
          </Styled.ListItemButton>
        </Styled.LinkContainer>

        {user?.IsPlatformAdmin &&
          featureFlagUnlock({
            platformAccountId: selectedPlatformAccount?.id,
            email: user?.Email,
            engineerLock: true,
          }) && (
            <Styled.LinkContainer
              to={`${SidebarLinks.Opportunities}${sourceAccountId || accountName ? '?' : ''}${
                sourceAccountId ? `sourceAccountId=${sourceAccountId}` : ''
              }${sourceAccountId && accountName ? '&' : ''}${accountName ? `accountName=${accountName}` : ''}`}
            >
              <Styled.ListItemButton
                data-testid="selectOpportunities"
                selected={!!pathname?.includes('/content-gap-analysis')}
                disableRipple
              >
                <Styled.ListIcon>
                  <InsertChartOutlined />
                </Styled.ListIcon>
                <Styled.ListItemText primary={SidebarLabels.Opportunities} />
              </Styled.ListItemButton>
            </Styled.LinkContainer>
          )}

        {adminAccess && (
          <Styled.LinkContainer to={SidebarLinks.Admin}>
            <Styled.ListItemButton
              data-testid="selectAdmin"
              selected={!!pathname?.includes('/admin/settings')}
              disableRipple
            >
              <Styled.ListIcon>
                <ManageAccountsOutlined />
              </Styled.ListIcon>
              <Styled.ListItemText primary={SidebarLabels.Admin} />
            </Styled.ListItemButton>
          </Styled.LinkContainer>
        )}
        {/* <Styled.AnchorTag href={SidebarLinks.Support} target="_blank" rel="noopener noreferrer">
          <Styled.ListItemButton disableRipple data-testid="selectSupport">
            <Styled.ListIcon>
              <HelpOutline />
            </Styled.ListIcon>
            <Styled.ListItemText primary={SidebarLabels.Support} />
          </Styled.ListItemButton>
        </Styled.AnchorTag> */}
      </Box>
      <Box onClick={handleOpenMenu}>
        <Styled.ListItemButton
          data-testid="selectUserOptions"
          selected={!!pathname.includes('/my-account') || pathname === '/platform/platform-admin-console'}
          disableRipple
        >
          <Styled.ListIcon>
            <AccountCircleOutlined />
          </Styled.ListIcon>
          <Styled.ListItemText primary={user?.FirstName} />
        </Styled.ListItemButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: -70,
          horizontal: 180,
        }}
        open={!!anchorEl}
        onClose={handleCloseMenu}
      >
        <Styled.MenuContainer data-testid="userProfileMenu">
          <Styled.MenuLink to={UserProfileMenuRoutes.MyAccount} onClick={handleCloseMenu}>
            <Styled.MenuItem data-testid="handleCloseUserMenuButton">
              <SettingsOutlined />
              <Styled.MenuText>My Account</Styled.MenuText>
            </Styled.MenuItem>
          </Styled.MenuLink>
          <Styled.MenuItem data-testid="handleLogoutButton" onClick={handleLogout}>
            <Logout />
            <Styled.MenuText>Sign Out</Styled.MenuText>
          </Styled.MenuItem>
        </Styled.MenuContainer>
      </Menu>
    </Styled.Container>
  );
};

export default SidebarOptions;
