'use client';

import { styled } from '@mui/material/styles';
import { Select, MenuItem, Box, Button } from '@mui/material';

import { KeywordSchema } from '~/core/types/graphql.types';

interface KeywordSelectProps {
  keywords: KeywordSchema[];
  selectedKeyword: KeywordSchema | null;
  onSelect: (keyword: KeywordSchema | null) => void;
  onClear: () => void;
}

const StyledSelect = styled(Select)(({ theme }) => ({
  fontSize: '1rem',
  height: 35,
  marginRight: theme.spacing(1),
}));

const KeywordSelector = ({ keywords, selectedKeyword, onSelect, onClear }: KeywordSelectProps) => (
  <Box display="flex" alignItems="center">
    <StyledSelect
      value={selectedKeyword}
      onChange={(e) => onSelect(e.target.value as KeywordSchema)}
      displayEmpty
      renderValue={(value: KeywordSchema) => value?.keyword_text || 'All Keywords'}
    >
      <MenuItem value={null}>All Keywords</MenuItem>

      {keywords.map((keyword) => (
        <MenuItem key={keyword.keyword_text} value={keyword as any}>
          {keyword.keyword_text} ({keyword.keyword_match_type})
        </MenuItem>
      ))}
    </StyledSelect>
    {selectedKeyword && (
      <Button variant="text" onClick={onClear} size="small">
        Clear
      </Button>
    )}
  </Box>
);

export default KeywordSelector;
