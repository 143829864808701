import { Breadcrumbs as MuiBreadcrumbs, styled } from '@mui/material';

export const Breadcrumbs = styled(MuiBreadcrumbs)({
  height: '36px',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'capitalize',
});

export const BreadcrumbsTypography = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '11px',
  color: '#3e6ad8',
  fontSize: 12,
  letterSpacing: '0.15px',
  fontWeight: 600,
  textDecoration: 'capitalize',
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

export const CurrentBreadcrumbsTypography = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '11px',
  color: 'rgba(33, 33, 52, 0.87)',
  fontSize: 12,
  letterSpacing: '0.15px',
  fontWeight: 'bold',
  cursor: 'default',
  textDecoration: 'capitalize',
});

export const LogoContainer = styled('div')`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(202, 213, 224, 0.45);
  border-radius: 25px;
`;
