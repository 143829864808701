import { Close } from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';

import { Chip } from '~/core/components/shared/Chip';
import { Tooltip } from '~/core/components/shared';

import { KeywordsPreview } from './components/KeywordsPreview';
import { PercentageDisplay } from './components/PercentageDisplay';
import { IntentThemesPreview } from './components/IntentThemesPreview';

const CustomContainer = styled(Box)({
  background: 'rgba(33, 33, 52, 0.04)',
  padding: '10px 12px',
  margin: '0 auto',
  borderBottom: 'none',
  height: '100%',
  minHeight: 'calc(100vh - 150px)',
  overflowX: 'hidden',
});

const HeadingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'center',
});

const Heading = styled('p')`
  flex-grow: 1;
  font-family: Inter;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.15px;
  text-align: left;
  margin: 0;
  color: #4a57bc;
  text-transform: capitalize;
`;

type TrafficSegmentPreviewProps = {
  onSegmentClick: (segmentId?: string) => void;
  segmentId: string;
  totalImpressions?: number;
  segmentImpressions?: number;
  totalIntentThemes?: number;
  segmentName: string;
};

const TrafficSegmentPreview: React.FC<TrafficSegmentPreviewProps> = ({
  onSegmentClick,
  segmentId,
  totalImpressions,
  segmentImpressions,
  totalIntentThemes,
  segmentName,
}) => (
  <CustomContainer>
    <Box>
      <HeadingContainer>
        <Heading>
          {segmentName || (
            <>
              <Tooltip title="This can happen when there is keyword activity, but not enough to extract Search Intent to define a Segment or its Themes.">
                <div>
                  <span style={{ marginRight: '8px' }}>Segment</span>
                  <Chip label="Insufficient Traffic" color="error" size="small" variant="outlined" />
                </div>
              </Tooltip>
            </>
          )}
        </Heading>
        <IconButton onClick={() => onSegmentClick('close')}>
          <Close
            sx={{
              fontSize: 20,
            }}
          />
        </IconButton>
      </HeadingContainer>
      <PercentageDisplay totalImpressions={totalImpressions} segmentImpressions={segmentImpressions} />
      {segmentId !== 'Others' && (
        <>
          {/* Keywords Display */}
          <KeywordsPreview segmentId={segmentId} />

          {/* Bubble Chart Display */}
          <IntentThemesPreview segmentId={segmentId} totalIntentThemes={totalIntentThemes} segmentName={segmentName} />
        </>
      )}
    </Box>
  </CustomContainer>
);

export default TrafficSegmentPreview;
