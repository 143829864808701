import { useMemo, useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, styled, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { Breadcrumbs, Button, DateSelector } from '~/core/components/shared';
import { useContexts } from '~/store/context/useContext';
import { SelectorMenu } from '~/core/components/shared';
import { KeywordSchema } from '~/core/types/graphql.types';

import { IntentThemeAnalysis } from '../IntentThemeAnalysis/IntentThemeAnalysis';
import { KeywordBreakdown } from './components/KeywordBreakdown';

const Header = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: rgba(33, 33, 52, 0.87);
  text-transform: capitalize;
`;

export const SegmentGapPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange } = useContexts();
  const [selectedKeyword, setSelectedKeyword] = useState<KeywordSchema | null>(null);
  const [keywords, setKeywords] = useState<KeywordSchema[]>([]);

  const getQueryValue = useCallback(
    (selected: string | null, queryParam: string) => selected || searchParams.get(queryParam),
    [searchParams],
  );
  const platformAccountId = useMemo(
    () => getQueryValue(selectedPlatformAccountId?.toString(), 'platformAccountId') as string,
    [getQueryValue, selectedPlatformAccountId] as const,
  );
  const sourceAccountId =
    useMemo(
      () => getQueryValue(selectedSourceAccountId, 'sourceAccountId'),
      [getQueryValue, selectedSourceAccountId],
    ) || searchParams.get('sourceAccountId');

  const dateRange =
    useMemo(() => getQueryValue(selectedDateRange, 'dateRange'), [getQueryValue, selectedDateRange]) ||
    searchParams.get('dateRange');

  const adGroupId = useMemo(() => searchParams.get('adGroupId'), [searchParams]);
  const segmentId = useMemo(() => searchParams.get('segmentId'), [searchParams]);
  const segmentName = useMemo(() => searchParams.get('segmentName'), [searchParams]);

  const importedState = useMemo(() => {
    const params = { platformAccountId, sourceAccountId, dateRange, adGroupId, segmentName, segmentId };
    return Object.entries(params).reduce((acc: any, [key, value]) => {
      if (value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }, [platformAccountId, sourceAccountId, dateRange, searchParams, adGroupId, segmentName, segmentId]);

  const handleClick = (event: any) => {
    event.stopPropagation();
    navigate(-1);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'none !important',
        }}
      >
        <SelectorMenu />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          backgroundColor: '#fff',
          borderBottom: '1px solid rgba(0,0,0,.12)',
        }}
      >
        <Breadcrumbs
          importedState={importedState}
          headerStyle={{
            borderBottom: 'none',
          }}
        />
        <DateSelector />
      </Box>
      <Box margin="0px 24px">
        <Box flex="1" display="flex" gap="22px" padding="17px 0px" alignItems="center">
          <Button
            label="All Segments"
            variant="outlined"
            startIcon={<ArrowBack />}
            color="secondary"
            onClick={handleClick}
          />
          <Header>{segmentName || 'Intent Segment'}</Header>
        </Box>

        <KeywordBreakdown
          selectedKeyword={selectedKeyword}
          setSelectedKeyword={setSelectedKeyword}
          setKeywords={setKeywords}
        />
        <IntentThemeAnalysis
          selectedKeyword={selectedKeyword}
          setSelectedKeyword={setSelectedKeyword}
          keywords={keywords}
        />
      </Box>
    </Box>
  );
};

export default SegmentGapPage;
