import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetAssetRecommendations } from '~/core/services/graphql/assets/hooks';
import { rsaAdPulse } from '~/core/services/rest/adFactory.service';
import { successNotificationMessage, errorNotificationMessages } from '~/core/constants/notificatonMessages.constants';
import { IntentsSchema } from '~/core/types/graphql.types';
import { useContexts } from '~/store/context/useContext';
import { notificationAction } from '~/store/actions/notifications.action';

import { ReviewStep } from './components/ReviewStep';
import { AssetsStep } from './components/AssetsStep';
import { AssetType, GroupedAssets, RemovedAssets } from './types';

interface DeployContentModalProps {
  open: boolean;
  onClose: () => void;
  selectedIntents: IntentsSchema[];
  onRemoveIntent: (intentId: string) => void;
  onDeploySuccess: () => void;
}

const DeployContentModal: React.FC<DeployContentModalProps> = ({
  open,
  onClose,
  selectedIntents,
  onRemoveIntent,
  onDeploySuccess,
}) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState<'review' | 'loading' | 'assets'>('review');
  const [groupedAssets, setGroupedAssets] = useState<GroupedAssets>({});
  const [removedAssets, setRemovedAssets] = useState<RemovedAssets>({});
  const [deploymentName, setDeploymentName] = useState('');
  const [nameError, setNameError] = useState('');
  const MAX_NAME_LENGTH = 70;
  const { selectedPlatformAccountId, selectedDateRange, selectedSourceAccountId } = useContexts();

  const handleClose = () => {
    onClose();
    setStep('review');
    setGroupedAssets({});
    setRemovedAssets({});
    setDeploymentName('');
    setNameError('');
  };

  const handleRemoveAsset = (campaignId: string, adGroupId: string, assetText: string, assetType: AssetType) => {
    setRemovedAssets((prev) => {
      const updated = { ...prev };
      const assetArray = assetType === 'HEADLINE' ? 'headlines' : 'descriptions';

      if (!updated[campaignId]) {
        updated[campaignId] = {};
      }
      if (!updated[campaignId][adGroupId]) {
        updated[campaignId][adGroupId] = {
          headlines: [],
          descriptions: [],
        };
      }

      // Check if asset is already removed to prevent duplicates
      if (!updated[campaignId][adGroupId][assetArray].includes(assetText)) {
        updated[campaignId][adGroupId][assetArray] = [...updated[campaignId][adGroupId][assetArray], assetText];
      }

      return updated;
    });
  };

  const handleRestoreAsset = (campaignId: string, adGroupId: string, assetText: string, assetType: AssetType) => {
    setRemovedAssets((prev) => {
      const updated = { ...prev };
      const assetArray = assetType === 'HEADLINE' ? 'headlines' : 'descriptions';

      if (!updated[campaignId]?.[adGroupId]?.[assetArray]) return prev;

      updated[campaignId][adGroupId][assetArray] = updated[campaignId][adGroupId][assetArray].filter(
        (text) => text !== assetText,
      );

      // Only clean up the specific asset array if it's empty
      if (updated[campaignId][adGroupId][assetArray].length === 0) {
        const otherArray = assetType === 'HEADLINE' ? 'descriptions' : 'headlines';
        // Only remove the adGroup if both arrays are empty
        if (updated[campaignId][adGroupId][otherArray]?.length === 0) {
          delete updated[campaignId][adGroupId];
          // Only remove the campaign if it has no more adGroups
          if (Object.keys(updated[campaignId]).length === 0) {
            delete updated[campaignId];
          }
        }
      }

      return updated;
    });
  };

  const isAssetRemoved = (campaignId: string, adGroupId: string, assetText: string, assetType: string) => {
    const assetArray = assetType === 'HEADLINE' ? 'headlines' : 'descriptions';
    return removedAssets[campaignId]?.[adGroupId]?.[assetArray]?.includes(assetText) ?? false;
  };

  const getRemovedAssetCount = (campaignId: string, adGroupId: string) => ({
    headlines: removedAssets[campaignId]?.[adGroupId]?.headlines?.length || 0,
    descriptions: removedAssets[campaignId]?.[adGroupId]?.descriptions?.length || 0,
  });

  const handleDeploymentNameChange = (value: string) => {
    setDeploymentName(value);
    if (!value.trim()) {
      setNameError('Please enter a deployment name');
    } else if (value.length > MAX_NAME_LENGTH) {
      setNameError(`Name must be ${MAX_NAME_LENGTH} characters or less`);
    } else {
      setNameError('');
    }
  };

  const { assetRecommendationsLoading, getAssetRecommendations } = useGetAssetRecommendations(
    !open || step !== 'loading',
    {
      platformAccountId: selectedPlatformAccountId || null,
      customerIds: [selectedSourceAccountId || ''],
      intentIds: selectedIntents.map((intent) => intent.id),
      dateRange: selectedDateRange || '',
    },
  );

  useEffect(() => {
    if (step === 'loading' && !assetRecommendationsLoading && getAssetRecommendations) {
      const newGroupedAssets: GroupedAssets = {};

      getAssetRecommendations.forEach((asset) => {
        if (!asset.adGroupId || !asset.campaignId) return;

        const matchingIntent = selectedIntents.find((intent) => intent.adGroupId === asset.adGroupId);

        if (!matchingIntent) return;

        // Initialize campaign if it doesn't exist
        if (!newGroupedAssets[asset.campaignId]) {
          newGroupedAssets[asset.campaignId] = {
            campaignName: matchingIntent.campaignName,
            campaignId: asset.campaignId,
            adGroups: {},
          };
        }

        // Initialize ad group if it doesn't exist
        if (!newGroupedAssets[asset.campaignId].adGroups[asset.adGroupId]) {
          newGroupedAssets[asset.campaignId].adGroups[asset.adGroupId] = {
            adGroupName: matchingIntent.adGroupName,
            adGroupId: asset.adGroupId,
            assets: {
              headlines: [],
              descriptions: [],
            },
          };
        }

        if (asset.assetType === 'HEADLINE') {
          newGroupedAssets[asset.campaignId].adGroups[asset.adGroupId].assets.headlines.push(asset);
        } else if (asset.assetType === 'DESCRIPTION') {
          newGroupedAssets[asset.campaignId].adGroups[asset.adGroupId].assets.descriptions.push(asset);
        }
      });

      setGroupedAssets(newGroupedAssets);
      setStep('assets');
    }
  }, [assetRecommendationsLoading, getAssetRecommendations, selectedIntents, step]);

  const handleFindAssets = () => {
    setStep('loading');
  };

  const handleDeploy = async () => {
    const date = new Date();
    const formattedDate = date
      .toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      })
      .replace(/\//g, '');

    const deploymentData = {
      customerId: selectedSourceAccountId || '',
      taskName: `${deploymentName}_mm_${formattedDate}`,
      assets: Object.entries(groupedAssets).map(([campaignId, campaign]) => ({
        campaignId,
        campaignName: campaign.campaignName,
        adGroups: Object.entries(campaign.adGroups).map(([adGroupId, adGroup]) => {
          const assets = [
            ...adGroup.assets.headlines
              .filter((asset) => !isAssetRemoved(campaignId, adGroupId, asset.assetText, 'HEADLINE'))
              .map((asset) => ({
                text: asset.assetText,
                assetType: 'headline' as const,
              })),
            ...adGroup.assets.descriptions
              .filter((asset) => !isAssetRemoved(campaignId, adGroupId, asset.assetText, 'DESCRIPTION'))
              .map((asset) => ({
                text: asset.assetText,
                assetType: 'description' as const,
              })),
          ];
          return {
            adGroupId,
            adGroupName: adGroup.adGroupName,
            assets,
          };
        }),
      })),
    };

    try {
      await rsaAdPulse(deploymentData);
      dispatch(notificationAction.success({ message: successNotificationMessage.successDeployContent }));
      onDeploySuccess();
      handleClose();
    } catch (error) {
      // Log error and show error notification to user
      dispatch(
        notificationAction.failure({
          message: errorNotificationMessages.errorDeployContent,
          severity: 'error',
        }),
      );
    }
  };

  const renderContent = () => {
    if (step === 'loading') {
      return (
        <Box display="flex" flexDirection="column" alignItems="center" py={4}>
          <CircularProgress size={48} />
          <Typography variant="h6" mt={2}>
            Finding Assets...
          </Typography>
        </Box>
      );
    }

    if (step === 'assets') {
      return (
        <AssetsStep
          groupedAssets={groupedAssets}
          deploymentName={deploymentName}
          nameError={nameError}
          maxNameLength={MAX_NAME_LENGTH}
          onDeploymentNameChange={handleDeploymentNameChange}
          isAssetRemoved={isAssetRemoved}
          handleRemoveAsset={handleRemoveAsset}
          handleRestoreAsset={handleRestoreAsset}
          getRemovedAssetCount={getRemovedAssetCount}
        />
      );
    }

    // Group intents by campaign
    const groupedIntents = selectedIntents.reduce(
      (acc, intent) => {
        if (!acc[intent.campaignId]) {
          acc[intent.campaignId] = {
            campaignName: intent.campaignName,
            campaignId: intent.campaignId,
            intents: [],
          };
        }
        acc[intent.campaignId].intents.push(intent);
        return acc;
      },
      {} as {
        [campaignId: string]: {
          campaignName: string;
          campaignId: string;
          intents: IntentsSchema[];
        };
      },
    );

    return <ReviewStep groupedIntents={groupedIntents} onRemoveIntent={onRemoveIntent} />;
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          Deploy Content
          <IconButton onClick={handleClose} size="small">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {step !== 'review' && (
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              marginLeft: '16px',
            }}
          >
            <Button onClick={() => setStep('review')} color="inherit">
              Back
            </Button>
          </Box>
        )}
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        {step === 'review' && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleFindAssets}
            disabled={selectedIntents.length === 0}
          >
            Find Assets
          </Button>
        )}
        {step !== 'review' && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeploy}
            disabled={!deploymentName.trim() || deploymentName.length > MAX_NAME_LENGTH || step === 'loading'}
          >
            Deploy Content
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeployContentModal;
