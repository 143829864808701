import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, useMediaQuery, Skeleton, Slide, styled, useTheme } from '@mui/material';

import SankeyChart from '~/core/components/shared/SankeyVisual/SankeyV2';
import { useGetAdGroupSummaries, useGetTrafficSegmentsV2 } from '~/core/services/graphql/adGroups/hooks';
import {
  TrafficSegmentSortEnum,
  QueryGetAdGroupSummariesArgs,
  QueryGetTrafficSegmentsV2Args,
} from '~/core/types/graphql.types';
import { useContexts } from '~/store/context/useContext';
import { Breadcrumbs, DateSelector, PageHeader, SelectorMenu, Tooltip } from '~/core/components/shared';
import { numberWithCommas } from '~/core/helpers';

import { createNodesAndLinks } from './helpers';
import { TrafficSegmentPreview } from '../TrafficSegmentPreview';
import { InfoIcon } from '../ContentGapAnalysis/Tables/styles';

const MetricContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  background: '#fff',
  width: '160px',
  padding: '10px',
  borderRadius: '6px',
});

const Label = styled('span')({
  fontSize: '10px',
  marginTop: '4px',
  width: '100%',
  color: '#6D6D6D',
});

const Metric = styled('span')({
  fontSize: '16px',
  width: '100%',
  fontWeight: 'bold',
});

const SegmentsView = () => {
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange } = useContexts();
  const [searchParams, setSearchParams] = useSearchParams();
  const [nodes, setNodes] = useState([]);
  const [links, setLinks] = useState([]);
  const adGroupId = searchParams.get('adGroupId');
  const campaignName = searchParams.get('campaignName');
  const [show, setShow] = useState(true);
  const [selectedSegmentId, setSelectedSegmentId] = useState(null);
  const theme = useTheme();

  const getQueryValue = useCallback(
    (selected: string | null, queryParam: string) => selected || searchParams.get(queryParam),
    [searchParams],
  );
  const platformAccountId = useMemo(
    () => getQueryValue(selectedPlatformAccountId?.toString(), 'platformAccountId') as string,
    [getQueryValue, selectedPlatformAccountId] as const,
  );
  const sourceAccountId =
    useMemo(
      () => getQueryValue(selectedSourceAccountId, 'sourceAccountId'),
      [getQueryValue, selectedSourceAccountId],
    ) || searchParams.get('sourceAccountId');

  const dateRange =
    useMemo(() => getQueryValue(selectedDateRange, 'dateRange'), [getQueryValue, selectedDateRange]) ||
    searchParams.get('dateRange');

  const adGroupName = useMemo(() => searchParams.get('adGroupName'), [searchParams]);
  const tableType = searchParams.get('tableType');

  const importedState = useMemo(() => {
    const params = { platformAccountId, sourceAccountId, dateRange, adGroupName, tableType };
    return Object.entries(params).reduce((acc: any, [key, value]) => {
      if (value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }, [platformAccountId, sourceAccountId, dateRange, searchParams, adGroupName]);

  const adGroupSummariesSkip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange;
  const adGroupSummariesVariables: QueryGetAdGroupSummariesArgs = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: [selectedSourceAccountId],
    dateRange: selectedDateRange,
    filterField: {
      filters: [
        {
          operation: 'eq',
          fieldName: 'ad_group_id',
          value: adGroupId,
        },
      ],
    },
    page: 1,
    pageSize: 1,
  };

  const { adGroupSummariesLoading, getAdGroupSummaries } = useGetAdGroupSummaries(
    adGroupSummariesSkip,
    adGroupSummariesVariables,
  );

  const totalKeywords = getAdGroupSummaries?.edges?.[0]?.totalKeywords;
  const keywordsWithTraffic = getAdGroupSummaries?.edges?.[0]?.keywordsWithTraffic;
  const impressions = getAdGroupSummaries?.edges?.[0]?.impressions;
  const totalEligibleImpressions = getAdGroupSummaries?.edges?.[0]?.totalEligibleImpressions;

  const trafficSegmentsSkip =
    !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange || !adGroupId;
  const trafficSegmentsV2Variables: QueryGetTrafficSegmentsV2Args = {
    platformAccountId: selectedPlatformAccountId,
    customerId: selectedSourceAccountId,
    dateRange: selectedDateRange,
    sortBy: {
      field: TrafficSegmentSortEnum.Impressions,
      desc: true,
    },
    filterField: {
      filters: [
        {
          operation: 'eq',
          fieldName: 'ad_group_id',
          value: adGroupId,
        },
      ],
    },
    page: 1,
    pageSize: 9999,
  };

  const { trafficSegmentsLoading, getTrafficSegments } = useGetTrafficSegmentsV2(
    trafficSegmentsSkip,
    trafficSegmentsV2Variables,
  );

  const trafficSegments = getTrafficSegments?.edges;

  const onSegmentClick = (segmentId?: string) => {
    // eslint-disable-next-line
    setSelectedSegmentId(segmentId);
    if (segmentId) {
      searchParams.set('adGroupName', adGroupName);
      searchParams.set('segmentId', segmentId);
      setSearchParams(searchParams);
    }
    return segmentId === 'close' ? setShow(false) : setShow(true);
  };

  let newNodes: any[] = [];
  let newLinks: any[] = [];
  let nodeAndLinks: any;

  useEffect(() => {
    if (trafficSegments?.length > 0) {
      nodeAndLinks = createNodesAndLinks(trafficSegments, null);
      newNodes = nodeAndLinks?.nodes;
      newLinks = nodeAndLinks?.links;
      setNodes(newNodes);
      setLinks(newLinks);
    }
  }, [trafficSegments, nodeAndLinks]);

  useEffect(() => {
    if (!adGroupId) return;
    searchParams.set('adGroupId', adGroupId);
  }, [adGroupId]);

  useEffect(() => {
    const cachedSegmentId = searchParams.get('segmentId');

    if (!selectedSegmentId && !cachedSegmentId) {
      if (!searchParams.get('segmentId') && trafficSegments?.length > 0) {
        searchParams.set('segmentId', trafficSegments?.[0]?.segmentId);
        setSearchParams(searchParams);
      }
      setSelectedSegmentId(trafficSegments?.[0]?.segmentId);
      return;
    }

    if (!selectedSegmentId && cachedSegmentId) {
      setSelectedSegmentId(cachedSegmentId);
      return;
    }

    searchParams.set('segmentId', selectedSegmentId);
  }, [selectedSegmentId, trafficSegments]);

  const loading = adGroupSummariesLoading || trafficSegmentsLoading || !selectedDateRange;

  const segmentImpressions = nodes?.find((segment) => segment?.id === selectedSegmentId)?.impressions;

  return (
    <div
      style={{
        background: '#fff',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'none !important',
        }}
      >
        <SelectorMenu />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          borderBottom: '1px solid rgba(0,0,0,.12)',
        }}
      >
        <Breadcrumbs
          importedState={importedState}
          headerStyle={{
            borderBottom: 'none',
          }}
        />
        <DateSelector />
      </Box>
      <PageHeader
        sx={{
          backgroundColor: '#F0F0F3',
          display: 'flex',
          flexDirection: useMediaQuery(theme.breakpoints.down('md')) ? 'column' : 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <Box
            style={{
              margin: '0',
              fontSize: '20px',
              width: '100%',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                display: 'inline',
                fontSize: '20px',
                fontWeight: 'bold',
                lineHeight: 1.6,
                letterSpacing: '0.15px',
                textAlign: 'left',
                color: 'rgba(33, 33, 52, 0.87)',
              }}
            >
              {adGroupName}

              <Tooltip title={`In Campaign: ${campaignName}`} placement="right">
                <InfoIcon
                  sx={{
                    color: '#bdbdbd',
                    position: 'absolute',
                    marginTop: '5px',
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
        </div>
        <div
          style={{
            width: useMediaQuery(theme.breakpoints.down('md')) ? '100%' : '70%',
            marginTop: useMediaQuery(theme.breakpoints.down('md')) ? '10px' : '0',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '20px',
            paddingLeft: '20px',
          }}
        >
          <MetricContainer>
            <Metric>
              {loading ? (
                <Skeleton variant="text" width="60px" height={20} />
              ) : typeof totalKeywords === 'number' ? (
                numberWithCommas(totalKeywords)
              ) : (
                '-'
              )}
            </Metric>
            <Label>Total Keywords</Label>
          </MetricContainer>
          <MetricContainer>
            <Metric>
              {loading ? (
                <Skeleton variant="text" width="60px" height={20} />
              ) : typeof keywordsWithTraffic === 'number' ? (
                numberWithCommas(keywordsWithTraffic)
              ) : (
                '-'
              )}
            </Metric>
            <Label>Keywords with Traffic</Label>
          </MetricContainer>
          <MetricContainer>
            <Metric>
              {loading ? (
                <Skeleton variant="text" width="60px" height={20} />
              ) : typeof impressions === 'number' ? (
                numberWithCommas(impressions)
              ) : (
                '-'
              )}
            </Metric>
            <Label>Impressions</Label>
          </MetricContainer>
          <MetricContainer>
            <Metric>
              {loading ? (
                <Skeleton variant="text" width="60px" height={20} />
              ) : typeof totalEligibleImpressions === 'number' ? (
                numberWithCommas(totalEligibleImpressions)
              ) : (
                '-'
              )}
            </Metric>
            <Label>Total Eligible Impressions</Label>
          </MetricContainer>
        </div>
      </PageHeader>
      <div className="segments-view__content">
        <Box
          sx={{
            margin: '0 auto',
            display: 'flex',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              minHeight: 'calc(100vh - 150px)',
              maxHeight: 'calc(100vh - 150px)',
              overflow: 'hidden',
            }}
          >
            <Box
              px={2}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '15px',
                width: '100%',
              }}
            >
              {!trafficSegments || trafficSegmentsLoading ? (
                <>
                  <Skeleton variant="text" width="295px" height={20} />
                  <Skeleton variant="text" width="140px" height={20} />
                  <Skeleton variant="text" width="295px" height={20} />
                </>
              ) : (
                <>
                  <Metric>{trafficSegments?.length} Intent Segments</Metric>
                  <span
                    style={{
                      flexGrow: 0,
                      fontSize: '12px',
                      lineHeight: 1.67,
                      letterSpacing: '0.4px',
                      textAlign: 'left',
                      color: 'rgba(33, 33, 52, 0.87)',
                    }}
                  >
                    Generated from {numberWithCommas(totalKeywords)} Keywords
                  </span>

                  {!trafficSegmentsLoading && trafficSegments?.length === 0 && (
                    <Box
                      sx={{
                        height: '700px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                    >
                      No data available
                    </Box>
                  )}

                  {/* <Box sx={{ width: 300 }}>
                    <RangeSlider
                      min={1}
                      max={trafficSegments?.length}
                      setMaxNodes={setMaxNodes}
                      setMinNodes={setMinNodes}
                    />
                  </Box> */}
                </>
              )}
            </Box>
            {/* <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '12px',
                  padding: '30px',
                }}
              >
                <a href="#">View Keywords with No Traffic</a>
              </Box> */}
            <Box
              sx={{
                display: 'flex',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  width: show ? '70%' : '100%',
                }}
              >
                {trafficSegments?.length > 0 && (
                  <Box
                    sx={{
                      paddingLeft: '10px',
                      width: show ? '140%' : '100%',
                    }}
                  >
                    <SankeyChart
                      nodes={nodes}
                      links={links}
                      onSegmentClick={onSegmentClick}
                      selectedSegmentId={selectedSegmentId}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          {trafficSegments?.length && show && selectedSegmentId !== null && (
            <Box
              sx={{
                minWidth: '400px',
                maxWidth: '400px',
                overflowY: 'auto',
                height: '100%',
                maxHeight: 'calc(100vh - 151px)',
              }}
            >
              <Slide direction="left" in={show}>
                <Box
                  sx={{
                    borderLeft: '1px solid rgba(0,0,0,.12)',
                  }}
                >
                  <TrafficSegmentPreview
                    segmentName={
                      trafficSegments?.find((segment) => segment?.segmentId === selectedSegmentId)?.segmentName
                    }
                    onSegmentClick={onSegmentClick}
                    segmentId={selectedSegmentId}
                    totalImpressions={impressions}
                    segmentImpressions={segmentImpressions}
                    totalIntentThemes={
                      trafficSegments?.find((segment) => segment?.segmentId === selectedSegmentId)?.intentThemes
                    }
                  />
                </Box>
              </Slide>
            </Box>
          )}
        </Box>
        {/* {trafficSegmentsLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '600px',
            }}
          >
            <CircularProgress />
          </Box>
        )} */}
      </div>
    </div>
  );
};

export default SegmentsView;
