'use client';

import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import cloud from 'd3-cloud';
import { styled } from '@mui/material/styles';
import { Tooltip, Typography, Box, Paper } from '@mui/material';

import { IntentThemesSchema } from '~/core/types/graphql.types';

import { CHART_COLORS } from '../SegmentGap/constants';

interface WordCloudProps {
  words?: IntentThemesSchema[];
  width?: number;
  height?: number;
  minFontSize?: number;
  maxFontSize?: number;
  onWordClick: (intentTheme: IntentThemesSchema) => void;
  selectedTheme: IntentThemesSchema | null;
}

const StyledSvg = styled('svg')({
  width: '100%',
  height: '100%',
  '& g:hover text': {
    opacity: 0.3,
  },
});

const StyledText = styled('text')(() => ({
  fontFamily: 'Arial',
  userSelect: 'none',
  cursor: 'pointer',
  transition: 'opacity 0.3s',
  '&:hover': {
    opacity: '1 !important',
  },
}));

const WordCloud = ({
  words = [],
  width = 800,
  height = 400,
  minFontSize = 12,
  maxFontSize = 80,
  onWordClick,
  selectedTheme,
}: WordCloudProps) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [layout, setLayout] = useState<Array<cloud.Word & IntentThemesSchema>>([]);

  useEffect(() => {
    if (!words || words.length === 0) {
      setLayout([]);
      return;
    }

    const scores = words.map((w) => w.opportunityScore);
    const minScore = Math.min(...scores);
    const maxScore = Math.max(...scores);

    // Use linear scale for better size distribution
    const fontScale = d3.scaleLinear().domain([minScore, maxScore]).range([minFontSize, maxFontSize]);

    const cloudLayout = cloud()
      .size([width, height])
      .words(
        words.map((w) => ({
          ...w,
          text: w.theme,
          size: fontScale(w.opportunityScore),
        })),
      )
      .padding(5)
      .rotate(() => 0)
      .fontSize((d) => d.size)
      .on('end', (cloudWords) => {
        const wordsWithData = cloudWords.map((w, i) => ({
          ...words[i],
          ...w,
        }));
        setLayout(wordsWithData);
      });

    cloudLayout.start();
  }, [words, width, height, minFontSize, maxFontSize]);

  const handleSvgClick = (event: React.MouseEvent<SVGSVGElement>) => {
    // If the click is directly on the SVG (background) and not on a text element
    if (event.target === event.currentTarget || (event.target as Element).tagName === 'g') {
      onWordClick(null);
    }
  };

  if (!words || words.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height }}>
        <Typography variant="body1">No words to display</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ position: 'relative', width: '100%', textTransform: 'capitalize' }}>
      <StyledSvg
        ref={svgRef}
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMidYMid meet"
        onClick={handleSvgClick}
      >
        <g transform={`translate(${width / 2},${height / 2})`}>
          {layout.map((word, index) => (
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'transparent',
                    boxShadow: 'none',
                    padding: 0,
                  },
                },
                popper: {
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'transparent',
                      textTransform: 'capitalize',
                    },
                  },
                },
              }}
              key={word.text}
              title={
                <Paper elevation={3} sx={{ p: 2, minWidth: 240 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    {word.text}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Total Impressions: {word.impressions.toLocaleString()}
                  </Typography>
                </Paper>
              }
            >
              <>
                <StyledText
                  fontSize={word.size}
                  textAnchor="middle"
                  transform={`translate(${word.x},${word.y})`}
                  onClick={() => onWordClick(word as any)}
                  style={{
                    opacity: selectedTheme ? (selectedTheme?.theme === word.theme ? 1 : 0.3) : 1,
                    fill: CHART_COLORS[index % CHART_COLORS.length],
                  }}
                >
                  {word.theme}
                </StyledText>
              </>
            </Tooltip>
          ))}
        </g>
      </StyledSvg>
    </Box>
  );
};

export default WordCloud;
