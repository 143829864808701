import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AuthContainer } from '~/core/components/layouts/AuthContainer';
import { Button, Input } from '~/core/components/shared';
import { ConfirmEmailForm } from '~/core/types/auth.types';
import { logoutAction, resetAuthSuccessFlagAction, verifyNewEmailAction } from '~/store/actions/auth.action';
import { platformAccountsAction } from '~/store/actions/platformAccounts.action';
import { authErrorSelector, authLoadingSelector } from '~/store/selectors/auth.selector';

import * as Styled from './styles';

const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { control, handleSubmit, watch } = useForm<ConfirmEmailForm>({ defaultValues: { confirmationCode: '' } });

  const [code, setCode] = useState<string>('');
  const confirmationCode = watch('confirmationCode');

  const confirmationPasswordError = useSelector(authErrorSelector);
  const isLoading = useSelector(authLoadingSelector);

  const onLogout = () => {
    dispatch(logoutAction.request());
    navigate('../sign-in', { replace: true });
  };

  const onVerifyNewEmail = ({ confirmationCode }: ConfirmEmailForm) => {
    dispatch(verifyNewEmailAction.request({ confirmationCode }));
    setCode(confirmationCode);
  };

  useEffect(() => {
    if (!confirmationPasswordError && code && !isLoading) {
      dispatch(resetAuthSuccessFlagAction());
      dispatch(platformAccountsAction.failure(''));
      navigate('../../traffic-gap-analysis', { replace: true });
    }
  }, [confirmationPasswordError, navigate, code, dispatch, isLoading]);

  return (
    <AuthContainer data-testid="authContainer">
      <Typography align="center" fontSize={24}>
        Confirm email
      </Typography>
      <form onSubmit={handleSubmit(onVerifyNewEmail)} data-testid="confirmEmailForm">
        <Styled.SignUpFormContent disableGutters>
          <Controller
            name="confirmationCode"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                data-testid="confirmCodeInput"
                label="Confirmation code"
                value={value}
                onChange={onChange}
                helperText={confirmationPasswordError}
                error={!!confirmationPasswordError}
                autoComplete="off"
              />
            )}
          />
          <Button
            data-testid="submitButton"
            type="submit"
            label="Confirm Email"
            fullWidth
            variant="contained"
            size="large"
            disabled={isLoading || confirmationCode.length !== 6}
          />
        </Styled.SignUpFormContent>
      </form>
      <Styled.Link data-testid="logoutLink" onClick={onLogout}>
        Sign in from another account
      </Styled.Link>
    </AuthContainer>
  );
};

export default ConfirmEmail;
