import { Box, IconButton, Typography } from '@mui/material';
import { Trash } from 'lucide-react';

import { IntentsSchema } from '~/core/types/graphql.types';

interface ReviewStepProps {
  groupedIntents: {
    [campaignId: string]: {
      campaignName: string;
      campaignId: string;
      intents: IntentsSchema[];
    };
  };
  onRemoveIntent: (intentId: string) => void;
}

export const ReviewStep: React.FC<ReviewStepProps> = ({ groupedIntents, onRemoveIntent }) => (
  <>
    <Box mb={3}>
      <Typography variant="body1" gutterBottom>
        You have selected {Object.values(groupedIntents).flatMap((c) => c.intents).length} search intents to cover
        content in {Object.keys(groupedIntents).length} campaign
        {Object.keys(groupedIntents).length !== 1 ? 's' : ''} and{' '}
        {
          new Set(
            Object.values(groupedIntents)
              .flatMap((c) => c.intents)
              .map((i) => i.adGroupName),
          ).size
        }{' '}
        ad group
        {new Set(
          Object.values(groupedIntents)
            .flatMap((c) => c.intents)
            .map((i) => i.adGroupName),
        ).size !== 1
          ? 's'
          : ''}
        .
      </Typography>
    </Box>
    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
      Selected Search Intents by Campaign
    </Typography>
    {Object.entries(groupedIntents).map(([campaignId, campaign]) => (
      <Box
        key={campaignId}
        sx={{
          p: 2,
          mb: 2,
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Campaign: {campaign.campaignName} ({campaign.campaignId})
        </Typography>
        {campaign.intents.map((intent) => (
          <Box
            key={intent.id}
            sx={{
              p: 2,
              mb: 1,
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              ml: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              bgcolor: 'background.default',
            }}
          >
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                Search Intent: <span style={{ textTransform: 'capitalize' }}>{intent.name}</span>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Ad Group: {intent.adGroupName} ({intent.adGroupId})
              </Typography>
            </Box>
            <IconButton
              onClick={() => onRemoveIntent(intent.id)}
              size="small"
              sx={{
                color: 'error.main',
                '&:hover': {
                  backgroundColor: 'error.light',
                },
              }}
            >
              <Trash size={18} />
            </IconButton>
          </Box>
        ))}
      </Box>
    ))}
  </>
);
