import { Box, Skeleton } from '@mui/material';

import { StyledCard, StyledCardHeader, StyledCardContent, GridContainer, ChartContainer } from '../../styles';

export const LoadingView = () => (
  <StyledCard>
    <StyledCardHeader
      title={<Skeleton variant="text" width={300} height={32} />}
      // action={
      //   <StatsContainer>
      //     <StatItem>
      //       <Skeleton variant="circular" width={20} height={20} />
      //       <div>
      //         <Skeleton variant="text" width={100} height={16} />
      //         <Skeleton variant="text" width={80} height={20} />
      //       </div>
      //     </StatItem>
      //     <StatItem>
      //       <Skeleton variant="circular" width={20} height={20} />
      //       <div>
      //         <Skeleton variant="text" width={100} height={16} />
      //         <Skeleton variant="text" width={80} height={20} />
      //       </div>
      //     </StatItem>
      //   </StatsContainer>
      // }
    />
    <StyledCardContent>
      <GridContainer>
        <div>
          <Skeleton variant="text" width={250} height={32} sx={{ mb: 2 }} />
          <ChartContainer>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              {/* Circular skeleton for pie chart */}
              <Box
                sx={{
                  width: 200,
                  height: 200,
                  borderRadius: '50%',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                {/* Create pie segments */}
                {[1, 2, 3, 4, 5].map((item) => (
                  <Skeleton
                    key={item}
                    variant="rectangular"
                    sx={{
                      position: 'absolute',
                      width: '50%',
                      height: '50%',
                      transformOrigin: '100% 100%',
                      transform: `rotate(${item * 72}deg)`,
                      animation: 'none',
                      backgroundColor: `rgba(0, 0, 0, ${0.1 + item * 0.05})`,
                    }}
                  />
                ))}
              </Box>
              {/* Labels around the pie */}
              {[1, 2, 3, 4, 5].map((item) => (
                <Skeleton
                  key={`label-${item}`}
                  variant="text"
                  width={60}
                  height={16}
                  sx={{
                    position: 'absolute',
                    transform: `rotate(${item * 72}deg) translateX(120px)`,
                    transformOrigin: 'center',
                  }}
                />
              ))}
            </Box>
          </ChartContainer>
        </div>
        <Box
          sx={{
            height: '100%',
            maxHeight: 400,
            overflow: 'auto',
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 1,
          }}
        >
          <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
            <Box display="flex" justifyContent="space-between">
              <Skeleton variant="text" width={80} height={20} />
              <Box display="flex" gap={2}>
                <Skeleton variant="text" width={100} height={20} />
                <Skeleton variant="text" width={100} height={20} />
                <Skeleton variant="text" width={60} height={20} />
              </Box>
            </Box>
          </Box>
          <Box sx={{ p: 2 }}>
            {[1, 2, 3, 4, 5, 6].map((item) => (
              <Box key={item} display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Skeleton variant="circular" width={12} height={12} />
                  <Skeleton variant="text" width={150} height={20} />
                </Box>
                <Box display="flex" gap={2} alignItems="center">
                  <Skeleton variant="text" width={80} height={20} />
                  <Skeleton variant="text" width={80} height={20} />
                  <Skeleton variant="rectangular" width={60} height={24} sx={{ borderRadius: 1 }} />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </GridContainer>
    </StyledCardContent>
  </StyledCard>
);
