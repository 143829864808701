import { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

// const colorRange = ['#2584DF', '#4A9CE4', '#93CCF0'];

const ZoomableCirclePacking = ({ data, currentDepth }) => {
  const svgRef = useRef(null);
  const [currentFocus, setCurrentFocus] = useState(null);
  const zoomToRef = useRef(null);

  useEffect(() => {
    if (!data || !svgRef.current) return;

    const width = 928;
    const height = width;

    const color = d3
      .scaleLinear()
      .domain([0, 5])
      .range(['#4A9CE4', '#6EB4EA'])
      .domain([1, 2])
      .interpolate(d3.interpolateHcl);

    const pack = (data) =>
      d3.pack().size([width, height]).padding(20)(
        d3
          .hierarchy(data)
          .sum((d) => d.impressions)
          .sort((a, b) => b.impressions - a.impressions),
      );

    const root = pack(data);
    setCurrentFocus(root);

    const svg = d3
      .select(svgRef.current)
      .attr('viewBox', `-${width / 2} -${height / 2} ${width} ${height}`)
      .attr('width', width)
      .attr('height', height)
      .attr('style', `max-width: 100%; height: auto; display: block; cursor: pointer; border-radius: 50%;`);

    svg.selectAll('*').remove();

    const node = svg
      .append('g')
      .selectAll('circle')
      .data(root.descendants().slice(1))
      .join('circle')
      .attr('fill', (d) => (d.children ? color(d.depth) : '#93CCF0'))
      .attr('opacity', 1);

    const label = svg
      .append('g')
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .selectAll('text')
      .data(root.descendants())
      .join('text')
      .style('fill-opacity', (d) => (d !== root ? 1 : 0))
      .style('display', (d) => (d.depth === currentDepth + 1 ? 'inline' : 'none'))
      .text((d) =>
        d.data.name
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '),
      );

    let view;

    const updateLabelSize = (k, label) => {
      label.style('font-size', (d) => {
        const maxFontSize = d.r * k;
        const textLength = d.data.name.length;
        const adjustedFontSize = Math.min(maxFontSize, ((d.r * k) / textLength) * 3);
        return `${adjustedFontSize}px`;
      });
    };

    const zoomTo = (v, label, node) => {
      const k = width / v[2];
      view = v;

      const transform = (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`;

      label.attr('transform', transform).style('opacity', (d) => {
        // Only show labels for current depth level
        const isCurrentDepth = d.depth === currentDepth + 1;
        const size = d.r * k;
        return isCurrentDepth && size >= 10 ? 1 : 0;
      });

      node.attr('transform', transform).attr('r', (d) => Math.max(d.r * k, 1));

      updateLabelSize(k, label);
    };

    const zoom = (event, d) => {
      event.stopPropagation(); // Prevent event bubbling

      const targetFocus = d;

      // Handle root level
      if (targetFocus.depth === 0) {
        if (currentDepth === 1) return; // Already at root level
        currentDepth = 1;
      }

      // Determine zoom direction and target
      const isZoomingOut = currentDepth >= targetFocus.depth;
      let zoomTarget;
      let newDepth;

      if (isZoomingOut) {
        // Zoom out to parent
        const parent = targetFocus.parent || targetFocus;
        zoomTarget = [parent.x, parent.y, parent.r * 2.5];
        newDepth = parent.depth;
      } else {
        // Zoom in to clicked node
        zoomTarget = [targetFocus.x, targetFocus.y, targetFocus.r * 2];
        newDepth = targetFocus.depth;
      }

      // Create transition
      svg
        .transition()
        .duration(event.altKey ? 7500 : 750)
        .tween('zoom', () => {
          const i = d3.interpolateZoom(view, zoomTarget);
          return (t) => {
            zoomTo(i(t), label, node);

            // Update label visibility
            label
              .style('display', (d) => (d.depth === newDepth + 1 ? 'inline' : 'none'))
              .style('opacity', (d) => {
                if (d.depth !== newDepth + 1) return 0;
                const size = d.r * (width / zoomTarget[2]);
                return size >= 10 ? t : 0; // Fade in only visible labels
              });
          };
        });

      // Update depth after transition starts
      currentDepth = newDepth;
    };

    // Initialize zoom reference
    zoomToRef.current = (v) => zoomTo(v, label, node);

    node
      .on('mousemove', (event, d) => {
        const calculateTotals = (node) => ({
          impressions: node.data.impressions || 0,
          totalSearchIntents: node.data.totalSearchIntents || 0,
          totalKeywords: node.data.totalKeywords || 0,
          color: color(node.depth),
        });

        const totals = calculateTotals(d);

        let tooltip = d3.select('.tooltip');
        if (tooltip.empty()) {
          tooltip = d3
            .select('body')
            .append('div')
            .attr('class', 'tooltip')
            .style('position', 'absolute')
            .style('background', '#fff')
            .style('border', '1px solid #ccc')
            .style('padding', '10px')
            .style('border-radius', '8px')
            .style('pointer-events', 'none')
            .style('opacity', 0)
            .style('min-width', '200px')
            .style('box-shadow', '0 4px 8px rgba(0, 0, 0, 0.1)');
        }

        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip
          .html(
            `
            <div style="font-weight: bold; margin-bottom: 5px;">
              <span style="display: inline-block; width: 12px; height: 12px; background-color: ${
                totals.color
              }; border-radius: 4px; "></span>
              ${d.data.name.replace(/\b\w/g, (char) => char.toUpperCase())}
            </div>
              <div style="display: flex; justify-content: space-between;">
                <span>nValue</span> <span>${totals.impressions.toLocaleString()}</span>
              </div>
            </div>
          `,
          )

          // tooltip
          //   .html(
          //     `
          //     <div style="font-weight: bold; margin-bottom: 5px;">
          //       <span style="display: inline-block; width: 12px; height: 12px; background-color: ${
          //         totals.color
          //       }; border-radius: 4px; "></span>
          //       ${d.data.name.replace(/\b\w/g, (char) => char.toUpperCase())}
          //     </div>
          //     <div style="display: flex; justify-content: space-between;">
          //       <span>nValue</span> <span>${totals.impressions.toLocaleString()}</span>
          //     </div>
          //     <div style="display: flex; justify-content: space-between;">
          //       <span>Search Intent</span> <span>${totals.totalSearchIntents.toLocaleString()}</span>
          //     </div>
          //     <div style="display: flex; justify-content: space-between;">
          //       <span>Keywords</span> <span>${totals.totalKeywords.toLocaleString()}</span>
          //     </div>
          //     </div>
          //   `,
          //   )

          .style('left', `${event.pageX + 5}px`)
          .style('top', `${event.pageY - 28}px`);

        d3.select(this).attr('stroke', '#000');

        node
          .transition()
          .duration(300)
          .attr('opacity', (n) => {
            if (n === d || n.parent === d) return 1;
            return 0.3;
          });

        d3.select(this).on('mouseout', () => {
          tooltip.remove();
          d3.select(this).attr('stroke', null);
          node.transition().duration(300).attr('opacity', 1);
        });
      })
      .on('mouseout', () => {
        d3.select('.tooltip').remove();
        d3.select(this).attr('stroke', null);
        node.transition().duration(300).attr('opacity', 1);
      })
      .on('click', (event, d) => {
        if (currentFocus !== d) {
          zoom(event, d);
          event.stopPropagation();
        }
      });

    svg.on('click', (event) => zoom(event, root));
  }, [data]);

  useEffect(() => {
    if (!currentFocus || !zoomToRef.current) return;

    zoomToRef.current([currentFocus.x, currentFocus.y, currentFocus.r * 2]);
  }, [currentFocus]);

  return <svg ref={svgRef} />;
};

export default ZoomableCirclePacking;
