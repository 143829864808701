import { Box, Chip, LinearProgress, Paper, Table, TableRow } from '@mui/material';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Add } from '@mui/icons-material';
import { useQuery } from '@apollo/client';

import { SearchBar, Snackbar, TableCell, TableHead, TablePagination } from '~/core/components/shared';
import { FilterAccessStatusEnum, tablePaginationRowsPerPageOptions } from '~/core/constants/table.constants';
import { accessStatusEnumReuse } from '~/core/helpers';
import useDebounce from '~/core/hooks/useDebounce';
import {
  AccessStatusEnum,
  PlatformAccountUsersSchema,
  PlatformAccountUsersSortableEnum,
} from '~/core/types/graphql.types';
import { SortType } from '~/core/types/table.types';
import { accountUsersAction, inviteAccountUserAction, resetMessageAction } from '~/store/actions/accountUsers.action';
import { useContexts } from '~/store/context/useContext';
import { accountEditSuccessMessageSelector, accountUsersErrorSelector } from '~/store/selectors/accountUsers.selector';
import { usersFromAgencyGql } from '~/core/services/graphql/accountUsers/accountUsers.queries';
import { graphqlClient } from '~/core/services';

import { AddUserDialog } from './components/AddUserDialog';
import { AddUserInput } from './components/AddUserDialog/types';
// import { DeactivateUserDialog } from './components/DeactivateUserDialog';
import * as Styled from './styles';
import { RoleFilterValues } from './types';
import { headerCells } from './userListHeaderCells';

const UserList: React.FC = () => {
  const [order, setOrder] = useState<SortType>(SortType.Asc);
  const [orderBy, setOrderBy] = useState(PlatformAccountUsersSortableEnum.Name);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<string>('');
  const [roleFilterValue] = useState<string>(RoleFilterValues.ANY_ROLE);
  const [statusFilterValue, setStatusFilterValue] = useState<FilterAccessStatusEnum>(FilterAccessStatusEnum.AnyStatus);
  const [addUserDialogOpen, setAddUserDialogOpen] = useState<boolean>(false);
  const { selectedPlatformAccountId, selectedPlatformAccount } = useContexts();

  const dispatch = useDispatch();
  const debouncedSearch = useDebounce(searchValue, 500);
  const errors = useSelector(accountUsersErrorSelector);
  const successMessage = useSelector(accountEditSuccessMessageSelector);

  const userInput = {
    accountId: selectedPlatformAccountId,
    page,
    pageSize: rowsPerPage,
    searchValue: debouncedSearch,
    sortBy: {
      field: orderBy,
      desc: order === SortType.Desc,
    },
    filterSchema: {
      isAdmin: roleFilterValue !== RoleFilterValues.ANY_ROLE ? roleFilterValue === RoleFilterValues.ADMIN : null,
      status: accessStatusEnumReuse(statusFilterValue),
    },
  };

  const {
    data,
    error,
    loading: isLoading,
    refetch,
  } = useQuery(usersFromAgencyGql, {
    client: graphqlClient,
    skip: !selectedPlatformAccountId,
    variables: {
      accountId: selectedPlatformAccountId,
      page,
      pageSize: rowsPerPage,
      searchValue,
      sortBy: {
        field: orderBy,
        desc: order === SortType.Desc,
      },
      filterSchema: {
        isAdmin: roleFilterValue !== RoleFilterValues.ANY_ROLE ? roleFilterValue === RoleFilterValues.ADMIN : null,
        status: accessStatusEnumReuse(statusFilterValue),
      },
    },
  });

  const users = data?.accountUsers?.edges;
  const usersPagination = data?.accountUsers?.pageInfo;

  const handleAddUserDialogOpenChange = () => {
    setAddUserDialogOpen((addUserDialogOpen) => !addUserDialogOpen);
  };

  const handleRequestSort = (event: MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === SortType.Asc;
    setOrder(isAsc ? SortType.Desc : SortType.Asc);
    setOrderBy(property);
    setPage(1);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setPage(1);
  };

  const handleAgenciesListSearch = ({ nativeEvent }: React.KeyboardEvent<HTMLDivElement>) => {
    if (nativeEvent.key === 'Enter' && selectedPlatformAccountId) {
      dispatch(accountUsersAction.request(userInput));
      setPage(1);
    }
  };

  // const handleRoleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   setRoleFilterValue(event.target.value);
  //   setPage(1);
  // };

  const handleStatusFilterChange = (event: any) => {
    setStatusFilterValue(event.target.value);
    setPage(1);
  };

  // const handleEditUserRoleChange =
  //   (selectedUserAccessId: number) =>
  //   ({ target }: SelectChangeEvent<unknown>) => {
  //     dispatch(
  //       updateUserAccessAction.request({
  //         updateUserAccessData: {
  //           accountId: selectedPlatformAccountId,
  //           userAccessId: selectedUserAccessId,
  //           dataToEdit: {
  //             isAdmin: target.value === RoleValues.ADMIN,
  //           },
  //         },
  //         userInviteInput: userInput,
  //       }),
  //     );

  //     setTimeout(() => {
  //       refetch();
  //     }, 500);
  //   };

  const handleAddUserDialogSubmit = (data: AddUserInput) => {
    dispatch(
      inviteAccountUserAction.request({
        inviteAccountUserData: {
          accountId: selectedPlatformAccountId,
          email: data.email,
          isAdmin: data.isAdmin === 'true',
        },
        userInviteInput: userInput,
      }),
    );
    setAddUserDialogOpen(false);

    setTimeout(() => {
      refetch();
    }, 1000);

    setTimeout(() => {
      dispatch(resetMessageAction());
    }, 3000);
  };

  return (
    <Styled.UserListContainer>
      <Paper
        data-testid="userListContainer"
        sx={{
          boxShadow: 'rgba(100, 116, 139, 0.16) 0px 1px 1px, rgba(100, 116, 139, 0.16) 0px 1px 6px',
          borderRadius: '6px',
        }}
      >
        <SearchBar>
          <Styled.SearchContainer>
            <Styled.SearchFilters>
              <Styled.SearchFieldContainer
                data-testid="searchFieldContainer"
                size="small"
                onChange={handleSearchInputChange}
                value={searchValue}
                fullWidth
                onKeyDown={handleAgenciesListSearch}
                placeholder="Search by name"
                label=""
              />
              {/* <Styled.StatusFilterContainer
                fullWidth
                data-testid="handleRoleFilterChange"
                values={Object.values(RoleFilterValues)}
                size="small"
                label="Role"
                value={roleFilterValue}
                onChange={handleRoleFilterChange}
              /> */}
              <Styled.StatusFilterContainer
                fullWidth
                // * Filtering inactive statuses now will remove in the future
                values={Object.values(FilterAccessStatusEnum).filter(
                  (value) => value !== FilterAccessStatusEnum.Inactive,
                )}
                size="small"
                label="User Status"
                value={statusFilterValue}
                onChange={handleStatusFilterChange}
              />
            </Styled.SearchFilters>
            <Styled.InviteButton
              data-testid="handleAddUserDialogOpenChange"
              label="Invite User"
              variant="contained"
              startIcon={<Add />}
              size="small"
              onClick={handleAddUserDialogOpenChange}
            />
          </Styled.SearchContainer>
        </SearchBar>
        {isLoading || (!users && !addUserDialogOpen) ? (
          <LinearProgress />
        ) : (
          <Box>
            <Styled.TableContainer>
              <Table stickyHeader>
                <TableHead
                  data-testid="tableHead"
                  headCells={headerCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <Styled.TableBody>
                  {users.map((row: PlatformAccountUsersSchema) => (
                    <TableRow data-testid="tableRow" hover role="checkbox" key={row.email}>
                      <TableCell
                        sx={{
                          minWidth: '200px',
                        }}
                        data-testid="name"
                      >
                        {row.fullName || '-'}
                      </TableCell>
                      <TableCell data-testid="email">{row.email}</TableCell>
                      {/* <TableCell>
                        <Styled.RoleDropdown
                          size="small"
                          variant="outlined"
                          fullWidth
                          disabled={user?.UserId === row?.userId}
                        >
                          {user.Email === row.email ? (
                            'Admin'
                          ) : (
                            <Select
                              disabled={!row?.userAccessId}
                              size="small"
                              onChange={handleEditUserRoleChange(row?.userAccessId)}
                              value={row.isAdmin ? RoleValues.ADMIN : RoleValues.STANDARD_USER}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop: 4,
                                    borderRadius: 6,
                                  },
                                },
                              }}
                            >
                              {Object.values(RoleValues).map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </Styled.RoleDropdown>
                      </TableCell> */}
                      <TableCell>
                        {row.status === AccessStatusEnum.Active && (
                          <Chip color="success" size="medium" label={FilterAccessStatusEnum.Active} />
                        )}
                        {row.status === AccessStatusEnum.Inactive && (
                          <Chip color="error" size="medium" label={FilterAccessStatusEnum.Inactive} />
                        )}
                        {row.status === AccessStatusEnum.Pending && (
                          <Chip color="warning" size="medium" label={FilterAccessStatusEnum.Pending} />
                        )}
                      </TableCell>
                      {/* <TableCell>{row.lastSignOn ? formatDate(new Date(row.lastSignOn)) : '-'}</TableCell> */}
                    </TableRow>
                  ))}

                  {users.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No Users Found
                      </TableCell>
                    </TableRow>
                  )}
                </Styled.TableBody>
              </Table>
            </Styled.TableContainer>
            <TablePagination
              data-testid="tablePagination"
              rowsPerPageOptions={tablePaginationRowsPerPageOptions}
              count={usersPagination?.total || 0}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Paper>
      {/* {deactivateUserDialogOpen && (
        <DeactivateUserDialog
          open={deactivateUserDialogOpen}
          handleCancel={handleCloseDeactivateUserDialog}
          handleSuccess={handleDeactivateUserSubmit(selectedUser)}
          user={selectedUser}
          accountName={selectedPlatformAccount.name}
        />
      )} */}
      {addUserDialogOpen && (
        <AddUserDialog
          data-testid="addUserDialog"
          open={addUserDialogOpen}
          accountName={selectedPlatformAccount?.name}
          handleClose={handleAddUserDialogOpenChange}
          onSubmit={handleAddUserDialogSubmit}
        />
      )}

      {(error || errors || successMessage?.length > 0) && (
        <Snackbar
          message={error?.message || errors || successMessage}
          severity={successMessage ? 'success' : 'error'}
        />
      )}
    </Styled.UserListContainer>
  );
};

export default UserList;
