import { Box, styled } from '@mui/material';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { Button, CircularProgress, Tooltip } from '~/core/components/shared';
import { BubbleChart } from '~/core/components/shared/BubbleChart';
import { numberWithCommas } from '~/core/helpers';
import { useGetIntentThemes } from '~/core/services/graphql/intents/hooks';
import { IntentThemesSortEnum, QueryGetIntentThemesArgs } from '~/core/types/graphql.types';
import { useContexts } from '~/store/context/useContext';

import { InfoIcon } from '../IntentThemes/styles';

const CustomContainer = styled(Box)`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  border-radius: 6px;
  border: solid 1px rgba(33, 33, 52, 0.12);
  background-color: #fff;
  margin: 8px 0;
  height: 100%;
  max-height: 100%;
`;

const ChartHeader = styled('p')({
  fontSize: '14px',
  fontWeight: 'bold',
  margin: '0',
  position: 'relative',
});

const ChartDetails = styled('p')`
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.17px;
  text-align: center;
  color: rgba(33, 33, 52, 0.87);
  margin: 0 0 10px 0;
  padding: 0;
`;

const ChartBubble = styled(Box)({
  backgroundColor: '#EEF1F9',
  borderRadius: '50%',
  border: '2px solid #D1D6DD',
  padding: 12,
  height: 200,
  width: 200,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
});

type IntentThemesPreviewProps = {
  segmentId: string;
  totalIntentThemes?: number;
  segmentName: string;
};

const IntentThemesPreview: React.FC<IntentThemesPreviewProps> = ({ segmentId, totalIntentThemes, segmentName }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange } = useContexts();
  const url = `intent-themes?${searchParams}&segmentName=${segmentName}`;

  const handleClick = (event: any) => {
    event.stopPropagation();
    navigate(url);
  };

  const intentThemesSkip = !segmentId;
  const intentThemesVariables: QueryGetIntentThemesArgs = {
    platformAccountId: selectedPlatformAccountId,
    customerId: selectedSourceAccountId,
    dateRange: selectedDateRange,
    filterField: {
      filters: [{ fieldName: 'segment_id', value: segmentId, operation: 'eq' }],
    },
    sortBy: { field: IntentThemesSortEnum.Impressions, desc: true },
    page: 1,
    pageSize: 100,
  };

  const { getIntentThemes, intentThemesLoading } = useGetIntentThemes(intentThemesSkip, intentThemesVariables);

  return (
    <CustomContainer>
      <ChartHeader>
        Intent Themes
        <Tooltip
          title={
            'Intent Themes are collections of Search Intent that provide audience insights ' +
            'beyond what keywords alone can offer. By understanding these broader themes, ' +
            'you can create content that better meets their needs without getting caught up ' +
            'in the specifics of particular search intents.'
          }
          placement="top"
        >
          <InfoIcon
            sx={{
              position: 'absolute',
              right: '-15px',
              top: '3px',
              fontSize: '18px',
            }}
          />
        </Tooltip>
      </ChartHeader>
      <ChartDetails
        sx={{
          position: 'relative',
        }}
      >
        {numberWithCommas(totalIntentThemes)} (showing sample)
      </ChartDetails>
      {intentThemesLoading ? (
        <Box height={145} display="flex" alignItems="center">
          <CircularProgress size={20} />
        </Box>
      ) : (
        <ChartBubble>
          <BubbleChart data={getIntentThemes?.edges} preview />
        </ChartBubble>
      )}
      {/* <StyledTableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell>Intent Themes</StyledTableHeaderCell>
              <StyledTableHeaderCell># of Intents</StyledTableHeaderCell>
              <StyledTableHeaderCell># of Keywords</StyledTableHeaderCell>
              <StyledTableHeaderCell>nValue</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {intentThemesLoading
              ? [...Array(5)].map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Skeleton variant="text" animation="wave" width="20%" height={10} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" animation="wave" width="20%" height={10} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" animation="wave" width="20%" height={10} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" animation="wave" width="20%" height={10} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              : getIntentThemes?.edges?.map((row) => (
                  <StyledTableRow key={row.theme}>
                    <StyledTableCell>{row.theme}</StyledTableCell>
                    <StyledTableCell>{row.intents}</StyledTableCell>
                    <StyledTableCell>{row.keywords}</StyledTableCell>
                    <StyledTableCell>{row.impressions}</StyledTableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer> */}
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        disabled={totalIntentThemes === 0 || !totalIntentThemes}
        label="View All"
        onClick={handleClick}
        sx={{
          backgroundColor: '#37345b',
          margin: '10px',
        }}
      />
    </CustomContainer>
  );
};

export default IntentThemesPreview;
