import React, { useCallback, useMemo } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, FormControl, OutlinedInput } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { limitCharacters } from '~/core/helpers';
import { getIntegrationAccountsAction } from '~/store/actions/integrationAccounts.action';
import { useContexts } from '~/store/context/useContext';
import { useGetAssesmentAvailability, useGetAssessmentStatuses } from '~/core/services/graphql/sourceAccounts/hooks';
import { GetAssessmentAvailabilityQueryVariables } from '~/core/services/graphql/sourceAccounts/sourceAccounts.queries.generated';
import { integrationAccountsSelector } from '~/store/selectors/integrationAccounts.selector';

import * as Styled from './styles';
import { SourceAccountSelectorProps } from './types';

const charLimit = 32;

const SourceAccountSelector: React.FC<SourceAccountSelectorProps> = React.memo(
  ({ demoMode, displayLogo, ...elementProps }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const updatedSearchParams = new URLSearchParams();
    const dispatch = useDispatch();

    const {
      selectedPlatformAccountId,
      selectedSourceAccountId,
      setSelectedSourceAccountId,
      setSelectedAccountName,
      setSelectedCampaignId,
      setSelectedDateRange,
    } = useContexts();

    const integrationAccounts = useSelector(integrationAccountsSelector);

    // Get assessment availability
    const getAssessmentAvailabilitySkip = !selectedPlatformAccountId;
    const getAssessmentAvailabilityVariables: GetAssessmentAvailabilityQueryVariables = {
      platformAccountId: selectedPlatformAccountId,
    };
    const { assessmentAvailabilityLoading, getAssessmentAvailability } = useGetAssesmentAvailability(
      getAssessmentAvailabilitySkip,
      getAssessmentAvailabilityVariables,
    );
    const updatedIntegrationAccounts = useMemo(() => {
      if (getAssessmentAvailability?.length > 0 && integrationAccounts) {
        return integrationAccounts.slice().sort((a, b) => {
          const aHasData = getAssessmentAvailability?.find(
            ({ customerId }) => customerId === a.customerId,
          )?.hasAvailableData;
          const bHasData = getAssessmentAvailability?.find(
            ({ customerId }) => customerId === b.customerId,
          )?.hasAvailableData;

          if (!aHasData && bHasData) return 1;
          if (aHasData && !bHasData) return -1;
          return a?.name.localeCompare(b?.name);
        });
      }
      return [];
    }, [getAssessmentAvailability, integrationAccounts]);

    const getAssessmentStatusesSkip = !selectedSourceAccountId;
    const { getAssessmentStatuses } = useGetAssessmentStatuses(getAssessmentStatusesSkip, {
      platformAccountId: selectedPlatformAccountId,
      customerId: selectedSourceAccountId,
    });

    const sourceAccountId = searchParams.get('sourceAccountId');

    useEffect(() => {
      setSelectedSourceAccountId(sourceAccountId || updatedIntegrationAccounts?.[0]?.customerId || null);
      const currentAcountName = updatedIntegrationAccounts?.find(
        (account) => account.customerId === selectedSourceAccountId,
      )?.name;
      if (currentAcountName) setSelectedAccountName(currentAcountName);
    }, [sourceAccountId, selectedSourceAccountId, updatedIntegrationAccounts, getAssessmentStatuses]);

    useEffect(() => {
      if (selectedPlatformAccountId) {
        dispatch(
          getIntegrationAccountsAction.request({
            platformAccountId: selectedPlatformAccountId,
            page: 1,
            pageSize: 9999,
            includeMcc: false,
          }),
        );
      }
    }, [selectedPlatformAccountId]);

    const handleAccountChange = useCallback(
      (value: string) => {
        if (demoMode) return;
        if (value === selectedSourceAccountId) return;
        if (value) {
          searchParams.delete('sourceAccountId');
          searchParams.delete('accountName');
          searchParams.delete('campaignId');
          searchParams.delete('startDate');
          searchParams.delete('accountName');
          searchParams.delete('endDate');
          searchParams.forEach((value, key) => {
            updatedSearchParams.set(key, value);
          });
          updatedSearchParams.set('sourceAccountId', value);
          const accountName = integrationAccounts?.find((account) => account.customerId === value)?.name;
          if (accountName) updatedSearchParams.set('accountName', accountName);
          setSelectedSourceAccountId(value);
          setSelectedAccountName(integrationAccounts?.find((account) => account.customerId === value)?.name);
          setSelectedCampaignId(null);
          setSearchParams(updatedSearchParams, { replace: true });
          setSelectedDateRange(null);
        }
      },
      [
        demoMode,
        selectedSourceAccountId,
        searchParams,
        updatedSearchParams,
        integrationAccounts,
        setSelectedSourceAccountId,
        setSelectedAccountName,
        setSelectedCampaignId,
        setSearchParams,
        setSelectedDateRange,
      ],
    );

    const currentAccount = useMemo(
      () =>
        updatedIntegrationAccounts?.find(
          (account) =>
            account?.customerId === (selectedSourceAccountId || updatedIntegrationAccounts?.[0]?.customerId || null),
        ),
      [updatedIntegrationAccounts, selectedSourceAccountId],
    );

    return !assessmentAvailabilityLoading && updatedIntegrationAccounts?.length > 0 ? (
      <FormControl data-testid="multipleAccountSelector">
        <Styled.Select
          data-testid="dropdownSelector"
          value={selectedSourceAccountId || ''}
          displayEmpty
          disabled={
            updatedIntegrationAccounts?.length === 0 ||
            getAssessmentAvailability?.length < 1 ||
            getAssessmentAvailability?.length === 0
          }
          autoWidth
          MenuProps={{
            sx: {
              maxHeight: '500px',
              marginLeft: '25px',
              marginTop: '5px',
            },
          }}
          IconComponent={ArrowDropDownIcon}
          renderValue={() => (
            <Box display="flex">
              {displayLogo}
              <Styled.AccountName className="selectName" data-testid="selectedAccountName">
                {limitCharacters(currentAccount?.name, charLimit)}
              </Styled.AccountName>
            </Box>
          )}
          input={<OutlinedInput />}
          {...elementProps}
        >
          {updatedIntegrationAccounts.map((option: any) => (
            <Styled.MenuItem
              data-testid="name"
              disabled={
                !getAssessmentAvailability?.some(
                  ({ customerId, hasAvailableData }) => customerId === option.customerId && hasAvailableData,
                )
              }
              key={option?.customerId}
              value={option?.customerId}
              selected={option?.customerId === selectedSourceAccountId}
              onClick={() => handleAccountChange(option?.customerId)}
              sx={{
                fontWeight: option?.customerId === selectedSourceAccountId ? 'bold' : 'normal',
              }}
            >
              {option?.name}{' '}
              {!getAssessmentAvailability?.find(({ customerId }) => customerId === option.customerId)
                ?.hasAvailableData && <Styled.CustomChip label="No Data" />}
            </Styled.MenuItem>
          ))}
        </Styled.Select>
      </FormControl>
    ) : (
      <Styled.LoadingContainer>
        <Styled.CustomSkeleton variant="text" animation="wave" />
      </Styled.LoadingContainer>
    );
  },
);

export default SourceAccountSelector;
