import { Skeleton, Table, TableBody } from '@mui/material';

import { TableHead } from '~/core/components/shared';
import { renderSkeletonRows } from '~/core/helpers';
import { SortType } from '~/core/types/table.types';
import { HeadCell } from '~/core/components/shared/Table/TableHead/types';

import { CustomTablePagination } from '../components';
import * as Styled from '../styles';

type LoadingTableProps = {
  desc?: boolean;
  orderBy?: string;
  page?: number;
  pageSize?: number;
  headerCells: HeadCell[];
};

const LoadingTable = ({ desc = true, orderBy = null, page = 0, pageSize = 25, headerCells }: LoadingTableProps) => {
  const skeletonRows = headerCells.length;

  return (
    <>
      <Styled.TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            data-testid="tableHead"
            headCells={headerCells}
            order={desc ? SortType.Desc : SortType.Asc}
            orderBy={orderBy}
          />

          <TableBody
            sx={{
              boxShadow: 'none',
            }}
          >
            {renderSkeletonRows(skeletonRows, 25)}
          </TableBody>
        </Table>
      </Styled.TableContainer>
      <CustomTablePagination
        sx={{
          backgroundColor: '#F7F7F9',
        }}
        disabled
        page={page}
        pageSize={pageSize}
        setPage={null}
        setPageSize={null}
        pageTotal={null}
        skeleton={<Skeleton height="16px" width="120px" />}
      />
    </>
  );
};

export default LoadingTable;
