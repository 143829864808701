import { useState, useMemo, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { useGetKeywords } from '~/core/services/graphql/keywords/hooks';
import { GetKeywordsQueryVariables } from '~/core/services/graphql/keywords/keywords.queries.generated';
import { useContexts } from '~/store/context/useContext';
import { KeywordSortEnum } from '~/core/types/graphql.types';

import { StyledCard, StyledCardHeader, StyledCardContent, GridContainer } from '../../styles';
import { KeywordBreakdownProps } from '../../types';
import { PieChartView } from './components/PieChartView/PieChartView';
import { KeywordTable } from './components/KeywordTable/KeywordTable';
import { LoadingView } from './LoadingView';

export const KeywordBreakdown = ({ selectedKeyword, setKeywords }: KeywordBreakdownProps) => {
  const [searchParams] = useSearchParams();
  const [hoveredKeyword, setHoveredKeyword] = useState<string | null>(null);
  const { selectedPlatformAccountId, selectedSourceAccountId, selectedDateRange } = useContexts();

  const adGroupId = useMemo(() => searchParams.get('adGroupId'), [searchParams]);
  const segmentId = useMemo(() => searchParams.get('segmentId'), [searchParams]);

  const getKeywordsSkip = !selectedPlatformAccountId || !selectedSourceAccountId || !selectedDateRange;
  const getKeywordsVars: GetKeywordsQueryVariables = {
    platformAccountId: selectedPlatformAccountId,
    customerIds: selectedSourceAccountId,
    dateRange: selectedDateRange,
    page: 0,
    pageSize: 1000,
    sortBy: {
      field: KeywordSortEnum.Impressions,
      desc: true,
    },
    filterField: {
      filters: [
        {
          fieldName: 'segment_id',
          operation: 'eq',
          value: segmentId,
        },
      ],
    },
  };

  if (adGroupId) {
    getKeywordsVars.filterField.filters.push({
      fieldName: 'ad_group_id',
      operation: 'eq',
      value: adGroupId,
    });
  }

  const { getKeywords, keywordsLoading } = useGetKeywords(getKeywordsSkip, getKeywordsVars);

  useEffect(() => {
    if (getKeywords?.edges?.length > 0) {
      setKeywords(getKeywords.edges);
    }
  }, [getKeywords]);

  const pieChartData = useMemo(
    () =>
      getKeywords?.edges
        ?.map((keyword) => ({
          name: `${keyword.keyword_text} (${keyword.keyword_match_type})`,
          value: keyword.auctions,
          impressions: keyword.impressions,
          keyword_text: keyword.keyword_text,
          keyword_match_type: keyword.keyword_match_type,
          eligibleImpressions: Math.round(Number(keyword.auctions)),
          missingImpressions: Math.round(Number(keyword.auctions)) - Math.round(Number(keyword.impressions)),
          gap:
            Number(keyword.auctions) > 0
              ? ((Number(keyword.auctions) - Number(Math.round(keyword.impressions))) / Number(keyword.auctions)) * 100
              : 0,
        }))
        .sort((a, b) => (b.value as unknown as number) - (a.value as unknown as number)),
    [getKeywords],
  );

  // const totalEligibleImpressions = useMemo(
  //   () => pieChartData?.reduce((sum, keyword) => sum + keyword.eligibleImpressions, 0),
  //   [pieChartData],
  // );

  // const totalMissingImpressions = useMemo(
  //   () => pieChartData?.reduce((sum, keyword) => sum + Math.round(Number(keyword.missingImpressions)), 0),
  //   [pieChartData],
  // );

  // const handleFilterFieldChange = (keyword: KeywordSchema) => {
  //   setSelectedKeyword(keyword);
  // };

  if (keywordsLoading || getKeywordsSkip) {
    return <LoadingView />;
  }

  return (
    <StyledCard>
      <StyledCardHeader
        title={
          <Typography variant="h6" fontWeight={600} fontSize="1.125rem">
            Traffic Gap Analysis
          </Typography>
        }
        // action={
        //   <StatsHeader
        //     totalEligibleImpressions={totalEligibleImpressions}
        //     totalMissingImpressions={totalMissingImpressions}
        //   />
        // }
      />
      <StyledCardContent>
        <GridContainer>
          <PieChartView
            data={pieChartData}
            hoveredKeyword={hoveredKeyword}
            selectedKeyword={selectedKeyword}
            onHover={setHoveredKeyword}
          />
          <KeywordTable data={pieChartData} />
        </GridContainer>
      </StyledCardContent>
    </StyledCard>
  );
};
