import { Box, styled } from '@mui/material';

export const Container = styled(Box)({
  height: '100vh',
});

export const ContentContainer = styled(Box)({
  flexGrow: 1,
  padding: 48,
  overflow: 'hidden',
  height: 'calc(100vh - 64px)',
});
