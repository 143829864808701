import { useQuery } from '@apollo/client';

import {
  AlignmentPercentageSchema,
  IntentsAssetMapSchema,
  IntentsAssetMapSchemaPaginationSchema,
  IntentsAvailabilitySchema,
  IntentsSchemaPaginationSchema,
  IntentThemesSchemaPaginationSchema,
  IntentThemesTreeSchema,
} from '~/core/types/graphql.types';

import {
  GET_ALIGNMENT_PERCENTAGES,
  GET_INTENT_THEME_TREE,
  GET_INTENT_THEMES,
  GET_INTENTS,
  GET_INTENTS_ASSET_MAP,
  GET_INTENTS_ASSET_MAP_PAGINATED,
  GET_INTENTS_AVAILABILITY,
  GET_INTENTS_BY_THEME,
} from './intents.queries';
import {
  GetAlignmentPercentagesQueryVariables,
  GetIntentsAssetMapPaginatedQueryVariables,
  GetIntentsAssetMapQueryVariables,
  GetIntentsAvailabilityQueryVariables,
  GetIntentsByThemeQueryVariables,
  GetIntentsQueryVariables,
  GetIntentThemesQueryVariables,
  GetIntentThemeTreeQueryVariables,
} from './intents.queries.generated';

export const useGetIntents = (skip: boolean, variables: GetIntentsQueryVariables) => {
  const {
    loading: intentsLoading,
    error: intentsError,
    data: intentsData,
  } = useQuery<{ getIntents: IntentsSchemaPaginationSchema }>(GET_INTENTS, {
    skip,
    variables,
  });

  const getIntents = intentsData?.getIntents;

  return { intentsLoading, intentsError, getIntents };
};

export const useGetIntentsAssetMap = (skip: boolean, variables: GetIntentsAssetMapQueryVariables) => {
  const {
    loading: assetMapLoading,
    error: assetMapError,
    data: assetMapData,
  } = useQuery<{ getIntentsAssetMap: IntentsAssetMapSchema[] }>(GET_INTENTS_ASSET_MAP, {
    skip,
    variables,
  });

  const getIntentsAssetMap = assetMapData?.getIntentsAssetMap;

  return { assetMapLoading, assetMapError, getIntentsAssetMap };
};

export const useGetIntentsAssetMapPaginated = (skip: boolean, variables: GetIntentsAssetMapPaginatedQueryVariables) => {
  const {
    loading: assetMapPaginatedLoading,
    error: assetMapPaginatedError,
    data: assetMapPaginatedData,
  } = useQuery<{ getIntentsAssetMapPaginated: IntentsAssetMapSchemaPaginationSchema }>(
    GET_INTENTS_ASSET_MAP_PAGINATED,
    {
      skip,
      variables,
    },
  );

  const getIntentsAssetMapPaginated = assetMapPaginatedData?.getIntentsAssetMapPaginated;

  return { assetMapPaginatedLoading, assetMapPaginatedError, getIntentsAssetMapPaginated };
};

export const useGetAlignmentPercentages = (skip: boolean, variables: GetAlignmentPercentagesQueryVariables) => {
  const {
    loading: alignmentLoading,
    error: alignmentError,
    data: alignmentData,
  } = useQuery<{ getAlignmentPercentages: AlignmentPercentageSchema }>(GET_ALIGNMENT_PERCENTAGES, {
    skip,
    variables,
  });

  const getAlignmentPercentages = alignmentData?.getAlignmentPercentages;

  return { alignmentLoading, alignmentError, getAlignmentPercentages };
};

export const useGetIntentsAvailability = (skip: boolean, variables: GetIntentsAvailabilityQueryVariables) => {
  const {
    loading: availabilityLoading,
    error: availabilityError,
    data: availabilityData,
  } = useQuery<{ getIntentsAvailability: IntentsAvailabilitySchema[] }>(GET_INTENTS_AVAILABILITY, {
    skip,
    variables,
  });

  const getIntentsAvailability = availabilityData?.getIntentsAvailability;

  return { availabilityLoading, availabilityError, getIntentsAvailability };
};

export const useGetIntentThemes = (skip: boolean, variables: GetIntentThemesQueryVariables) => {
  const {
    loading: intentThemesLoading,
    error: intentThemesError,
    data: intentThemesData,
  } = useQuery<{ getIntentThemes: IntentThemesSchemaPaginationSchema }>(GET_INTENT_THEMES, {
    skip,
    variables,
  });

  const getIntentThemes = intentThemesData?.getIntentThemes;

  return { intentThemesLoading, intentThemesError, getIntentThemes };
};

export const useGetIntentThemeTree = (skip: boolean, variables: GetIntentThemeTreeQueryVariables) => {
  const {
    loading: intentThemeTreeLoading,
    error: intentThemeTreeError,
    data: intentThemeTreeData,
  } = useQuery<{ getIntentThemeTree: IntentThemesTreeSchema }>(GET_INTENT_THEME_TREE, {
    skip,
    variables,
  });

  const getIntentThemeTree = intentThemeTreeData?.getIntentThemeTree;

  return { intentThemeTreeLoading, intentThemeTreeError, getIntentThemeTree };
};

export const useGetIntentsByTheme = (skip: boolean, variables: GetIntentsByThemeQueryVariables) => {
  const {
    loading: intentsByThemeLoading,
    error: intentsByThemeError,
    data: intentsByThemeData,
  } = useQuery<{ getIntentsByTheme: IntentsSchemaPaginationSchema }>(GET_INTENTS_BY_THEME, {
    skip,
    variables,
  });

  const getIntentsByTheme = intentsByThemeData?.getIntentsByTheme;

  return { intentsByThemeLoading, intentsByThemeError, getIntentsByTheme };
};
