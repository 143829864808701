import { CssBaseline } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { ErrorBoundary } from './core/components/layouts/ErrorBoundary';
import { CircularProgress, InitApp } from './core/components/shared';
import { PrivateRoute } from './core/components/wrappers/PrivateRoute';
import { SnackbarEvents } from './core/components/wrappers/SnackbarEvents';
import {
  AdminSettingsPaths,
  AuthPaths,
  DashboardPaths,
  HelperPaths,
  PlatformPaths,
} from './core/constants/paths.constants';
import { UserProfileMenuRoutes } from './core/types/globals';
import { AccessStatusEnum } from './core/types/graphql.types';
import {
  AdminView,
  AssetsView,
  CompanySettings,
  ConfirmEmail,
  CreateAccount,
  CreatePlatformAccount,
  Dashboard,
  // MotiveMap,
  NotFound,
  Opportunities,
  PaidSearchIntegration,
  PasswordReset,
  PasswordResetRequestPage,
  PlatformAdminConsole,
  RedirectGooglePage,
  SegmentsView,
  SignInPage,
  SignUpConfirm,
  UserSettings,
} from './pages';
import { MotiveMap } from './pages/MotiveMap';
import { Recommendations, SegmentGapPage } from './pages/Opportunities/components';
import { useContexts } from './store/context/useContext';
import { authIsAppInitSelector, authUserSelector } from './store/selectors/auth.selector';
import { IntentThemes } from './pages/Opportunities/components/TrafficSegmentPreview/components/IntentThemes';
import { featureFlagUnlock } from './core/helpers';

export const AppRoutes = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { selectedPlatformAccountId, platformAccounts, platformAccountsError } = useContexts();
  const currentPlatformAccount = platformAccounts?.find((account) => account.id === selectedPlatformAccountId);

  const user = useSelector(authUserSelector);
  const isInit = useSelector(authIsAppInitSelector);
  const activeUser = user?.UserStatus?.toUpperCase() === AccessStatusEnum.Active;

  const isCreateAccountRoute = location.pathname.includes(PlatformPaths.CreateNewPlatformAccount);
  const sourceAccountId = searchParams.get('sourceAccountId');
  const accountName = searchParams.get('accountName');

  // Setting up user info for Sentry logging
  Sentry.setUser({
    email: user?.Email,
    userId: user?.UserId,
    name: `${user?.FirstName}  ${user?.LastName}`,
    platformAccountId: currentPlatformAccount?.id,
    platformAccount: currentPlatformAccount?.name,
    sourceAccountId,
    accountName,
  });

  // Sending user identification for Heap Analytics
  window.heap.identify(user?.Email);

  return isInit ? (
    <Routes>
      <Route path={AuthPaths.Auth}>
        <Route path={AuthPaths.SignIn} element={<SignInPage />} />
        <Route path={AuthPaths.CreateAccount} element={<CreateAccount />} />
        <Route path={AuthPaths.PasswordReset} element={<PasswordReset />} />
        <Route path={AuthPaths.PasswordResetRequest} element={<PasswordResetRequestPage />} />
        <Route path={AuthPaths.ConfirmEmail} element={<SignUpConfirm />} />
        <Route path={AuthPaths.EmailNotVerified} element={<ConfirmEmail />} />
      </Route>
      <Route
        path={DashboardPaths.MotiveMap}
        element={
          <PrivateRoute isAllowed={!!user} isVerified={activeUser} error={platformAccountsError?.message}>
            <Dashboard />
          </PrivateRoute>
        }
      >
        <Route path="*" element={<NotFound />} />
        <Route index element={<MotiveMap />} />
      </Route>

      <Route
        path={DashboardPaths.Opportunities}
        element={
          <PrivateRoute isAllowed={!!user} isVerified={activeUser} error={platformAccountsError?.message}>
            <Dashboard />
          </PrivateRoute>
        }
      >
        <Route path="*" element={<NotFound />} />
        <Route index element={<Opportunities />} />
        <Route path="assets" element={<AssetsView />} />
        <Route path="recommendations" element={<Recommendations />} />
      </Route>

      <Route
        path={DashboardPaths.Segments}
        element={
          <PrivateRoute isAllowed={!!user} isVerified={activeUser} error={platformAccountsError?.message}>
            <Dashboard />
          </PrivateRoute>
        }
      >
        <Route path="*" element={<NotFound />} />
        <Route path="intent-themes" element={<SegmentGapPage />} />
        <Route index element={<Opportunities />} />
        {featureFlagUnlock({
          platformAccountId: null,
          email: user?.Email,
          engineerLock: true,
        }) && <Route path="segments" element={<SegmentsView />} />}
        <Route path="segments/intent-themes" element={<IntentThemes />} />
      </Route>

      <Route
        element={
          <PrivateRoute isAllowed={!!user} isVerified={activeUser} error={platformAccountsError?.message}>
            <Dashboard />
          </PrivateRoute>
        }
      >
        <Route path={DashboardPaths.PaidSearchIntegrations} element={<PaidSearchIntegration />} />
      </Route>
      <Route
        path={PlatformPaths.Platform}
        element={
          <PrivateRoute
            isAllowed={!!user && user.IsPlatformAdmin}
            isVerified={activeUser}
            error={platformAccountsError?.message}
          >
            <Dashboard hideSidebar={isCreateAccountRoute} />
          </PrivateRoute>
        }
      >
        <Route path="*" element={<NotFound />} />
        <Route path={PlatformPaths.PlatformConsole} element={<PlatformAdminConsole />} />
        <Route path={PlatformPaths.CreateNewPlatformAccount} element={<CreatePlatformAccount />} />
      </Route>
      <Route
        path={AdminSettingsPaths.Admin}
        element={
          <PrivateRoute
            isAllowed={(!!user && currentPlatformAccount?.isAdmin) || (!!user && user?.IsPlatformAdmin)}
            isVerified={activeUser}
            error={platformAccountsError?.message}
          >
            <Dashboard />
          </PrivateRoute>
        }
      >
        <Route path="*" element={<NotFound />} />
        <Route path={AdminSettingsPaths.CompanySettings} element={<CompanySettings />} />
        <Route path={AdminSettingsPaths.AdminSettings} element={<AdminView />} />
      </Route>
      <Route
        path={UserProfileMenuRoutes.MyAccount}
        element={
          <PrivateRoute isAllowed={!!user} isVerified={activeUser} error={platformAccountsError?.message}>
            <Dashboard hideSidebar />
          </PrivateRoute>
        }
      >
        <Route path={UserProfileMenuRoutes.MyAccount} element={<UserSettings />} />
      </Route>
      <Route path={HelperPaths.GoogleRedirect} element={<RedirectGooglePage />} />
      <Route path="*" element={<Navigate to={DashboardPaths.Segments} />} />
    </Routes>
  ) : (
    <CircularProgress />
  );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const App = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return (
    <ErrorBoundary>
      <Elements stripe={stripePromise}>
        <InitApp />
        <CssBaseline />
        <SnackbarEvents />
        {/* <SentryFeedback /> */}
      </Elements>
    </ErrorBoundary>
  );
};

export default App;
